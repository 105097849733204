$tier-tiles-carousel-color-event: color('primary-3');
// unselectable
$tier-tiles-carousel-color-unselectable: color('base-2');
$tier-tiles-carousel-item-color: color('base-0');
$tier-tiles-carousel-circle-border-color: color('base-3');
$tier-tiles-carousel-value-color: color('primary-3');
$tier-tiles-carousel-price-color: color('base-4');
$tier-tiles-carousel-currency-color: color('base-0');
$tier-tiles-carousel-currency-through-color: color('base-3');
$tier-tiles-carousel-description-color: color('base-4');
$tier-tiles-carousel-loader-color: color('primary-3');
