.location-block {
    text-align: $location-block-text-align;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    &::before {
        @include inline-svg-bg($icon-location-secondary);

        margin: 0 0 15px;
        height: 35px;
        content: "";
        display: inline-block;
        min-width: 35px;
        background-position: 0 center;

        @if ($theme-brand == "css") {
            margin: 0 20px 0 0;
        }

        @include media-breakpoint-up(md) {
            @include inline-svg-bg($icon-location);
        }

        @include media-breakpoint-up(lg) {
            margin: 0 25px 0 0;
        }
    }
}
