$header-banner-bg-color: $gray-500 !default;
$header-banner-text-color: $body-color !default;
$header-banner-link-color: $link-color !default;

.header-banner {
    @include transition($transition-base);

    background: $header-banner-bg-color;
    max-height: 150px;
    margin-bottom: -5px;

    @include media-breakpoint-down(md) {
        @include when-inside('.menu-opened') {
            display: none;
        }
    }

    &.hide {
        margin-bottom: 0;
        max-height: 0;
    }

    &-in {
        position: relative;
        text-align: center;

        @include media-breakpoint-down(md) {
            max-height: 60px;
            overflow: hidden;
        }
    }

    &-promotion {
        @include limit-text-lines($max-lines: 2, $is-max-height: true);

        @include media-breakpoint-up(lg) {
            @include limit-text-lines($max-lines: 1);
        }
    }

    &-close {
        @include inline-svg-bg($icon-close-banner);

        appearance: none;
        border: none;
        cursor: pointer;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include when-inside('.error-notification') {
            @include inline-svg-bg($icon-close-white);
        }
    }

    &-content {
        color: $header-banner-text-color;

        a {
            color: $header-banner-link-color;
        }

        .html-slot-container {
            padding: 11px 25px;

            @include media-breakpoint-down(md) {
                max-height: 56px;
                overflow: hidden;
            }
        }
    }
}
