$b-hint-color: color('base-8') !default;
$b-hint-icon-size: 20px !default;
$b-hint-icon-margin: 10px !default;

.b-hint {
    align-items: center;
    color: $b-hint-color;
    display: flex;
    gap: $b-hint-icon-margin;

    @include media-breakpoint-down(sm) {
        align-items: start;
    }

    svg {
        height: $b-hint-icon-size;
        width: $b-hint-icon-size;
        flex-shrink: 0;

        @include media-breakpoint-down(sm) {
            margin-top: -5px;
        }
    }

    &-copy {
        line-height: 1;
    }

    .search-widget-inner & {
        display: none;
    }
}
