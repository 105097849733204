// -----------------------------------------------------------------------------
// This file contains all styles related to the category banner component.
// -----------------------------------------------------------------------------
$category-banner-font-size: 16px !default;
$category-banner-background-color: #fbfafa !default;
$category-banner-padding: 30px 0 15px !default;
$category-banner-margin-bottom: 60px !default;

.category-banner {
    background-color: $category-banner-background-color;
    font-size: $category-banner-font-size;
    overflow: hidden;
    padding: $category-banner-padding;
    margin-bottom: $category-banner-margin-bottom;

    &.background-svg {
        &::before {
            @if ($theme-brand == 'aa') {
                background-position: -130px -30px;
                background-size: 700px;

                @include media-breakpoint-up(md) {
                    background-position: 100px -70px;
                }

                @include media-breakpoint-up(lg) {
                    background-position: 360px -65px;
                    background-size: 1100px;
                }
            }
        }
    }
}
