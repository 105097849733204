.l-search {
    &_list-content {
        display: grid;
        gap: 40px;

        @include media(md-up) {
            grid-template-columns: repeat(3, 1fr);
            align-items: stretch;
            column-gap: 20px;
        }
    }

    &_list-actions {
        .b-load_more {
            text-align: center;
        }
    }
}
