$alert-background: lighten($red, 50%) !default;
$alert-color: $red !default;
$alert-danger-border-radius: 5px !default;
$alert-message-color: color('base-0') !default;
$alert-message-bg-color: color('base-6') !default;
$alert-message-font-size: 13px !default;
$alert-message-border-radius: $border-radius !default;
$alertalert-information-color: $grey-4 !default;

.alert-danger {
    background-color: $alert-background;
    color: $alert-color;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: $alert-danger-border-radius;

    &::before {
        @include inline-svg-bg($icon-exclamation);

        content: '';
        display: block;
        flex: 0 0 20px;
        height: 20px;
        margin: 0 20px 0 0;
    }

    &.m-small {
        padding: 10px;

        &::before {
            margin: -3px 8px 0 0;
            flex-basis: 16px;
            height: 16px;
        }
    }
}

.alert-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    font-size: $alert-message-font-size;
    color: $alert-message-color;
    background-color: $alert-message-bg-color;
    border-radius: $alert-message-border-radius;
}

.alert-information {
    position: relative;
    padding: 0 0 0 20px;
    color: $alertalert-information-color;

    &::before {
        @include inline-svg-bg($icon-info);

        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        content: '';
    }
}

.form-control.is-invalid {
    border: 1px solid $form-control-error-border-color;

    &.m-invalid-secondary {
        @include on-event($self: true) {
            background: none;
            box-shadow: 0 0 0 2px $form-error-shadow-color-secondary;
            padding-right: 15px;
        }
    }
}

select,
textarea {
    &.form-control.is-invalid {
        ~ .invalid-feedback {
            @include inline-svg-bg($icon-exclamation);

            padding: 1px 0 1px 25px;
            background-size: 20px;
            background-position: 0 50%;
        }
    }
}
