.b-product_tile {
    @include flex;

    column-gap: 24px;
    position: relative;

    .l-plp-product_tile & {
        background: none;
    }

    .product-carousel-item & {
        flex-direction: column;
    }

    @include media(sm) {
        flex-direction: column;
    }

    .image-container {
        a {
            @include aspect_ratio(75);

            position: relative;
            display: block;
        }

        .product-carousel-item & {
            border-radius: $border-radius;
            overflow: hidden;
        }

        &::before {
            .product-carousel-item & {
                display: none;
            }
        }
    }

    .strike-through {
        color: $grey;
        display: block;
        font-weight: normal;
        text-decoration: line-through;
    }

    &-image {
        &_container {
            display: grid;
            grid-template-columns: [grid-start] 1fr [grid-end];
        }

        &_link {
            grid-row: 1/2;
            grid-column: grid-start/grid-end;
        }
    }

    &-body {
        @include flex($direction: column);

        width: 100%;

        @include media(sm) {
            padding: 20px 16px;
        }
    }

    .b-product_tile-body {
        gap: 0;

        .pdp-link .link {
            color: $blue;
            font-size: 24px;
            letter-spacing: normal;
        }
    }

    &-content {
        @include flex($horizontal: space-between);

        column-gap: 32px;
        flex-grow: 1;

        .product-carousel-item &,
        .b-carousel-item & {
            flex-direction: column;
            row-gap: 32px;
        }

        @include media(md-down) {
            flex-direction: column;
        }
    }

    &-info {
        @include flex($direction: column);
    }

    &-category {
        color: $grey;
        margin-bottom: 12px;
        font-size: 12px;
    }

    &-description {
        color: $black;
        margin-bottom: 20px;

        .btn-link {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }

    .b-product_tile-description {
        font-size: 14px;
    }

    &-link-button {
        .product-carousel-item & {
            margin-bottom: 0;
        }
    }

    &-actions {
        @include media(md-up) {
            align-self: flex-end;
            text-align: right;

            .product-carousel-item & {
                align-self: flex-start;
            }
        }

        @include media(md) {
            margin-top: -30px;
        }

        @include when-inside('.b-carousel-item') {
            align-self: flex-start;
        }
    }

    .b-product_tile-actions {
        .price .price-value {
            display: inline-block;
            font-size: 14px;
            color: $black;
        }
    }

    .product-tile {
        &-badge {
            border-radius: $border-radius-badge;
            border: 1px solid $blue;
            column-gap: 6px;
            display: flex;
            height: 24px;
            padding: 4px 8px;
            font-family: $font-emirates;
            color: $blue;
            font-size: 12px;
            font-weight: bold;

            &-wrapper {
                @include flex($wrap: wrap);

                column-gap: 8px;
                row-gap: 8px;
                margin-top: auto;
            }

            &.bestseller {
                background: $green;
                border-color: $green;
                color: $white;
            }
        }
    }

    .plp-product-image {
        @include media(md-up) {
            flex: 0 0 320px;
        }

        @include media(sm) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        figure {
            @include aspect_ratio(75);

            max-width: 100%;
        }

        > .main-image-carousel {
            border-radius: $border-radius;
            overflow: hidden;
        }

        .main-image-carousel-element-wrapper {
            width: 100%;
        }

        .no-image {
            height: 240px;
        }
    }

    .price {
        margin-top: 20px;
        margin-bottom: 20px;

        &-value {
            font-weight: 700;
        }

        .product-carousel-item & {
            margin: 0;
        }

        .strike-through {
            .price-value {
                color: $grey-4;
                font-size: 12px;
                font-weight: 400;
                text-decoration: line-through;
            }
        }
    }
}
