@mixin reset-default-icon-styles($height: 24px, $width: 24px) {
    position: relative;
    left: 0;
    top: 0;
    transform: initial;
    display: inline-block;
    height: $height;
    width: $width;
}

.navigation-menu {
    &-in {
        @include media(md-down) {
            background-color: $grey-1;
            padding-bottom: 60px;
        }
    }

    &-list {
        @include media(md-up) {
            column-gap: 4px;
        }

        @include media(md-down) {
            background-color: $white;
            padding: 0;
            border-bottom: 1px solid $grey-10;
        }
    }

    &-item-title {
        @include media(md-down) {
            @include flex($vertical: center);

            column-gap: 16px;
        }

        img {
            max-width: 40px;

            @include media(md-up) {
                display: none;
            }
        }
    }

    &-link {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $dark;

        @include media(md-up) {
            padding: 4px 10px;
        }

        @include media(md-down) {
            @include flex($horizontal: space-between, $vertical: center);

            border-top: 1px solid $grey-10;
            padding: 8px 16px;
        }

        &.dropdown-toggle {
            @include media(md-down) {
                &::after {
                    @include inline-svg-bg($icon-arrow-right-menu);
                    @include reset-default-icon-styles;
                }
            }
        }

        &:hover {
            @include media(md-up) {
                background-color: $blue;
                color: $white;
                border-radius: 100px;
                text-decoration: none;
            }
        }

        &::after {
            @include media(md-up) {
                display: none;
            }
        }
    }

    &-item {
        &:first-child {
            .navigation-menu-link {
                @include media(md-up) {
                    padding-left: 10px;
                }
            }
        }

        &:last-child {
            .navigation-menu-link {
                @include media(md-up) {
                    padding-right: 10px;
                }
            }
        }
    }

    &-item-content {
        @include media(md-down) {
            padding: 0;
        }

        &.show {
            @include media(md-up) {
                max-height: 600px;
            }
        }
    }

    &-item-wrap {
        @include media(md-up) {
            padding: 48px 0;
        }

        .container {
            @include media(md-down) {
                padding: 0;
            }
        }
    }

    &-backlink-wrapper {
        display: none;

        @include media(md-down) {
            @include flex($vertical: center, $horizontal: space-between);

            padding: 17px 16px;
            background-color: $white;
        }

        &.m-no-backlink {
            justify-content: flex-end;
        }
    }

    &-backlink {
        @include flex($vertical: center);

        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        padding: 0;
        column-gap: 8px;

        &::before {
            @include inline-svg-bg($icon-arrow-left-menu);
            @include reset-default-icon-styles;
        }
    }

    &-closelink {
        position: relative;
        line-height: 0;

        &::before {
            @include inline-svg-bg($icon-close-menu);

            content: '';
            display: inline-block;
            height: 24px;
            width: 24px;
        }
    }

    &-columns {
        display: flex;
        flex-direction: column;

        @include media(lg-up) {
            column-gap: 40px;
            flex-direction: row;
        }


    }

    &-column {
        font-size: 14px;
        line-height: 20px;
        padding: 24px 16px 32px;
        border-top: 1px solid $grey-10;

        @include media(lg-up) {
            padding: 0 20px 0 0;
            width: 25%;
            border: none;
            border-right: 1px solid $grey-10;
        }

        &:last-child {
            @include media(lg-up) {
                border-right: none;
                padding: 0;
            }
        }
    }

    &-column-title {
        &.m-lg-hidden {
            @include media(md-up) {
                display: none;
            }
        }
    }

    &-column-link {
        color: initial;
        display: inline-block;
        margin-top: 32px;
        text-decoration: underline;

        &:hover {
            color: initial;
            text-decoration: none;
        }
    }

    &-column-list {
        @include flex;

        padding-left: 0;
        list-style: none;
        flex-direction: column;
        row-gap: 20px;
    }

    &-column-list-item {
        a {
            color: initial;
        }
    }

    .circle-tiles-list {
        @include flex;

        margin: 0;
        padding: 0;
        flex-direction: column;
        row-gap: 16px;

        &-item {
            @include flex;

            max-width: initial;
            padding: 0;
            margin: 0;
            column-gap: 16px;
            text-align: left;

            &:hover {
                background: none;
                color: inherit;
            }
        }

        &-item-image {
            display: inline-block;
            height: 48px;
            width: 48px;
        }

        &-item-text {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }
    }

    .blog-tile {
        border: 1px solid $grey-10;
        border-radius: 16px;
        padding-bottom: 24px;

        &-img {
            border-radius: 16px 16px 0 0;
            margin-bottom: 20px;
        }

        &-title {
            color: $blue;
            font-size: 24px;
            line-height: 26px;
            margin: 0 16px 8px;
            letter-spacing: 0.24px;

            a {
                color: inherit;
            }
        }

        &-description {
            margin: 0 16px;
        }
    }

    .utility-navigation-mobile {
        border-bottom: 1px solid $grey-10;
        margin-top: 16px;
        padding: 0;

        &::before {
            display: none;
        }

        a,
        &-link {
            font-size: 14px;
            line-height: 20px;
            color: $dark;
            padding: 0;
        }

        &-item {
            border-top: 1px solid $grey-10;
            padding: 11px 16px;
            margin: 0;

            &::before {
                display: none;
            }

            &.m-link {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            &.m-support {
                a {
                    &::before {
                        @include inline-svg-bg($icon-chat);

                        content: "";
                        margin-right: 10px;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &.country-selector,
            &.currency-selector {
                span {
                    &.utility-navigation-mobile-link {
                        @include flex($horizontal: space-between);

                        &::after {
                            @include inline-svg-bg($icon-arrow-right-menu);
                            @include reset-default-icon-styles;
                        }
                    }
                }

                .dropdown-menu {
                    border-bottom: 1px solid $grey-10;
                    padding: 0;

                    .utility-navigation-mobile-link {
                        border-top: 1px solid $grey-10;
                        padding: 11px 16px;
                    }
                }

                &-link-description {
                    margin-left: 0;
                }
            }
        }

        .link-icon-in {
            @include flex($vertical: center);

            padding-left: 0;
            column-gap: 8px;

            &::before {
                @include reset-default-icon-styles(32px, 32px);
            }
        }

        .link-icon {
            &.m-account {
                .link-icon-in {
                    &::before {
                        @include inline-svg-bg($icon-account-menu);
                    }
                }
            }

            &.m-wishlist {
                @include flex($vertical: center);

                column-gap: 4px;

                .link-icon-in {
                    &::before {
                        @include inline-svg-bg($icon-wishlist-menu);
                    }
                }
            }
        }

        &.m-account {
            background-color: $white;

            .utility-navigation-mobile {
                &-item {
                    padding-top: 12px;
                    padding-bottom: 12px;

                    &.dropdown {
                        .dropdown-menu {
                            padding: 0;
                        }

                        .navigation-menu-backlink {
                            padding: 17px 16px;
                        }

                        .dropdown-toggle {
                            @include flex($horizontal: space-between);

                            &::after {
                                @include inline-svg-bg($icon-arrow-right-menu);
                                @include reset-default-icon-styles;
                            }
                        }

                        .dropdown-item {
                            border-top: 1px solid $grey-10;
                            padding: 14px 16px;
                        }

                        .dropdown-divider {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
