/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active {
            @content;
        }
    } @else {
        &:hover,
        &:active {
            @content;
        }
    }
}

@mixin on-disabled($self: false) {
    @if $self {
        &,
        &:disabled,
        &.disabled {
            @content;
        }
    } @else {
        &:disabled,
        &.disabled {
            @content;
        }
    }
}

@mixin on-readonly($self: false) {
    @if $self {
        &,
        &[readonly],
        &.readonly {
            &:not(.m-readonly-ignore) {
                @content;
            }
        }
    } @else {
        &[readonly],
        &.readonly {
            &:not(.m-readonly-ignore) {
                @content;
            }
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

/// Hides an element but makes it available for screen readers.
@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

/// Text overflow mixin
@mixin text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/// Mixin to change placeholder style (font\color etc)
//  @example
//    .text-input {
//      @include placeholder {
//        color: #ccc;
//      }
//    }
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;

        opacity: 1;
    }

    &::-moz-placeholder { // stylelint-disable-line
        @content;

        opacity: 1;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

/// Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.
///
/// @param {Number} $triangle-size - Width of the triangle.
/// @param {Color} $triangle-color - Color of the triangle.
/// @param {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
@mixin css-triangle($triangle-color, $triangle-size: 6px, $triangle-direction: down) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset $triangle-size;

    @if ($triangle-direction == down) {
        border-color: $triangle-color transparent transparent;
        border-top-style: solid;
        border-bottom-width: 0;
    }
    @if ($triangle-direction == up) {
        border-color: transparent transparent $triangle-color;
        border-bottom-style: solid;
        border-top-width: 0;
    }
    @if ($triangle-direction == right) {
        border-color: transparent transparent transparent $triangle-color;
        border-left-style: solid;
        border-right-width: 0;
    }
    @if ($triangle-direction == left) {
        border-color: transparent $triangle-color transparent transparent;
        border-right-style: solid;
        border-left-width: 0;
    }
}

@mixin highlight {
    @include media-breakpoint-up(lg) {
        transition: opacity $transition-base-duration;

        @include on-event {
            opacity: 0.8;
            outline: none;
        }
    }
}

@mixin limit-text-lines($line-height: $line-height-base, $font-size: $font-size-base, $max-lines: 1, $is-max-height: false) {
    overflow: hidden;

    @if ($is-max-height) {
        max-height: $line-height * $font-size * $max-lines;
    } @else {
        height: $line-height * $font-size * $max-lines;
    }
}

@mixin rtl-english-fix {
    &::before,
    &::after {
        content: "\200E";
    }
}
