.l-pdp {
    .form-control {
        border-radius: $border-radius-small;
        font-size: $body-font-size;
        padding: 12px 10px 10px;

        &:not([size]):not([multiple]) {
            height: 44px;
        }

        &.custom-select {
            span {
                text-align: left;
            }

            &::before {
                @include inline-svg($icon-arrow-bottom-secondary);

                border: 0;
                height: 16px;
                right: 16px;
                top: 14px;
                width: 16px;
            }
        }
    }

    .form-group {
        margin-bottom: 24px;

        .form-input-wrapper.m-datepicker {
            .form-control {
                padding-left: 50px;
            }

            &::before {
                @include inline-svg($icon-calendar-empty);
                top: 9px;
            }
        }
    }
}

.card {
    .payment-option {
        .card-method,
        .apple-pay-method {
            &:checked {
                ~ .payment-method-label {
                    &::after {                        
                        background-color: $green;                        
                    }
                }
            }
        }
    }
}