$tabs-tertiary-nav-gutter: 5px !default;
$tabs-tertiary-nav-list-margin: 0 (-$tabs-tertiary-nav-gutter) 5px !default;
$tabs-tertiary-nav-item-padding: 7px 10px 5px !default;
$tabs-tertiary-nav-item-margin: 0 $tabs-tertiary-nav-gutter ($tabs-tertiary-nav-gutter * 2) !default;
$tabs-tertiary-nav-item-color: color('base-0') !default;
$tabs-tertiary-nav-item-bg-color: $white !default;
$tabs-tertiary-nav-item-border-color: color('base-6') !default;
$tabs-tertiary-nav-item-bg-color-active: $tabs-tertiary-nav-item-border-color !default;
$tabs-tertiary-nav-item-border-color-active: $black !default;
$tabs-tertiary-nav-item-border-radius: 15px !default;
$tabs-tertiary-nav-item-before-size: 16px !default;
$tabs-tertiary-nav-item-before-border-size: 1px !default;
$tabs-tertiary-nav-item-before-border-size-active: 4px !default;
$tabs-tertiary-nav-item-before-margin: 0 8px 0 0 !default;
$tabs-tertiary-nav-item-before-border-color: $black !default;
$tabs-tertiary-nav-item-before-border-color-event: $tabs-tertiary-nav-item-border-color-active !default;
$tabs-tertiary-nav-item-before-bg-color: $white !default;
$tabs-tertiary-nav-item-before-border: $tabs-tertiary-nav-item-before-border-size solid $tabs-tertiary-nav-item-before-border-color !default;

.tabs-tertiary-nav {
    &-list {
        margin: $tabs-tertiary-nav-list-margin;
        flex-wrap: wrap;
    }

    &-item {
        flex: 0 1 auto;
        padding: $tabs-tertiary-nav-item-padding;
        margin: $tabs-tertiary-nav-item-margin;
        color: $tabs-tertiary-nav-item-color;
        background-color: $tabs-tertiary-nav-item-bg-color;
        border: 1px solid $tabs-tertiary-nav-item-border-color;
        border-radius: $tabs-tertiary-nav-item-border-radius;

        @include on-event {
            &::before {
                border-color: $tabs-tertiary-nav-item-before-border-color-event;
            }
        }

        &::before {
            position: relative;
            top: -1px;
            display: inline-block;
            width: $tabs-tertiary-nav-item-before-size;
            height: $tabs-tertiary-nav-item-before-size;
            margin: $tabs-tertiary-nav-item-before-margin;
            border-radius: 50%;
            border: $tabs-tertiary-nav-item-before-border;
            background-color: $tabs-tertiary-nav-item-before-bg-color;
            vertical-align: middle;
            content: '';
        }

        &.active {
            background-color: $tabs-tertiary-nav-item-bg-color-active;

            &::before {
                border-width: $tabs-tertiary-nav-item-before-border-size-active;
                border-color: $tabs-tertiary-nav-item-border-color-active;
            }
        }
    }
}
