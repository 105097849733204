.b-load_progress {
    margin: 0 auto;
    max-width: 410px;
    padding-top: 40px;
    text-align: center;

    @include media(md-up) {
        padding-top: 48px;
    }

    @include media(sm) {
        display: none;
    }

    &-description {
        margin-bottom: 24px;
    }

    &-indicator {
        background: $border-color;
        height: 2px;
        margin-bottom: 32px;
        position: relative;
    }

    &-value {
        background: $blue;
        border-radius: $border-radius-small;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        translate: 0 -50%;
    }
}
