$content-history-background-color: #f3f3f3 !default;
$content-history-border-color: color('base-1') !default;
$content-history-title-color: color('base-0') !default;
$content-history-year-color: color('primary-1') !default;
$content-history-center-year-color: color('base-0') !default;
$content-history-dot-background-color: color('primary-1') !default;
$content-history-card-year-font-size: 22px !default;
$content-history-card-title-font-size: 18px !default;
$content-page-box-shadow: 0 1px 4px 0 rgba(#2d2423, 0.1) !default;

.content-history {
    position: relative;
    padding: 60px 0;

    @include media-breakpoint-up(lg) {
        padding-bottom: 200px;
    }

    &::before {
        @include text-alignment($horizontal: true, $vertical: true);

        content: '';
        width: 1px;
        height: 100%;
        opacity: 0.5;
        background-color: $content-history-border-color;
    }

    &-center-year {
        position: absolute;
        top: 70%;
        text-align: center;
        color: $content-history-center-year-color;
        padding: 4px 18px;
        border-radius: 15px;
        background-color: $content-history-background-color;
    }

    &-card-wrapper {
        position: relative;

        &::before {
            @include media-breakpoint-up(lg) {
                @include text-alignment($horizontal: false, $vertical: true);

                content: '';
                height: 1px;
                width: 12%;
                opacity: 0.5;
                background-color: $content-history-border-color;
            }

            @include media-breakpoint-up(xl) {
                width: 22%;
            }
        }

        &::after {
            @include media-breakpoint-up(lg) {
                @include text-alignment($horizontal: false, $vertical: true);

                content: '';
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: $content-history-dot-background-color;
            }
        }

        &-left {
            &::before {
                right: 0;
            }

            &::after {
                right: calc(12% + 3px);

                @include media-breakpoint-up(xl) {
                    right: calc(22% + 3px);
                }
            }

            .content-history-center-year {
                right: 0;
                transform: translateX(50%);
            }
        }

        &-right {
            @include media-breakpoint-up(lg) {
                top: 50%;
            }

            &::before {
                left: 0;
            }

            &::after {
                left: calc(12% + 3px);

                @include media-breakpoint-up(xl) {
                    left: calc(22% + 3px);
                }
            }

            .content-history-center-year {
                left: 0;
                transform: translateX(-50%);
            }
        }
    }

    &-title {
        @include text-alignment($horizontal: true, $vertical: false);

        text-align: center;
        color: $content-history-title-color;
        padding: 4px 18px;
        border-radius: 15px;
        background-color: $content-history-background-color;

        &-top {
            top: 0;
        }

        &-bottom {
            bottom: 0;
        }
    }

    &-card {
        max-width: 304px;
        margin: 0 auto;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: $content-page-box-shadow;
        position: relative;
        background-color: $white;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 90px;
        }

        &-content {
            padding: 25px 35px 30px;
        }

        &-year {
            font-size: $content-history-card-year-font-size;
            text-align: left;
            color: $content-history-year-color;
            margin-bottom: 20px;
        }

        &-title {
            font-size: $content-history-card-title-font-size;
            text-align: left;
            color: $content-history-title-color;
            margin-bottom: 20px;
        }

        &-description {
            text-align: left;
        }

        img {
            height: 160px;
        }
    }
}

