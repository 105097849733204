@mixin link-defaults($link-color: $link-color, $link-decoration: $link-decoration, $link-hover-color: $link-hover-color, $link-hover-decoration: $link-hover-decoration) {
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

@mixin link-button() {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

@mixin link-icon($icon-name, $icon-size: 20px, $direction: left) {
    .link-icon-in {
        position: relative;
        display: inline-block;
        padding-#{$direction}: $icon-size + 5;
        text-decoration: inherit;
        vertical-align: top;

        &::before {
            @include inline-svg-bg($icon-name);

            content: '';
            display: block;
            width: $icon-size;
            height: $icon-size;
            position: absolute;
            #{$direction}: 0;
            top: 45%;
            transform: translateY(-50%);
        }
    }
}
