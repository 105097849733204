// -----------------------------------------------------------------------------
// This file contains all styles related to the link component.
// -----------------------------------------------------------------------------

// Links
//
// .link-icon.m-edit - edit link
// .link-icon.m-remove - remove link
// .link-icon.m-back - remove link
// .link-icon.m-whatsapp - whatsapp phone number link
// .link-icon.m-support - support phone number link
// .link-icon.m-signout - sign out link
// .link-icon.m-toggle - toggle link
// .link-icon.m-info.m-secondary - info link
//
// Markup:
// <a href="#" class="{{modifier_class}}"><span class="link-icon-in">link</span></a>
//
// Styleguide 5

$link-secondary-color: color('base-0') !default;
$link-secondary-hover-color: color('base-5') !default;
$link-icon-secondary-color: inherit !default;
$link-icon-secondary-color-event: color('base-0') !default;
$link-icon-watch-color: inherit !default;
$link-icon-calendar-color: inherit !default;

.link-icon {
    &.m-edit {
        @include link-icon($icon-edit-primary, 12px);
    }

    &.m-remove {
        @include link-defaults;
        @include link-button;
        @include link-icon($icon-close-primary, 10px);

        margin-left: 20px;
    }

    &.m-back {
        @include link-icon($icon-menu-arrow-left, 10px);
    }

    &.m-toggle {
        @include link-icon($icon-arrow-bottom-primary, 10px, right);

        &.m-active {
            @include link-icon($icon-arrow-top-primary, 10px, right);
        }
    }

    &.m-tertiary {
        font-size: 16px;
        margin-bottom: 15px;
        display: inline-block;

        &.m-toggle {
            @include link-icon($icon-arrow-bottom-secondary, 10px, right);

            text-decoration: none;

            .link-icon-in-container {
                border-bottom: 1px solid color('base-0');
            }

            &.m-active {
                @include link-icon($icon-arrow-top-secondary, 10px, right);

                .link-icon-in-container {
                    border-bottom: 1px solid color('primary-1');
                }
            }
        }
    }

    &.m-signout {
        @include link-icon($icon-signout, 12px, right);
    }

    &.m-account {
        @include link-icon($icon-user);
    }

    &.m-wishlist {
        @include link-icon($icon-wishlist);
    }

    &.m-whatsapp {
        @include link-icon($icon-whatsapp, 22px);
    }

    &.m-support {
        @include link-icon($icon-phone, 18px);

        @include media-breakpoint-up(lg) {
            pointer-events: none;
        }
    }

    &.m-whatsapp,
    &.m-support {
        @include when-inside('.menu-opened') {
            @include media-breakpoint-down(md) {
                .link-icon-in {
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &.m-info {
        @include link-icon($icon-info-dark, 22px);
    }

    &.m-watch {
        @include link-icon($icon-watch, 18px);

        color: $link-icon-watch-color;
    }

    &.m-calendar {
        @include link-icon($icon-calendar, 22px);

        color: $link-icon-calendar-color;
    }

    &.m-secondary {
        @include link-button;

        display: inline-block;
        padding: 2px 0;
        color: $link-icon-secondary-color;
        text-decoration: underline;

        @media (pointer: fine) {
            @include on-event {
                text-decoration: none;
                color: $link-icon-secondary-color-event;
            }
        }
    }
}

.link-secondary {
    color: $link-secondary-color;

    @include hover {
        color: $link-secondary-hover-color;
        text-decoration: none;
    }

    &.m-highlight {
        color: color('base-5');
    }
}

.link-password-reset {
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;

    @include hover {
        text-decoration: underline;
    }
}
