$pdp-service-modal-grid-gutter: 6px !default;
$pdp-service-modal-caption-margin: 0 0 15px !default;
$pdp-service-modal-caption-font-size: 16px !default;
$pdp-service-modal-caption-font: $font-weight-medium $pdp-service-modal-caption-font-size $font-primary !default;
$pdp-service-modal-label-color: $black !default;
$pdp-service-modal-sub-padding: 0 0 5px !default;
$pdp-service-modal-sub-font-size: 12px !default;
$pdp-service-modal-sub-color: $black !default;
$pdp-service-modal-content-padding: 0 0 20px !default;
$pdp-service-modal-description-content-padding: 20px 0 0 !default;
$pdp-service-modal-description-content-padding-md: 0 !default;
$pdp-service-modal-description-content-color: $black !default;
$pdp-service-modal-footer-padding: 30px 0 0 !default;
$pdp-service-modal-footer-border-color: color('base-6') !default;

.pdp-service-modal {
    &.m-grid {
        .row {
            margin-left: -($pdp-service-modal-grid-gutter);
            margin-right: -($pdp-service-modal-grid-gutter);
        }

        [class*="col-"],
        .col {
            padding-right: $pdp-service-modal-grid-gutter;
            padding-left: $pdp-service-modal-grid-gutter;
        }
    }

    &-grid {
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;

            &.chauffeur-arrival,
            &.chauffeur-departure {
                width: 50%;
                padding-top: 20px;

                .form-group {
                    .pdp-service-modal-sub {
                        margin: 0;
                    }
                }

                .form-input-wrapper {
                    &::before {
                        background-image: none;
                    }

                    .form-control {
                        border: none;
                        padding: 0;
                        min-height: auto;
                        color: $dark;
                    }
                }

                ~ hr {
                    width: 50%;
                    left: -30%;
                    margin-top: 0;
                    position: relative;
                }
            }
        }
    }

    &-side {
        @include media-breakpoint-up(md) {
            width: 120px;
        }
    }

    &-main {
        @include media-breakpoint-up(md) {
            width: calc(100% - 150px);
        }
    }

    &-caption {
        margin: $pdp-service-modal-caption-margin;
        font: $pdp-service-modal-caption-font;
    }

    &-label {
        color: $pdp-service-modal-label-color;
    }

    &-sub {
        padding: $pdp-service-modal-sub-padding;
        font-size: $pdp-service-modal-sub-font-size;
        color: $pdp-service-modal-sub-color;
    }

    &-content {
        @include media-breakpoint-up(md) {
            max-height: calc(80vh - 190px);
            min-height: 200px;
            overflow: auto;
            padding: $pdp-service-modal-content-padding;

            &.chauffeur {
                max-height: calc(80vh - 120px);
            }
        }
    }

    &-description {
        &-image {
            width: 100%;
            height: 120px;
            object-fit: cover;
            object-position: 50% 0;
        }

        &-content {
            @include limit-text-lines($line-height: 1.35, $font-size: $body-font-size, $max-lines: 10, $is-max-height: true);

            padding: $pdp-service-modal-description-content-padding;
            color: $pdp-service-modal-description-content-color;

            @include media-breakpoint-up(md) {
                padding: $pdp-service-modal-description-content-padding-md;
            }
        }
    }

    &-radio-list {
        display: flex;
        flex-wrap: wrap;

        .form-group {
            margin-right: 20px;

            @include media-breakpoint-up(md) {
                margin-right: 30px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-textarea {
        textarea.form-control {
            min-height: 80px;
        }
    }

    &-footer {
        padding: $pdp-service-modal-footer-padding;
        border-top: 1px solid $pdp-service-modal-footer-border-color;
    }

    &-cancel-wrap {
        @include media-breakpoint-down(sm) {
            order: 1;
            margin-top: 15px;
            text-align: center;
        }
    }

    &-save {
        display: inline-block;
        max-width: 200px;
        width: 100%;
    }
}
