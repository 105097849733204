$footer-border-color: transparent !default;

$footer-nav-title-font-size: 18px !default;
$footer-nav-title-font-weight: $font-weight-normal !default;
$footer-nav-item-font-size: 14px !default;
$footer-nav-item-line-height: 1.5 !default;

$footer-content-font-size: 14px !default;
$footer-content-line-height: 1.7 !default;
$footer-content-color: inherit !default;

// support
$footer-support-max-width: 220px !default;

$footer-support-title-font-size: 16px !default;
$footer-support-title-font-size-lg: 14px !default;
$footer-support-title-font-weight: $font-weight-medium !default;
$footer-support-title-font-family: $font-primary !default;
$footer-support-title-font: $footer-support-title-font-weight $footer-support-title-font-size $footer-support-title-font-family !default;

$footer-support-font-size: 15px !default;
$footer-support-font-size-lg: 14px !default;
$footer-support-list-font-size: 14px !default;

$footer-support-text-large-font-size: 22px !default;
$footer-support-text-large-font-size-lg: 20px !default;
$footer-support-text-large-font-weight: $font-weight-bold !default;
$footer-support-text-large-font-family: $font-primary !default;
$footer-support-text-large-font: $footer-support-text-large-font-weight $footer-support-text-large-font-size $footer-support-text-large-font-family !default;

$footer-support-text-middle-font-size: 18px !default;
$footer-support-text-middle-font-size-lg: 20px !default;
$footer-support-text-middle-font-weight: $font-weight-bold !default;
$footer-support-text-middle-font-family: $font-primary !default;
$footer-support-text-middle-font: $footer-support-text-middle-font-weight $footer-support-text-middle-font-size $footer-support-text-middle-font-family !default;

$footer-support-text-small-font-size: 16px !default;
$footer-support-text-small-font-size-lg: 14px !default;
$footer-support-text-small-font-weight: $font-weight-bold !default;
$footer-support-text-small-font-family: $font-primary !default;
$footer-support-text-small-font: $footer-support-text-small-font-weight $footer-support-text-small-font-size $footer-support-text-small-font-family !default;
$footer-support-text-small-opacity: 0.4 !default;

// subscribe
$footer-subscribe-max-width: 220px !default;
$footer-subscribe-title-color: inherit !default;
$footer-button-font-size: 16px !default;

// awards
$footer-awards-item-vertical-align: middle !default;

// copyright
$footer-copyright-font-size: 12px !default;
$footer-copyright-year-color: inherit !default;

// policy
$footer-policy-font-size: 12px !default;

.footer {
    position: relative;
    padding: 45px 0 25px;
    color: $footer-text-color;
    background-color: $footer-bg-color;
    z-index: z('default');
    border-top: 1px solid $footer-border-color;

    @if ($theme-brand == 'marhaba') {
        &::before {
            opacity: 0.05;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 30px 0;
    }

    a[href] {
        &:not(.btn) {
            color: inherit;
        }
    }

    .container {
        position: relative;
    }

    &-inner {
        max-width: 352px;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }

    &-title {
        display: block;
        margin-bottom: 20px;
        color: $footer-title-color;
        font: $footer-title-font;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: $footer-title-font-size-lg;
            text-align: left;
        }
    }

    &-middle {
        position: relative;
        padding-top: 30px;

        &::before {
            @include media-breakpoint-up(lg) {
                content: '';
                position: absolute;
                left: $grid-gutter-width * 0.5;
                right: $grid-gutter-width * 0.5;
                top: 0;
                border-top: 1px solid $footer-color-divider;
            }
        }

        &-item {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;

                &:first-child {
                    justify-content: flex-start;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }
        }
    }

    &-logo {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            max-width: $footer-logo-max-width;
            margin-bottom: $footer-logo-margin;

            .content-asset {
                width: 200px;
                height: 72px;
            }
        }

        @include media-breakpoint-up(xl) {
            max-width: $footer-logo-max-width-xl;
            margin-bottom: $footer-logo-margin-xl;
        }
    }

    &-support {
        max-width: $footer-support-max-width;
        margin: 0 auto 45px;
        font-size: $footer-support-font-size;
        text-align: center;

        @include media-breakpoint-up(lg) {
            max-width: 100%;
            margin: 0 0 30px;
            font-size: $footer-support-font-size-lg;
            text-align: left;
        }

        &-title {
            margin: 0 0 10px;
            font: $footer-support-title-font;
            color: $footer-support-title-color;

            @include media-breakpoint-up(lg) {
                margin: 0 0 5px;
                font-size: $footer-support-title-font-size-lg;
            }
        }

        &-text {
            display: inline-block;

            &.m-text {
                &-large {
                    font: $footer-support-text-large-font;

                    @include media-breakpoint-up(lg) {
                        font-size: $footer-support-text-large-font-size-lg;
                    }
                }

                &-medium {
                    font: $footer-support-text-middle-font;

                    @include media-breakpoint-up(lg) {
                        font-size: $footer-support-text-middle-font-size-lg;
                    }
                }

                &-small {
                    font: $footer-support-text-small-font;
                    opacity: $footer-support-text-small-opacity;

                    @include media-breakpoint-up(lg) {
                        font-size: $footer-support-text-small-font-size-lg;
                    }
                }
            }
        }

        &-row {
            margin-bottom: 30px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 15px;
            }

            @include media-breakpoint-up(xl) {
                display: flex;
            }
        }

        &-cell {
            display: block;

            &:first-child {
                @include media-breakpoint-up(xl) {
                    width: 35%;
                    flex: 0 0 35%;
                    max-width: 35%;
                    padding-right: 10px;
                }
            }
        }

        &-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: $footer-support-list-font-size;

            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
        }

        li {
            @include media-breakpoint-up(lg) {
                padding-right: 20px;
            }

            &:first-child {
                @include media-breakpoint-up(xl) {
                    width: 35%;
                    flex: 0 0 35%;
                    max-width: 35%;
                    padding-right: 10px;
                }
            }

            &:last-child {
                padding-right: 0;
            }

            &:only-child {
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                    margin-right: 0;
                }

                @include media-breakpoint-up(xl) {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &-nav {
        &-row {
            margin: 0 0 30px;

            @include media-breakpoint-up(lg) {
                display: flex;
                margin: 0 (-$grid-gutter-width * 0.5);
            }
        }

        &-col {
            @include media-breakpoint-up(lg) {
                flex-basis: 0;
                flex-grow: 1;
                width: 100%;
                max-width: 100%;
                padding: 0 ($grid-gutter-width * 0.5);
                margin: 0 0 15px;
            }
        }

        &-title {
            display: block;
            font-size: $footer-nav-title-font-size;
            font-weight: $footer-nav-title-font-weight;

            @include media-breakpoint-up(lg) {
                margin: 0 0 20px;
            }
        }

        li { // stylelint-disable-line no-descending-specificity
            margin: 0 0 15px;
            font-size: $footer-nav-item-font-size;
            line-height: $footer-nav-item-line-height;
        }
    }

    &-content {
        margin-top: 45px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin: 0;
            text-align: left;
        }

        &-head {
            margin-bottom: 15px;
        }

        &-img {
            max-height: 60px;
        }

        &-text {
            color: $footer-content-color;
            font-size: $footer-content-font-size;
            line-height: $footer-content-line-height;
        }
    }

    .accordion-init {
        .accordion {
            &-icon {
                @include inline-svg-bg($icon-footer-accordion);

                width: 12px;
                height: 12px;
                flex: 0 0 12px;
            }

            &-item {
                border-color: $footer-accordion-border-color;
            }
        }
    }

    &-social {
        @include divider-full-width($color: $footer-color-divider, $placing: bottom);

        margin: 0 0 30px;
        padding: 0 0 30px;

        @include media-breakpoint-up(lg) {
            @include divider-full-width(false);

            padding: 0;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
        }

        &-link {
            width: 40px;
            height: 40px;
        }

        li { // stylelint-disable-line no-descending-specificity
            width: 40px;
            margin: 0 12px 10px;
            @include media-breakpoint-up(lg) {
                margin: 0 24px 0 0;

                &:last-child {
                    margin: 0;
                }
            }

            a {
                @include highlight;
            }
        }
    }

    &-payment {
        margin-bottom: 30px;

        &-list {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;

            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
        }

        li { // stylelint-disable-line no-descending-specificity
            width: 52px;
            height: 29px;
            margin: 0 10px;
            @include media-breakpoint-up(lg) {
                margin: 0 24px 0 0;

                &:last-child {
                    margin: 0;
                }
            }

            .theme-marhaba & {
                width: 58px;
                height: 32px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-subscribe {
        max-width: $footer-subscribe-max-width;
        width: 100%;
        margin: 0 auto 45px;

        @include media-breakpoint-up(lg) {
            margin: 0 0 45px;
        }

        &-title {
            color: $footer-subscribe-title-color;
        }
    }

    &-awards {
        margin: 0 0 30px;

        &-list {
            text-align: center;

            &.m-space {
                @include media-breakpoint-up(lg) {
                    margin-bottom: -50%;
                }
            }

            li {
                &:first-child {
                    width: 90px;
                    height: 80px;
                }

                &:last-child {
                    width: 102px;
                    height: 28px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        li { // stylelint-disable-line no-descending-specificity
            display: inline-block;
            margin: 5px 10px;
            vertical-align: $footer-awards-item-vertical-align;
            max-width: $footer-awards-item-max-width;

            @include media-breakpoint-up(lg) {
                max-width: $footer-awards-item-max-width-lg;
            }
        }
    }

    &-copyright {
        margin: 0 0 30px;
        font-size: $footer-copyright-font-size;
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin: 0;
            text-align: left;
        }

        &-year {
            display: inline-block;
            margin: 0 5px 0 0;
            color: $footer-copyright-year-color;
        }
    }

    &-policy {
        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -10px;
            font-size: $footer-policy-font-size;
        }

        &-link {
            display: inline-block;
            margin: 2px 10px;
        }

        li { // stylelint-disable-line no-descending-specificity
            position: relative;

            &::after {
                content: '|';
                display: inline-block;
                opacity: 0.5;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    &.m-secondary {
        .footer-inner {
            max-width: 276px;

            @include media-breakpoint-up(lg) {
                max-width: 100%;
            }
        }

        .footer-logo {
            margin-bottom: 20px;
            display: block;
            max-width: 160px;
        }

        .footer-title,
        .footer-support,
        .footer-copyright {
            text-align: left;
        }

        .footer-support {
            max-width: 100%;

            &-text {
                margin-bottom: 15px;
            }

            &-list {
                justify-content: normal;

                li {
                    padding-right: 25px;
                    width: auto;
                    flex: none;
                }
            }

            &-cell {
                &:first-child {
                    @include media-breakpoint-up(lg) {
                        max-width: 100%;
                        width: auto;
                    }
                }
            }

            li {
                &:first-child {
                    @include media-breakpoint-up(lg) {
                        max-width: 100%;
                        width: auto;
                    }
                }
            }
        }

        .footer-payment {
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }

            li { // stylelint-disable-line no-descending-specificity
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .footer-payment-list {
            justify-content: normal;

            @include media-breakpoint-down(md) {
                li:first-child {
                    margin-left: 0;
                }
            }
        }

        .footer-policy-list {
            @include media-breakpoint-down(md) {
                display: block;
                margin: 0;

                li {
                    margin-bottom: 10px;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
