// carousel
$testimonials-carousel-padding: 20px 0 !default;
$testimonials-carousel-margin: 0 0 50px !default;
$testimonials-carousel-font-size-lg: 16px !default;
$testimonials-carousel-color: color('base-0') !default;
$testimonials-carousel-bg-color: color('base-1') !default;
// carousel-inner
$testimonials-carousel-inner-padding-md: 10px 0 0 !default;
$testimonials-carousel-inner-icon-width: 80px !default;
$testimonials-carousel-inner-icon-height: 54px !default;
$testimonials-carousel-inner-icon-width-lg: 90px !default;
$testimonials-carousel-inner-icon-height-lg: 60px !default;
$testimonials-carousel-inner-icon-quote-open: $icon-quote-open !default;
$testimonials-carousel-inner-icon-quote-close: $icon-quote-close !default;
// carousel-title
$testimonials-carousel-title-font-size-lg: 34px !default;
$testimonials-carousel-title-margin-bottom: 26px !default;
// carousel-content
$testimonials-carousel-content-line-height: 1.6 !default;
$testimonials-carousel-content-letter-spacing: 0 !default;
$testimonials-carousel-content-font-size-lg: 16px !default;
// carousel-author
$testimonials-carousel-author-padding-vertical: 10px !default;
$testimonials-carousel-author-padding: $testimonials-carousel-author-padding-vertical 0 !default;
$testimonials-carousel-author-font-size: 16px !default;
$testimonials-carousel-author-font-size-md: 14px !default;
$testimonials-carousel-author-color: $dark !default;
// carousel-tns-controls
$testimonials-carousel-tns-controls-height: 90px !default;
$testimonials-carousel-tns-controls-spacing: 20px !default;
$testimonials-carousel-tns-controls-top: -($testimonials-carousel-tns-controls-height + $testimonials-carousel-tns-controls-spacing) !default;
$testimonials-carousel-tns-controls-border-color: color('base-0') !default;
$testimonials-carousel-tns-controls-icon-width: 60px !default;
$testimonials-carousel-tns-controls-icon-height: 40px !default;
$testimonials-carousel-tns-controls-button-size: 30px !default;
$testimonials-carousel-tns-controls-button-size-md: 24px !default;
$testimonials-carousel-tns-controls-button-bottom-md: ceil($paragraph-margin-bottom + $testimonials-carousel-author-font-size-md + $line-height-base + $testimonials-carousel-author-padding-vertical * 2) !default;
$testimonials-carousel-tns-controls-button-gutters: 0 !default;
$testimonials-carousel-tns-controls-button-gutters-md: 2% !default;
$testimonials-carousel-tns-controls-gutter-height: 24px !default;
// carousel-tns-outer
$testimonials-carousel-tns-outer-padding-md: 0 percentage(calc(1/$grid-columns)) !default;

.testimonials-carousel {
    padding: $testimonials-carousel-padding;
    margin: $testimonials-carousel-margin;
    color: $testimonials-carousel-color;
    background-color: $testimonials-carousel-bg-color;

    @include media-breakpoint-up(lg) {
        font-size: $testimonials-carousel-font-size-lg;
    }

    &-wrapper {
        position: relative;

        @include media-breakpoint-up(md) {
            padding: $testimonials-carousel-tns-outer-padding-md;
        }

        &::before {
            @include inline-svg-bg($testimonials-carousel-inner-icon-quote-open);
            @include block-alignment-secondary;

            content: '';
            display: block;
            height: $testimonials-carousel-tns-controls-icon-height;
            margin-bottom: 48px;
            position: relative;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &::after {
            background-color: $testimonials-carousel-tns-controls-border-color;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
            top: $testimonials-carousel-tns-controls-icon-height + $testimonials-carousel-tns-controls-gutter-height;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &-inner {
        position: relative;
        z-index: z('default');

        @include media-breakpoint-up(md) {
            padding: $testimonials-carousel-inner-padding-md;
        }

        &::before,
        &::after {
            @include media-breakpoint-up(md) {
                position: absolute;
                width: $testimonials-carousel-inner-icon-width;
                height: $testimonials-carousel-inner-icon-height;
                z-index: z('below');
                content: '';
            }

            @include media-breakpoint-up(lg) {
                width: $testimonials-carousel-inner-icon-width-lg;
                height: $testimonials-carousel-inner-icon-height-lg;
            }
        }

        &::before {
            @include inline-svg-bg($testimonials-carousel-inner-icon-quote-open);

            top: 0;
            left: 0;
        }

        &::after {
            @include inline-svg-bg($testimonials-carousel-inner-icon-quote-close);

            bottom: 0;
            right: 0;
        }
    }

    &-item {
        display: none;

        &:first-child {
            display: inline-block;
        }
    }

    &-title {
        margin-bottom: $testimonials-carousel-title-margin-bottom;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: $testimonials-carousel-title-font-size-lg;
        }
    }

    &-controls {
        @include transition('opacity');

        opacity: 0;

        @include media-breakpoint-down(sm) {
            left: 0;
            position: absolute;
            right: 0;
            top: $testimonials-carousel-tns-controls-icon-height*0.5;
        }

        @include when-inside('.carousel-init') {
            opacity: 1;
        }

        @include tiny-carousel-controls($arrow-icon-size: $testimonials-carousel-tns-controls-button-size, $arrow-icon-gutters: $testimonials-carousel-tns-controls-button-gutters) {
            top: 0;
            bottom: 0;
            margin: auto 0;

            @include media-breakpoint-up(md) {
                width: $testimonials-carousel-tns-controls-button-size-md;
                height: $testimonials-carousel-tns-controls-button-size-md;
                bottom: $testimonials-carousel-tns-controls-button-bottom-md;
            }

            &[data-controls="prev"] {
                @include media-breakpoint-up(md) {
                    left: $testimonials-carousel-tns-controls-button-gutters-md rtl-ignore();
                }
            }

            &[data-controls="next"] {
                @include media-breakpoint-up(md) {
                    right: $testimonials-carousel-tns-controls-button-gutters-md rtl-ignore();
                }
            }
        }
    }

    &-content {
        line-height: $testimonials-carousel-content-line-height;
        letter-spacing: $testimonials-carousel-content-letter-spacing;
        text-align: justify;

        @include media-breakpoint-up(lg) {
            font-size: $testimonials-carousel-content-font-size-lg;
            text-align: center;
        }
    }

    &-author {
        padding: $testimonials-carousel-author-padding;
        font-size: $testimonials-carousel-author-font-size;
        color: $testimonials-carousel-author-color;

        @include media-breakpoint-up(md) {
            font-size: $testimonials-carousel-author-font-size-md;
        }

        @include media-breakpoint-up(lg) {
            text-align: center;
        }
    }

    .tns-inner {
        overflow: hidden;
        user-select: none;
    }

    .tns-carousel {
        align-items: center;
        direction: ltr;
        display: flex;
    }
}
