.l-plp {
    @include media(md-up) {
        @include flex;

        column-gap: 20px;
    }

    h2 {
        font-size: 20px;
    }

    &-wrapper {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;

        @include media(lg-down) {
            padding: 0 16px;
        }

        .b-header_search {
            padding-top: 32px;

            @include media(lg-up) {
                padding-top: 60px;
                padding-bottom: 20px;
            }

            &-title,
            &-title_no_results {
                margin-bottom: 40px;
            }

            &-title {
                text-align: center;
            }
        }

        .content-hero {
            border-radius: $border-radius;
            margin-bottom: 40px;
            overflow: hidden;
            padding: 0;

            @include media(md-up) {
                margin-bottom: 60px;
            }

            .content-hero {
                margin-bottom: 0;
            }

            .image-container {
                &::before {
                    display: none !important;
                }

                img {
                    position: static !important;
                }
            }
        }
    }

    &-products {
        flex-grow: 1;
    }

    &-product_tile {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin-bottom: 40px;
        position: relative;

        @include media(md-up) {
            margin-bottom: 24px;
            padding: 24px;
        }

        .product-badge {
            background-color: transparent;
            border-radius: 0;
            color: $white;
            padding-top: 4px;
            padding-left: 8px;
            font-size: 12px;
            font-family: $font-emirates;

            &-container {
                background: $red;
                flex-direction: row;
                right: auto;
                left: 24px;
                top: 20px;
                border-radius: $border-radius-badge $border-radius-badge 0 0;
                overflow: hidden;
                z-index: z('below');

                @include media(sm) {
                    left: 0;
                    top: -25px;
                }
            }
        }

        .m-promo {
            @include media(md-up) {
                padding-top: 20px;
            }
        }
    }

    .original-product-badge {
        position: absolute;
        left: 12px;
        bottom: 12px;
    }

    .main-image-carousel {
        margin: 0;

        &::after,
        &::before {
            display: none;
        }
    }

    .pdp-link {
        font-size: 24px;
        margin-bottom: 8px;

        a,
        a:hover {
            color: $blue;
        }
    }

    .tns {
        &-outer {
            margin: 0;
        }

        &-item {
            position: relative;
        }

        &-nav {
            bottom: 18px;
            column-gap: 18px;

            button {
                background: transparent;
                border-color: $white;
                flex-shrink: 0;
                margin: 0;
                height: 6px;
                width: 6px;

                &::after {
                    display: none;
                }

                &.tns-nav-active {
                    background: $white;
                }
            }
        }
    }

    .js-tiles {
        &-thumbnails-controls {
            display: block;

            button {
                border-radius: 50%;
                height: 32px;
                margin: 0;
                width: 32px;
            }

            @include media(sm) {
                display: none;
            }
        }

        &-carousel-control {
            &-prev[data-controls] {
                @include inline-svg($icon-chevron-left);

                background-color: rgba($white, 0.4);
                left: 12px;

                &:disabled,
                &:hover {
                    @include inline-svg($icon-chevron-left);

                    background-color: rgba($white, 0.4);
                }

                &:hover:not(:disabled) {
                    background-color: $white;
                }
            }

            &-next[data-controls] {
                @include inline-svg($icon-chevron-right);

                background-color: rgba($white, 0.4);
                right: 12px;

                &:disabled,
                &:hover {
                    @include inline-svg($icon-chevron-right);

                    background-color: rgba($white, 0.4);
                }

                &:hover:not(:disabled) {
                    background-color: $white;
                }
            }
        }
    }

    .b-load_more {
        text-align: center;
        margin-bottom: 80px;

        @include media(md-up) {
            margin-bottom: 140px;
        }

        .m-desktop {
            @include media(sm) {
                display: none;
            }
        }

        .m-mobile {
            @include media(md-up) {
                display: none;
            }
        }

        &-button {
            @include media (md) {
                display: inline-block;
            }
        }
    }
}
