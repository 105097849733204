
$this: ".b-service_tile";

.b-service_tile {
    &-content {
        border: 1px solid $border-color;
        border-top: 0;
        border-radius: 0 0 $border-radius $border-radius;

        #{$this}-title {
            color: $blue;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.1;
            letter-spacing: 0.24px;
        }
    }

    .publish-date-strip {
        margin-bottom: 18px;
    }
}
