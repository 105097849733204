.b-pdp_header {
    padding-top: 16px;

    @include media(md-up) {
        padding-top: 24px;
    }

    .breadcrumbs {
        margin-bottom: 8px;
    }

    &-title {
        margin-bottom: 6px;

        @include media(md-up) {
            font-size: 42px;
            margin-bottom: 0;
        }
    }

    &-row {
        @include flex($horizontal: space-between, $vertical: flex-end, $wrap: wrap);

        @include media(md-up) {
            align-items: center;
            flex-wrap: nowrap;
        }

        &_right {
            @include flex($horizontal: space-between, $vertical: flex-end);

            flex-basis: 100%;

            @include media(md-up) {
                align-items: flex-start;
                flex-basis: auto;
            }
        }

        .b-pdp_badges {
            margin-top: 16px;

            @include media(md-up) {
                margin-right: 20px;
                margin-top: 12px;
            }
        }
    }

    &-category {
        color: color('base-8');
        font-size: 12px;
        margin-bottom: 6px;

        @include media(md-up) {
            margin-top: 30px;
        }
    }

    &-supplier {
        color: $blue;
        flex-basis: 100%;
        font-size: 12px;

        @include media(md-up) {
            flex-basis: auto;
        }
    }
}
