// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component.
// -----------------------------------------------------------------------------

// Tabs
//
// Styleguide 8

// Tabs primary
//
// Markup:
// <div id="example-js-tabs-1">
//     <div class="tabs-nav js-tabs__nav">
//         <ul class="tabs-nav-list js-tabs__tabs-container">
//             <li class="tabs-nav-item js-tabs__tab active">tab nav item 1</li>
//             <li class="tabs-nav-item js-tabs__tab">tab nav item 2</li>
//             <li class="tabs-nav-item js-tabs__tab">tab nav item 3</li>
//         </ul>
//     </div>
//     <div class="tabs-content-container js-tabs__content-container">
//         <div class="tabs-content js-tabs__content active">content 1</div>
//         <div class="tabs-content js-tabs__content">content 2</div>
//         <div class="tabs-content js-tabs__content">cotnent 3</div>
//     </div>
// </div>
// <script>
//     const myTabs1 = new JsTabs({
//         elm: '#example-js-tabs-1'
//     })
//     myTabs1.init()
// </script>
//
// Styleguide 8.1

// Tabs primary (icons/radio)
//
// Markup:
// <div id="example-js-tabs-2">
//     <div class="tabs-nav js-tabs__nav">
//         <div class="tabs-nav-list js-tabs__tabs-container">
//             <label class="tabs-nav-item js-tabs__tab active" for="tab1">
//                 <span class="tabs-nav-icon m-icon-meetandgreet"></span>
//                  tab nav item 1
//                 <input class="d-none" id="tab1" type="radio" checked>
//             </label>
//             <label class="tabs-nav-item js-tabs__tab" for="tab2">
//                 <span class="tabs-nav-icon m-icon-lounges"></span>
//                 tab nav item 2
//                 <input class="d-none" id="tab2" type="radio">
//             </label>
//             <label class="tabs-nav-item js-tabs__tab" for="tab3">
//                 <span class="tabs-nav-icon m-icon-elite"></span>
//                 tab nav item 3
//                 <input class="d-none" id="tab3" type="radio">
//             </label>
//         </div>
//     </div>
//     <div class="tabs-content-container js-tabs__content-container">
//         <div class="tabs-content js-tabs__content active">content 1</div>
//         <div class="tabs-content js-tabs__content">content 2</div>
//         <div class="tabs-content js-tabs__content">cotnent 3</div>
//     </div>
// </div>
// <script>
//     const myTabs2 = new JsTabs({
//         elm: '#example-js-tabs-2'
//     })
//     myTabs2.init()
// </script>
//
// Styleguide 8.2

// Tabs secondary
//
// Markup:
// <div id="example-js-tabs-3">
//     <div class="tabs-secondary-nav js-tabs__nav">
//         <ul class="tabs-secondary-nav-list js-tabs__tabs-container">
//             <li class="tabs-secondary-nav-item js-tabs__tab active">tab nav item 1</li>
//             <li class="tabs-secondary-nav-item js-tabs__tab">tab nav item 2</li>
//             <li class="tabs-secondary-nav-item js-tabs__tab">tab nav item 3</li>
//         </ul>
//     </div>
//     <div class="tabs-content-container js-tabs__content-container">
//         <div class="tabs-content js-tabs__content active">content 1</div>
//         <div class="tabs-content js-tabs__content">content 2</div>
//         <div class="tabs-content js-tabs__content">cotnent 3</div>
//     </div>
// </div>
// <script>
//     const myTabs3 = new JsTabs({
//         elm: '#example-js-tabs-3'
//     })
//     myTabs3.init()
// </script>
//
// Styleguide 8.3

// Tabs secondary (with radio)
//
// Markup:
// <div id="example-js-tabs-4">
//     <div class="tabs-secondary-nav js-tabs__nav">
//         <div class="tabs-secondary-nav-list js-tabs__tabs-container">
//             <label class="tabs-secondary-nav-item js-tabs__tab active">
//                  tab nav item 1
//                 <input class="d-none" type="radio" checked>
//             </label>
//             <label class="tabs-secondary-nav-item js-tabs__tab">
//                  tab nav item 2
//                 <input class="d-none" type="radio">
//             </label>
//             <label class="tabs-secondary-nav-item js-tabs__tab">
//                  tab nav item 3
//                 <input class="d-none" type="radio">
//             </label>
//         </div>
//     </div>
//     <div class="tabs-content-container js-tabs__content-container">
//         <div class="tabs-content js-tabs__content active">content 1</div>
//         <div class="tabs-content js-tabs__content">content 2</div>
//         <div class="tabs-content js-tabs__content">cotnent 3</div>
//     </div>
// </div>
// <script>
//     const myTabs4 = new JsTabs({
//         elm: '#example-js-tabs-4'
//     })
//     myTabs4.init()
// </script>
//
// Styleguide 8.4

$tabs-nav-margin: 0 0 20px !default;
$tabs-nav-color: $black !default;
$tabs-nav-color-active: $black !default;
$tabs-nav-border-color: rgba($black, 0.3) !default;
$tabs-nav-font-size: 14px !default;
$tabs-nav-font-size-lg: 16px !default;
$tabs-nav-border-height: 3px !default;
$tabs-nav-border-height-active: 3px !default;
$tabs-nav-item-padding: 12px 5px (12px + $tabs-nav-border-height-active) !default;
$tabs-nav-icon-size: 36px !default;
$tabs-nav-icon-size-lg: 40px !default;
$tabs-nav-icon-margin: 0 auto 12px !default;

.tabs {
    &-nav {
        margin: $tabs-nav-margin;
        font-size: $tabs-nav-font-size;

        @include media-breakpoint-up(lg) {
            font-size: $tabs-nav-font-size-lg;
        }

        &-item {
            position: relative;
            padding: $tabs-nav-item-padding;
            color: $tabs-nav-color;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: $tabs-nav-border-height;
                background-color: $tabs-nav-border-color;
                pointer-events: none;
            }

            &.active {
                color: $tabs-nav-color-active;

                &::before {
                    height: $tabs-nav-border-height-active;
                    background-color: currentColor;
                }
            }
        }

        &-icon {
            position: relative;
            display: block;
            width: $tabs-nav-icon-size;
            height: $tabs-nav-icon-size;
            margin: $tabs-nav-icon-margin;
            background: center / contain no-repeat;

            @include media-breakpoint-up(lg) {
                width: $tabs-nav-icon-size-lg;
                height: $tabs-nav-icon-size-lg;
            }

            &.m-icon {
                &-meetandgreet {
                    @include tabs-nav-icon($icon: $icon-meet, $icon-active: $icon-meet-primary);
                }

                &-lounges {
                    @include tabs-nav-icon($icon: $icon-lounges, $icon-active: $icon-lounges-primary);
                }

                &-elite {
                    @include tabs-nav-icon($icon: $icon-dedicated, $icon-active: $icon-dedicated-primary);
                }
            }
        }
    }

    &-marker {
        height: 3px;
    }
}
