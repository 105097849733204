// Headings
@mixin heading($heading-font-size, $heading-font-size-lg: false, $heading-color, $letter-spacing: $heading-letter-spacing) {
    color: $heading-color;
    font-size: $heading-font-size;
    letter-spacing: $letter-spacing;

    @if ($heading-font-size-lg) {
        @include media-breakpoint-up(lg) {
            font-size: $heading-font-size-lg;
        }
    }
}
