// circle
$tabs-quaternary-nav-item-color-event: $black !default;
$tabs-quaternary-nav-item-circle-size: 22px !default;
$tabs-quaternary-nav-item-circle-margin: 0 10px 0 0 !default;
$tabs-quaternary-nav-item-circle-border-color: #afa18f !default;
$tabs-quaternary-nav-item-circle-border-color-event: $tabs-quaternary-nav-item-color-event !default;
$tabs-quaternary-nav-item-circle-border-color-unselectable: #e1e1e1 !default;
$tabs-quaternary-nav-item-circle-border-size: 2px !default;
$tabs-quaternary-nav-item-circle-border: $tabs-quaternary-nav-item-circle-border-size solid $tabs-quaternary-nav-item-circle-border-color !default;
$tabs-quaternary-nav-item-circle-border-size-selected: 4px !default;
$tabs-quaternary-nav-item-circle-border-selected: $tabs-quaternary-nav-item-circle-border-size-selected solid $tabs-quaternary-nav-item-circle-border-color-event !default;

.tabs-quaternary-nav {
    margin: 0 0 40px;

    &-item {
        display: block;
        margin: 0 0 20px;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            margin: 0 75px 0 0;
        }
    }

    &-circle {
        display: inline-block;
        width: $tabs-quaternary-nav-item-circle-size;
        height: $tabs-quaternary-nav-item-circle-size;
        border-radius: 50%;
        border: $tabs-quaternary-nav-item-circle-border;
        margin: $tabs-quaternary-nav-item-circle-margin;
        vertical-align: middle;
    }

    &-item.active &-circle {
        border: 6px solid color('primary-1');
    }

    &-link {
        margin-bottom: 0;
    }

    &-link:hover {
        text-decoration: none;
    }

    &-link-text {
        color: color('base-0');
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
    }
}
