$promotion-padding: 5px 0 3px !default;
$promotion-border-color: color('base-6') !default;
$promotion-content-primary-color: $black !default;
$promotion-dots-color: currentColor !default;
$promotion-dots-content: '\2022\2022\2022' !default;
$promotion-dots-letter-spacing: 0.24em !default;
$promotion-dots-indent: 0.12em !default;
$promotion-dots-secondary-color: currentColor !default;
$promotion-dots-padding: 0 2.6em !default;
$promotion-timecounter-value-font-size: 13px !default;
$promotion-timecounter-value-color: inherit !default;

.promotion {
    padding: $promotion-padding;
    border-bottom: 1px solid $promotion-border-color;
    text-align: center;

    &-content {
        &.m-primary {
            color: $promotion-content-primary-color;
        }
    }

    &-dots {
        @include media-breakpoint-up(md) {
            position: relative;
            display: inline-block;
            padding: $promotion-dots-padding;
            vertical-align: middle;

            &::before,
            &::after {
                @include block-alignment-secondary($horizontal: false, $vertical: true, $right: false, $left: false);

                color: $promotion-dots-color;
                letter-spacing: $promotion-dots-letter-spacing;
                line-height: 0;
                height: 0;
                content: $promotion-dots-content;
            }

            &::before {
                left: $promotion-dots-indent rtl-ignore();
            }

            &::after {
                right: -#{$promotion-dots-indent} rtl-ignore();
            }

            &.m-secondary {
                &::before,
                &::after {
                    color: $promotion-dots-secondary-color;
                }
            }
        }
    }

    &-timecounter {
        &-value {
            font-size: $promotion-timecounter-value-font-size;
            color: $promotion-timecounter-value-color;
        }
    }
}
