@mixin tabs-border-radius($border-radius: false) {
    @if ($border-radius) {
        &:first-child {
            border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-child {
            border-radius: 0 $border-radius $border-radius 0;
        }

        &:only-child {
            border-radius: $border-radius;
        }
    }
}

@mixin tabs-nav-icon($icon, $icon-active, $when-inside: '.tabs-nav-item.active') {
    background-image: url("#{svg($icon)}");

    @include when-inside($when-inside) {
        background-image: url("#{svg($icon-active)}");
    }
}
