// -----------------------------------------------------------------------------
// This file contains all styles related to the carousel global component.
// -----------------------------------------------------------------------------
$tns-nav-button-border: $global-border-color !default;
$tns-nav-secondary-button-bg: $black !default;
$tns-nav-secondary-button-after-bg: $black !default;

.tns-outer {
    position: relative;
}

.tns-inner {
    /* rtl:ignore */
    direction: ltr;

    .content-hero-layout {
        direction: ltr;
    }

}

.tns-nav {
    /* rtl:ignore */
    direction: ltr;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    z-index: z('default');

    @include media-breakpoint-up(md) {
        bottom: $tns-nav-bottom-md;
        width: $tns-nav-width-md;
        margin-right: $tns-nav-margin-right-md;

        @include when-inside('.m-without-search') {
            margin: 0 auto;
        }
    }

    @include media-breakpoint-up(lg) {
        bottom: 50px;
        margin-right: auto;
    }

    button {
        /* rtl:ignore */
        margin-right: 20px;
        height: 12px;
        width: 12px;
        border-radius: 100%;
        background: $white;
        border: 1px solid $tns-nav-button-border;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        outline: none;
        padding: 0;
        line-height: 1;

        &:nth-last-child(1) {
            margin: 0;
        }

        &::after {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: $tns-nav-button-after-background;
            opacity: 0;
        }

        &.tns-nav-active {
            &::after {
                opacity: 1;
            }
        }
    }
}

.tns-slider-wrapper.m-secondary {
    .tns-nav {
        width: 100%;
        pointer-events: none;

        button {
            width: 8px;
            height: 8px;
            margin-right: 10px;
            border-width: 0;
            background: $tns-nav-secondary-button-bg;
            pointer-events: auto;

            &:last-child {
                margin-right: 0;
            }

            &::after {
                width: inherit;
                height: inherit;
                background-color: $tns-nav-secondary-button-after-bg;
            }
        }
    }
}
