.header {
    border-top: 0;
    border-bottom: 0;
    background-image: url('../images/global-bg-aa-sm.svg');
    background-repeat: repeat-x;
    background-size: 375px 4px;
    padding-top: 4px;

    @include media(md-up) {
        background-image: url('../images/global-bg-aa-lg.svg');
        background-size: 1440px 4px;
    }

    &-in {
        display: block;
        padding: 0;

        @include media(md-down) {
            min-height: 60px;
        }
    }

    &-area {
        @include flex($horizontal: space-between, $vertical: center);

        @include media(md-down) {
            column-gap: 10px;
        }

        .m-sticky & {
            column-gap: 40px;

            @include media(md-down) {
                column-gap: 10px;
            }
        }
    }

    &-top {
        @include media(lg-up) {
            border-bottom: 1px solid  $border-color;
            padding: 14px 0;
        }

        &-actions {
            @include flex($horizontal: space-between, $vertical: center);

            font-size: 12px;
            line-height: 16px;
            column-gap: 32px;
        }
    }

    &-support {
        .content-asset {
            a {
                @include flex($vertical: center);

                color: $dark;

                &::before {
                    @include inline-svg-bg($icon-chat);

                    content: "";
                    margin-right: 6px;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        &.m-mobile {
            text-indent: -5000px;

            a {
                &::before {
                    margin: 6px;
                    width: 20px;
                    height: 20px;
                }
            }

            .header:not(.m-sticky) & {
                @include media(md-up) {
                    display: none;
                }
            }
        }
    }

    &-logo {
        @include media(md-down) {
            display: none;
        }

        .m-sticky & {
            display: none;
        }

        &.header-bottom-logo {
            @include media(md-down) {
                flex-shrink: 0;
                width: 160px;
                height: 30px;
            }

            .content-asset {
                &:first-child {
                    @include media(md-down) {
                        display: none;
                    }
                }

                &:last-child {
                    @include media(md-up) {
                        display: none;
                    }
                }
            }
        }
    }

    &-action {
        &-icons {
            @include flex($vertical: center);

            column-gap: 20px;
            padding-right: 0;

            @include media(lg-up) {
                min-width: initial;
            }

            @include media(md-down) {
                column-gap: 10px;
            }

            .header-icon {
                color: $dark;
                display: block;
                margin: 0;
                width: 32px;

                @include media(lg-up) {
                    @include flex($vertical: center);

                    margin: 0;
                    width: auto;
                }

                &-user {
                    @include media(lg-up) {
                        display: flex !important;
                        align-items: center;
                    }

                    &::before {
                        @include media(lg-up) {
                            @include inline-svg($icon-account-menu);
                        }
                    }
                }

                &-wishlist {
                    @include media(sm) {
                        display: none;
                    }

                    &::before {
                        width: 32px;
                        height: 32px;

                        @include media(lg-up) {
                            @include inline-svg($icon-wishlist-menu);
                        }
                    }
                }

                &-minicart {
                    &::before {
                        width: 32px;
                        height: 32px;
                    }
                }

                &-menu {
                    width: 22px;

                    @include media(md-down) {
                        margin: 0 5px;
                    }

                    &::before {
                        @include inline-svg($icon-menu);
                    }
                }
            }
        }
    }

    &-bottom {
        padding: 14px 0;
        border-bottom: 1px solid  $border-color;

        @include media(md-up) {
            padding: 18px 0;
        }

        &-logo {
            display: none;

            @include media (md-down) {
                display: block;
            }

            .m-sticky & {
                display: block;
            }
        }

        &-search {
            @include inline-svg($icon-search);

            @include plain-hover-focus {
                appearance: none;
                border: none;
                cursor: pointer;
                padding: 0;
                outline: none;
            }

            .stickyheader-init &,
            .theme-aa:not(.page-init) & {
                @include media(lg-up) {
                    display: none;
                }
            }

            .stickyheader-init.m-sticky & {
                display: block;
            }
        }
    }

    &-search {
        position: relative;

        @include media(md-down) {
            position: absolute;
            visibility: hidden;

            &.m-open {
                visibility: visible;
            }
        }

        .search {
            &-field {
                min-height: initial;
                padding: 0;
            }
        }

        &:not(.m-open) {
            .suggestions-wrapper {
                border-color: $white;
            }
        }
    }

    &-banner {
        background-color: $blue;
        margin-bottom: 5px;

        &-promotion {
            font-size: 12px;
            height: auto;
        }

        .html-slot-container {
            padding: 10px 25px;
        }
    }

    &.m-sticky {
        .header-action-icons .header-icon-wishlist {
            display: block;

            @include media(md-down) {
                display: none;
            }
        }

        .header-top {
            border: 0;
            padding: 0;
        }

        .header-bottom-search {
            width: 32px;
        }

        .navigation-menu-link {
            @include media(lg-up) {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                padding: 4px 10px;
            }
        }
    }

    &:not(.m-sticky) {
        .header {
            &-icon {
                .visually-hidden {
                    @include media(lgm-up) {
                        position: relative;
                        overflow: initial;
                        margin: 0;
                        height: initial;
                        width: initial;
                    }
                }
            }
        }
    }

    .navigation {
        .m-sticky & {
            margin-right: auto;
            justify-content: flex-end;
        }

        &-menu-item-content {
            border: none;
        }
    }

    .timer {
        color: $blue;
        font-size: 12px;
        margin-left: 4px;
    }

    &-wishlist-quantity,
    .minicart-quantity {
        background: $red;
        font-size: 10px;
        line-height: 1;
        left: 10px;
        top: 7px;
        height: 15px;
        width: 15px;
    }

    .utility-navigation-item {
        &.m-find {
            a {
                color: $dark;
            }
        }
    }
}
