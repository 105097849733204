.carousel-content-asset {
    margin-bottom: 50px;

    &-item {
        display: none;
        width: 100%;

        &:first-child {
            display: inline-block;
        }

        .theme-css & {
            .content-hero:empty {
                height: 400px;;
            }
        }

        .theme-aa & {
            .row {
                // max-height: 48px;
                h3 {
                    font-size: 24px;
                }
            }

            .content-hero-layout {
                .row {
                    max-height: none;
                }
            }
        }
    }

    .tns-carousel {
        direction: ltr;
        display: block !important;
    }
}
