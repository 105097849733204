$tracking-consent-background-color: #eeaf00 !default;

.tracking-consent {
    background-color: $tracking-consent-background-color;
    bottom: 0;
    box-shadow: 0 -6px 12px 0 rgba(0, 0, 0, 0.15);
    color: $white;
    left: 0;
    padding: 16px 0 14px;
    position: fixed;
    right: 0;
    text-align: justify;
    z-index: z('default');

    &-content {
        align-items: center;
        display: flex;
        flex-flow: column;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex-flow: row;
        }
    }

    &-button {
        flex-shrink: 0;
        margin-top: 14px;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-left: 12px;
            margin-top: 0;
            width: 170px;
        }
    }

    p {
        margin-bottom: 0;
    }
}
