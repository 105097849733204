.hero.slant-down {
    background: url('../images/top-banner-bg.jpg') center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    height: 120px;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    &.m-extended {
        flex-direction: column;
        justify-content: center;
        text-align: left;

        .page-title {
            font-size: 24px;
            font-weight: $font-weight-normal;
            margin-bottom: 12px;

            @include media-breakpoint-up(md) {
                font-size: 30px;
            }
        }
    }

    @include when-inside('.theme-aa') {
        background-image: url('../images/top-banner-bg-aa.jpg');
    }

    @include when-inside('.theme-css') {
        background-image: url('../images/top-banner-bg-css.jpg');
    }

    .page-title { // stylelint-disable-line no-descending-specificity
        @include make-container;
        @include make-container-max-widths;

        color: color('base-0');
        font: $font-weight-medium 38px/1 $font-primary;
        letter-spacing: $body-letter-spacing;
        margin-bottom: 0;
    }

    .page-description {
        @include make-container;
        @include make-container-max-widths;

        color: color('base-0');
        font-family: $font-primary;
        font-size: 16px;
        margin-bottom: 0;
    }
}
