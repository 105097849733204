// common
$tier-tiles-carousel-color-event: $black !default;
$tier-tiles-carousel-color-unselectable: $black !default;
$tier-tiles-carousel-bg-color-unselectable: #fafafa !default;
$tier-tiles-carousel-line-height-default: $line-height-base !default;
// wrapper
$tier-tiles-carousel-wrapper-margin: 0 0 20px !default;
$tier-tiles-carousel-wrapper-margin-md: 0 0 35px !default;
// carousel
$tier-tiles-carousel-max-width: 100%;
$tier-tiles-carousel-slide-font-size: 14px !default;
$tier-tiles-carousel-loader-color: $black !default;
// item
$tier-tiles-carousel-item-padding: 15px 10px 10px !default;
$tier-tiles-carousel-item-border-color: color('base-6') !default;
$tier-tiles-carousel-item-border-color-selected: $tier-tiles-carousel-color-event !default;
$tier-tiles-carousel-item-border-color-unselectable: transparent !default;
$tier-tiles-carousel-item-color: $black !default;
$tier-tiles-carousel-item-bg-color: $white !default;
$tier-tiles-carousel-item-bg-color-unselectable: $tier-tiles-carousel-bg-color-unselectable !default;
$tier-tiles-carousel-item-border-radius: 10px !default;
// circle
$tier-tiles-carousel-circle-size: 16px !default;
$tier-tiles-carousel-circle-margin: 0 auto 10px !default;
$tier-tiles-carousel-circle-border-color: $black !default;
$tier-tiles-carousel-circle-border-color-event: $tier-tiles-carousel-color-event !default;
$tier-tiles-carousel-circle-border-color-unselectable: #e1e1e1 !default;
$tier-tiles-carousel-circle-border-size: 2px !default;
$tier-tiles-carousel-circle-border: $tier-tiles-carousel-circle-border-size solid $tier-tiles-carousel-circle-border-color !default;
$tier-tiles-carousel-circle-border-size-selected: 4px !default;
$tier-tiles-carousel-circle-border-selected: $tier-tiles-carousel-circle-border-size-selected solid $tier-tiles-carousel-circle-border-color-event !default;
// value
$tier-tiles-carousel-value-margin: 0 0 10px !default;
$tier-tiles-carousel-value-font-size: 22px !default;
$tier-tiles-carousel-value-line-height: 1.1 !default;
$tier-tiles-carousel-value-font: #{$tier-tiles-carousel-value-font-size} / #{$tier-tiles-carousel-value-line-height} $font-primary !default;
$tier-tiles-carousel-value-color: $black !default;
$tier-tiles-carousel-value-color-unselectable: inherit !default;
// price
$tier-tiles-carousel-price-color: $black !default;
$tier-tiles-carousel-price-color-unselectable: inherit !default;
$tier-tiles-carousel-price-font-size: 12px !default;
$tier-tiles-carousel-currency-color: $black !default;
$tier-tiles-carousel-currency-color-unselectable: inherit !default;
$tier-tiles-carousel-currency-font-size: 17px !default;
$tier-tiles-carousel-currency-font-weight: $font-weight-medium !default;
$tier-tiles-carousel-currency-font: $tier-tiles-carousel-currency-font-weight #{$tier-tiles-carousel-currency-font-size} / #{$tier-tiles-carousel-line-height-default} $font-primary !default;
$tier-tiles-carousel-currency-through-color: $black !default;
$tier-tiles-carousel-currency-through-color-unselectable: inherit !default;
$tier-tiles-carousel-currency-through-font-size: 13px !default;
$tier-tiles-carousel-price-min-height: ceil(($tier-tiles-carousel-price-font-size + $tier-tiles-carousel-currency-font-size + $tier-tiles-carousel-currency-through-font-size) * $tier-tiles-carousel-line-height-default) !default;
// description
$tier-tiles-carousel-description-margin: 0 0 5px !default;
$tier-tiles-carousel-description-color: $black !default;
$tier-tiles-carousel-description-color-unselectable: inherit !default;
$tier-tiles-carousel-description-line-height: 1.3 !default;
$tier-tiles-carousel-description-font-size: 13px !default;
$tier-tiles-carousel-description-font: #{$tier-tiles-carousel-description-font-size} / #{$tier-tiles-carousel-description-line-height} $font-primary !default;

.tier-tiles-carousel {
    @include loader($color: $tier-tiles-carousel-loader-color, $is-nested-class: true);

    margin: 0 auto;
    max-width: $tier-tiles-carousel-max-width;


    &-wrapper {
        margin: $tier-tiles-carousel-wrapper-margin;
        position: relative;
        user-select: none;

        @include media-breakpoint-up(md) {
            margin: $tier-tiles-carousel-wrapper-margin-md;
        }

        @include tiny-carousel-controls($arrow-icon-size: 24px, $arrow-icon-gutters: 0) {
            &[data-controls="prev"] {
                left: calc((100% - #{$tier-tiles-carousel-max-width}) * 0.5) rtl-ignore();

                @include media-breakpoint-up(md) {
                    left: 0 rtl-ignore();
                }

                @include media-breakpoint-only(lg) {
                    left: -5px rtl-ignore();
                }

                &[disabled] ~ .tier-tiles-carousel .tns-inner::before {
                    opacity: 0;
                }
            }

            &[data-controls="next"] {
                right: calc((100% - #{$tier-tiles-carousel-max-width}) * 0.5) rtl-ignore();

                @include media-breakpoint-up(md) {
                    right: 0 rtl-ignore();
                }

                @include media-breakpoint-only(lg) {
                    right: -5px rtl-ignore();
                }

                &[style*="display: none"] ~ .carousel-init {
                    margin: 0;
                    padding-right: 0;
                }

                &:not([style*="display: none"]) ~ .carousel-init {
                    padding: 0 35px;

                    @include media-breakpoint-only(lg) {
                        padding: 0 25px;
                    }

                    .tns-inner {
                        &::before,
                        &::after {
                            content: '';
                            position: absolute;
                            top: 20%;
                            bottom: 20%;
                            border-radius: 50%;
                            box-shadow: 0 0 18px 6px rgba(0, 0, 0, 0.2);
                            width: 0;
                            z-index: z('default');
                        }

                        &::before {
                            left: 0;
                        }

                        &::after {
                            right: 0;
                        }
                    }
                }

                &[style*="display: none"] ~ .tier-tiles-carousel .tns-inner {
                    direction: ltr;
                }

                &[disabled] ~ .tier-tiles-carousel .tns-inner::after {
                    opacity: 0;
                }
            }

            &[data-controls="prev"],
            &[data-controls="next"] {
                &:not([aria-controls]) {
                    display: none;
                }
            }
        }
    }

    &-container {
        direction: ltr;
        font-size: 0;
        overflow: hidden;
        padding-bottom: 1px;
        vertical-align: top;
        white-space: nowrap;
    }

    &-slide {
        padding: 0 0 1px;
        display: inline-block;
        font-size: $tier-tiles-carousel-slide-font-size;
    }

    &-circle {
        display: block;
        width: $tier-tiles-carousel-circle-size;
        height: $tier-tiles-carousel-circle-size;
        border-radius: 50%;
        border: $tier-tiles-carousel-circle-border;
        margin: $tier-tiles-carousel-circle-margin;
    }

    &-value {
        @include limit-text-lines($line-height: $tier-tiles-carousel-value-line-height, $font-size: $tier-tiles-carousel-value-font-size, $max-lines: 2);

        color: $tier-tiles-carousel-value-color;
        display: block;
        font: $tier-tiles-carousel-value-font;
        line-height: $tier-tiles-carousel-value-line-height;
        margin: $tier-tiles-carousel-value-margin;
        text-align: center;
        word-break: break-word;
    }

    &-price {
        display: block;
        font-size: $tier-tiles-carousel-price-font-size;
        color: $tier-tiles-carousel-price-color;
        min-height: $tier-tiles-carousel-price-min-height;
    }

    &-currency {
        display: block;
        font: $tier-tiles-carousel-currency-font;
        color: $tier-tiles-carousel-currency-color;
    }

    &-currency-through {
        display: block;
        font-size: $tier-tiles-carousel-currency-through-font-size;
        color: $tier-tiles-carousel-currency-through-color;
        text-decoration: line-through;
    }

    &-description {
        @include limit-text-lines($line-height: $tier-tiles-carousel-description-line-height, $font-size: $tier-tiles-carousel-description-font-size, $max-lines: 3);

        display: block;
        margin: $tier-tiles-carousel-description-margin;
        font: $tier-tiles-carousel-description-font;
        color: $tier-tiles-carousel-description-color;
        text-align: center;
    }

    &-item {
        display: block;
        padding: $tier-tiles-carousel-item-padding;
        border: 2px solid $tier-tiles-carousel-item-border-color;
        color: $tier-tiles-carousel-item-color;
        background-color: $tier-tiles-carousel-item-bg-color;
        border-radius: $tier-tiles-carousel-item-border-radius;

        @include hover {
            text-decoration: none;
            color: $tier-tiles-carousel-item-color;

            .tier-tiles-carousel-circle {
                border-color: $tier-tiles-carousel-circle-border-color-event;
            }
        }

        &.selected {
            border-color: $tier-tiles-carousel-item-border-color-selected;

            .tier-tiles-carousel-circle {
                border: $tier-tiles-carousel-circle-border-selected;
            }
        }

        &.unselectable {
            color: $tier-tiles-carousel-color-unselectable;
            background-color: $tier-tiles-carousel-item-bg-color-unselectable;
            border-color: $tier-tiles-carousel-item-border-color-unselectable;
            pointer-events: none;

            .tier-tiles-carousel-circle {
                border-color: $tier-tiles-carousel-circle-border-color-unselectable;
            }

            .tier-tiles-carousel-value {
                color: $tier-tiles-carousel-value-color-unselectable;
            }

            .tier-tiles-carousel-price {
                color: $tier-tiles-carousel-price-color-unselectable;
            }

            .tier-tiles-carousel-currency {
                color: $tier-tiles-carousel-currency-color-unselectable;
            }

            .tier-tiles-carousel-currency-through {
                color: $tier-tiles-carousel-currency-through-color-unselectable;
            }

            .tier-tiles-carousel-description {
                color: $tier-tiles-carousel-description-color-unselectable;
            }
        }
    }

    .tns-outer {
        overflow: hidden;
    }
}
