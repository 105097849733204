// -----------------------------------------------------------------------------
// This file contains styles related to the modal component.
// -----------------------------------------------------------------------------

// Modal
//
// Styleguide 7


// Modal
//
// Markup:
// <div class="modal fade" style="display: block !important; position: relative; opacity: 1;" tabindex="-1" role="dialog" aria-label="test-modal">
//     <div class="modal-dialog" role="document">
//         <div class="modal-content">
//             <div class="modal-header">
//                 <h4 class="modal-title">
//                     Forgot your Password
//                 </h4>
//                 <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
//             </div>
//             <div class="modal-body">
//                 <p>Please provide your account address to receive an email to reset your password</p>
//                 <form>
//                     <div class="form-group required">
//                         <label class="form-control-label" for="test-input">Email</label>
//                         <input type="email" class="form-control" id="test-input" placeholder="Enter your Email" required />
//                         <div class="invalid-feedback">Some hint message</div>
//                     </div>
//                 </form>
//             </div>
//             <div class="modal-footer">
//                 <button type="button" class="btn btn-block btn-primary"
//                         data-dismiss="modal"
//                         data-action="send action">
//                     Send
//                 </button>
//             </div>
//         </div>
//     </div>
// </div>
//
// Styleguide 7.1

$modal-backdrop: rgba(color('base-0'), 0.3) !default;
$modal-button-min-width: 100px !default;
$modal-button-width: auto !default;
$modal-content-border-radius: $border-radius !default;
$modal-separator-color: color('base-6') !default;
$modal-separator-indented-margin: 10px 0 15px !default;
$modal-title-color: inherit !default;
$modal-title-font-size: 24px !default;
$modal-title-font-size-md: 28px !default;
$modal-title-font-weight: $font-weight-medium !default;
$modal-title-line-height: 1.2 !default;
$modal-title-font: $modal-title-font-weight #{$modal-title-font-size}/#{$modal-title-line-height} $font-primary !default;
$modal-add-to-cart-transition: transform 0.2s ease !default;
$modal-add-to-cart-message-font-size: 18px !default;
$modal-add-to-cart-message-color-success: $green !default;
$modal-add-to-cart-message-color-danger: $red !default;
$modal-padding: 30px !default;
$modal-header-padding-bottom: 20px !default;
$modal-body-padding: 20px 0 0;

// alert
$modal-alert-bg-color: rgba(color('base-0'), 0.6) !default;
$modal-alert-content-padding: 20px 25px !default;
$modal-alert-content-font-size: 18px !default;
$modal-alert-content-bg-color: $white !default;
$modal-alert-content-border-radius: $border-radius !default;

.modal {
    &-dialog {
        margin: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 470px;
            margin: 0 auto;
        }

        &.m-large {
            @include media-breakpoint-up(md) {
                max-width: 708px;
            }
        }
    }

    &-content {
        padding: $modal-padding;
        border: 0;
        border-radius: $modal-content-border-radius;
        box-shadow: none;
    }

    &-header {
        padding: 0 20px $modal-header-padding-bottom 0;
        border-bottom: 1px solid $modal-separator-color;
    }

    &-title {
        color: $modal-title-color;
        font: $modal-title-font;

        @include media-breakpoint-up(md) {
            font-size: $modal-title-font-size-md;
        }
    }

    &-body {
        padding: $modal-body-padding;
    }

    &-footer {
        border-top: 1px solid $modal-separator-color;
        margin-top: 10px;
        padding: 25px 0 0;

        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
        }

        @include media-breakpoint-up(md) {
            position: relative;
            justify-content: center;
        }

        > .btn-cancel {
            margin: 0;

            @include media-breakpoint-down(sm) {
                margin-top: 20px;
            }

            @include media-breakpoint-up(md) {
                position: absolute;
                left: 0;
                bottom: 20px;
                transform: translateY(50%);
            }
        }

        > .btn-primary {
            margin-left: 0;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &.m-mobile-row {
            align-items: baseline;
            flex-direction: row;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                justify-content: center;
            }

            .btn-primary {
                min-width: 200px;
                width: auto;
            }
        }
    }

    &-separator {
        border-top: 1px solid $modal-separator-color;
        display: block;
        margin: 0;

        &.m-indented {
            margin: $modal-separator-indented-margin;
        }
    }

    &-buttons-wrapper {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    &-button {
        margin-right: 40px;
        min-width: $modal-button-min-width;
        width: $modal-button-width;

        &:last-child {
            margin-right: 0;
        }
    }

    &-background,
    &-backdrop {
        &.fade {
            transition: none;
        }

        &.show,
        &.in {
            background-color: $modal-backdrop;
            opacity: 1;
        }
    }

    &-add-to-cart {
        @include transition($modal-add-to-cart-transition);

        align-items: center;
        background-color: $modal-backdrop;
        bottom: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        z-index: z('above');

        &.m-alert-success {
            color: $modal-add-to-cart-message-color-success;
        }

        &.m-alert-danger {
            color: $modal-add-to-cart-message-color-danger;
        }

        &-message {
            background-color: $white;
            border-radius: $border-radius;
            display: inline-block;
            font-size: $modal-add-to-cart-message-font-size;
            padding: 20px 30px;
        }
    }

    &-alert {
        @include fade-in($modifier-class: 'm-active');

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $modal-alert-bg-color;
        z-index: z('above');
        display: flex;
        align-items: center;
        justify-content: center;

        &-content {
            padding: $modal-alert-content-padding;
            font-size: $modal-alert-content-font-size;
            background-color: $modal-alert-content-bg-color;
            border-radius: $modal-alert-content-border-radius;
            text-align: center;
        }
    }

    &.fade {
        transition: none;

        .modal-dialog {
            transform: translate(0, 0);
        }
    }

    &.show,
    &.in {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .close {
        width: 16px;
        position: absolute;
        top: 30px;
        right: 30px;
        height: 16px;
        padding: 0;
        margin: 0;

        &::after {
            @include inline-svg-bg($icon-close);

            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        span {
            display: none;
        }
    }

    &-chauffeur {
        display: flex;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        &-col {
            width: 50%;
            margin-top: -62px;

            &-departure {
                margin-top: -100px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-top: 0;
            }

            .js-phone {
                width: 70%;
            }
        }
    }
}
