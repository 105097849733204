$content-banner-location-color: $brown;
$content-banner-location-color-md: $white;
$content-banner-location-padding: 20px 0 10px;
$content-banner-location-padding-md: 40px 0;

// Title and description
$content-banner-title-color-md: $h1-color;
$content-banner-description-color-md: color('base-4');

// Image container
$image-container-img-fixed-height-md: 350px;
$image-container-img-fixed-height-lg: auto;

// Cart promo banner
$cart-banner-color: $white;
