@mixin form-reset() {
    appearance: none;
    box-shadow: none;
    display: inline-block;
    outline: none;
    resize: none;
    width: 100%;

    @content;
}
