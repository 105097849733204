$footer-bg-color: color('base-1');
$footer-text-color: color('base-4');
$footer-border-color: color('base-2');
// title
$footer-title-font-size: 16px;
$footer-title-font-size-lg: 14px;
$footer-title-font-weight: $font-weight-normal;
$footer-title-font: $footer-title-font-weight $footer-title-font-size $font-primary;
$footer-title-color: inherit;
// logo
$footer-logo-max-width: 119px;
$footer-logo-max-width-xl: 145px;
$footer-logo-margin: 30px;
$footer-logo-margin-xl: 30px;
// divider
$footer-color-divider: color('base-2');

$footer-accordion-border-color: color('base-2');

// content
$footer-content-color: color('base-3');

// support
$footer-support-title-color: inherit;
$footer-support-title-font-weight: $font-weight-normal;
$footer-support-text-small-font-weight: $font-weight-medium;
// awards
$footer-awards-item-max-width: 74px;
$footer-awards-item-max-width-lg: calc(50% - #{$grid-gutter-width});
//nav
$footer-nav-title-font-weight: $font-weight-normal;
//switcher
$switcher-border-top-color: color('base-2');
