.l-grid {
    @include when-inside('.m-page_designer') {
        &_layout {
            border-radius: $border-radius;

            &.m-brand-strip {
                position: relative;
                margin-bottom: 15px;

                &::after {
                    background-image: url("../images/block-bg-aa-sm.jpg");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    border-radius: 0 0 $border-radius $border-radius;
                    content: "";
                    height: 10px;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: calc(100% + 5px);

                    @include media(md-up) {
                        background-image: url("../images/block-bg-aa-lg.jpg");
                    }
                }


                &.m-strip-bottom {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &.m-strip-top {
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    margin-top: 15px;
                    margin-bottom: 0;


                    &::after {
                        border-radius: $border-radius $border-radius 0 0;
                        top: -15px;
                    }
                }
            }

            .m-link_small {
                color: $white;
            }
        }
    }
}
