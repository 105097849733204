$header-utils-link-font-size: 16px !default;
$header-utils-link-color: color('base-0') !default;
$header-utils-link-color-lg: color('base-0') !default;
$header-utils-divider-color: color('base-1') !default;
$header-utils-phone-bg-color: color('base-1') !default;

.utility-navigation {
    display: none;

    @include media(lg-up) {
        @include flex($horizontal: space-between);

        margin-bottom: 30px;
    }

    @include when-inside('.m-sticky') {
        border-left: 1px solid $header-utils-divider-color;
        border-right: 1px solid $header-utils-divider-color;
        margin-bottom: 0;
        padding: 5px 10px;

        @include media-breakpoint-up(xl) {
            padding: 5px 50px;
        }
    }

    &-item {
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;

        @include when-inside('.m-sticky') {
            margin-left: 10px;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 45px;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &-phonenumber {
        background-color: $header-utils-phone-bg-color;
        padding: 10px 0;
        text-align: center;
    }

    &-mobile {
        padding: 14px 0;
        position: relative;

        @include when-inside('.submenu-opened') {
            position: static;
        }

        &::before {
            @include text-alignment(true, false);

            content: '';
            top: 0;
            height: 1px;
            width: calc(100% - 44px);
            background-color: $header-utils-divider-color;

            @include media(md-up) {
                width: calc(100% - 66px);
            }
        }

        &-link,
        &-item a {
            @include navigation-link($header-utils-link-color-lg);

            display: flex;
            font-size: $header-utils-link-font-size;

            > .link-icon {
                color: $header-utils-link-color;
            }
        }

        &-link-description {
            margin-left: 4px;
        }

        &-account {
            padding-top: 14px;
            margin-top: 14px;
            position: relative;

            @include when-inside('.submenu-opened') {
                position: static;
            }

            &::before {
                @include text-alignment(true, false);

                content: '';
                top: 0;
                height: 1px;
                width: calc(100% - 44px);
                background-color: $header-utils-divider-color;

                @include media(md-up) {
                    width: calc(100% - 66px);
                }
            }
        }
    }

    a:not(.dropdown-item),
    .dropdown {
        color: $header-utils-link-color-lg;
    }
}
