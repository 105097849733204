.b-applied_filters {
    border-top: 1px solid $border-color;
    margin-bottom: 32px;
    padding-top: 16px;

    &-items {
        display: inline-flex;
        flex-wrap: wrap;
        column-gap: 8px;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        row-gap: 8px;
    }

    &-item {
        @include flex($vertical: center);

        font-size: 14px;
        line-height: 20px;
        background-color: $service-background;
        border-radius: 4px;
        padding: 2px 10px;
        color: $dark;
        column-gap: 4px;
    }

    &-name {
        color: $grey-8;
    }

    &-remove_button {
        @include inline-svg-bg($icon-close-menu);

        content: '';
        display: inline-block;
        border: none;
        margin-left: 4px;
        height: 18px;
        width: 18px;

        svg {
            display: none;
        }

        &:focus {
            border: none;
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-clear_all {
        background: initial;
        border: none;
        text-decoration: underline;

        &:focus {
            border: none;
            outline: none;
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}
