.b-pdp_badges {
    .product-tile {
        &-badge {
            border-radius: $border-radius-badge;
            border: 1px solid $blue;
            color: $blue;
            column-gap: 6px;
            display: flex;
            font-family: $font-emirates;
            font-size: 12px;
            font-weight: bold;
            height: 24px;
            padding: 4px 8px;

            &-wrapper {
                @include flex($wrap: wrap);

                column-gap: 8px;
                margin-top: auto;
                row-gap: 8px;
            }

            &.bestseller {
                background: $green;
                border-color: $green;
                color: $white;
            }
        }
    }
}
