$no-image-background-color: color('base-6') !default;

.no-image {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("#{svg($icon-no-image)}") $no-image-background-color no-repeat center / contain;
    }
}
