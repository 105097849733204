.b-banner {
    .pdx-content-asset {
        @include flex($vertical: center);

        height: 100%;
    }

    .experience-bannerExtContentAsset {
        grid-column: grid-start/grid-end;
        grid-row: 1/2;
    }

    .b-text_block,
    .b-banner_content {
        &.m-extra_padding {
            padding: 32px;
        }

        h3 + h3 {
            margin-top: -20px;
        }

        .b-actions {
            margin-top: 20px;
        }

        .b-actions-container {
            &.m-horizontal_left {
                .btn {
                    &.m-link_small {
                        width: auto;
                    }
                }
            }
        }
    }

    .b-banner {
        &-caption {
            @include when-inside('.l-grid_layout.m-centered') {
                padding-block: 40px;

                &.m-vertical_bottom {
                    padding-bottom: 30px;

                    h3 {
                        margin-bottom: 16px;
                    }

                    .b-actions {
                        margin-top: 16px;
                    }
                }
            }
        }

        &-inner {
            height: 100%;
        }

        &-picture {
            background: none;
            display: block;

            img {
                width: 100%;
            }
        }
    }

    &.m-rounded,
    &.m-rounded img {
        border-radius: 16px;
    }

    &.m-rounded-left,
    &.m-rounded-left img {
        @include media(md-up) {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }

        @include media(md-down) {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
    }

    &.m-rounded-right,
    &.m-rounded-right img {
        @include media(md-up) {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        @include media(md-down) {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
}
