$this: ".tracking-consent";

.tracking-consent {
    &-button {
        &:hover {
            color: $blue;
            background: $white;
            border-color: $white;
        }
    }

    &.m-pdp-sticky-bar {
        background: $white;
        color: $aa-black;
        z-index: 1000;

        #{$this}-inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto 1fr;
            gap: 8px;
            width: 100%;

            @include media(md-up) {
                @include flex($horizontal: flex-end);
            }
        }

        .btn {
            padding: 10px 6px;

            &.m-edit {
                min-width: 92px;
            }
        }

        .sticky_bar-passengers {
            @include flex($vertical: center);

            grid-column: 1 / 2;
            grid-row: 1 / 2;
            gap: 20px;

            &-item {
                white-space: nowrap;
            }
        }

        .b-pdp_cancel-message {
            @include flex($vertical: center);

            font-size: 12px;
            max-width: 200px;
            gap: 8px;
            margin: 0 auto;

            @include media(md-down) {
                display: none;
            }

            &::before {
                @include inline-svg($icon-refund);

                content: '';
                display: block;
                min-width: 24px;
            }

            &-wrap {
                align-self: center;
                flex-basis: 50%;
            }
        }

        .b-pdp_promo-prices {
            @include flex($vertical: flex-end, $horizontal: flex-end, $wrap: wrap);

            grid-column: 2 / 3;
            grid-row: 1 / 2;
            gap: 6px;
            align-self: flex-end;

            @include media(md-up) {
                max-width: 25%;
            }

            @include media(md-down) {
                align-self: center;
            }

            &_title {
                display: block !important;
                line-height: 1;
                font-weight: 700;
            }

            &-notification {
                flex-basis: 100%;
                text-align: right;
            }

            .price-value {
                font-size: 16px;
                color: $dark;
                padding: 0;
                font-weight: 700;
            }
        }

        .pdp-product-addtocart-button {
            grid-column: 1 / 2;
            grid-row: 2 / 3;

            .btn-primary {
                background: transparent;
                border: 1px solid $black;
                min-height: 44px;
                width: 165px;

                @include hover-focus-active {
                    background: $yellow;
                    border-color: $yellow;
                    color: $white;
                }
            }

            .btn-extend-text {
                font-size: 16px;
                text-transform: none;
            }
        }

        .btn-options {
            grid-column: 2 / 2;
            grid-row: 2 / 3;
            justify-self: end;

            .btn-primary {
                width: 165px;
            }
        }

        .sticky-pdp,
        .btn-extend-price {
            display: none;
        }
    }
}
