@include media-breakpoint-up(lgm) {
   .l-pdp {
       .l-pdp-bottom-wrapper {
           width: 100%;
           padding-right: 12px;
           margin-right: auto;
           margin-left: auto;
           max-width: 1464px;
            > div {
               max-width: calc(100% - 375px);
            }
        }
    }
}
