// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

// Clear inner floats
.clearfix {
    @include clearfix;
}

// Hide element while making it readable for screen readers
.visually-hidden {
    @include visually-hidden;
}

// Hide element
.hidden {
    display: none !important;
}

// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
// See: https://code.google.com/p/chromium/issues/detail?id=457146
.hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

// Hide bullets from <ul> and <ol> lists
.no-bullet {
    list-style: none;
    padding: 0;
    margin: 0;
}

// ltr class for the fields that always should have ltr direction (phone, email)
.ltr {
    direction: ltr rtl-ignore();
}

.rtl-english {
    @include rtl-english-fix;
}

.position-md-absolute {
    @include media-breakpoint-only(md) {
        position: absolute !important;
    }
}

.position-lg-absolute {
    @include media-breakpoint-only(lg) {
        position: absolute !important;
    }
}

.position-xl-absolute {
    @include media-breakpoint-only(xl) {
        position: absolute !important;
    }
}

.pull-right {
    @extend .float-right !optional;  // stylelint-disable-line
}

.pull-left {
    @extend .float-left !optional; // stylelint-disable-line
}

// Removing horizontal paddings in the specific viewport size or from extrasmall to provided viewport size
// Examples: no-padding-md, no-padding-to-lg
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
        .no-padding-to#{$infix} {
            padding-right: 0;
            padding-left: 0;
        }

        .no-padding-#{$breakpoint} {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
