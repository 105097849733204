// -----------------------------------------------------------------------------
// This file contains all common application-wide Sass variables.
// -----------------------------------------------------------------------------

// Custom Fonts
$font-primary: 'DINNextLTPro', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-secondary: 'Mallanna', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$font-emirates: 'Emirates', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Body
$font-family-base: $font-primary;
// $font-size-base: 16px;
// $font-size-sm: ($font-size-base * .875);
// $font-size-lg: ($font-size-base * 1.25);
$line-height-base: 1.4;
$body-font-size: 14px;
$body-letter-spacing: -0.01em;
$heading-letter-spacing: -0.025em;

// Typography
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-bold:            700 !default;

// Links
$link-decoration: none;
$link-hover-decoration: underline;

// Headings
$headings-font-family: $font-secondary !default;
$headings-font-weight: normal;
$headings-line-height: 1.2;
$headings-margin-bottom: 15px;

$h1-font-size: 36px;
$h1-font-size-lg: 44px;
$h2-font-size: 28px;
$h2-font-size-lg: 34px;
$h3-font-size: 24px;
$h3-font-size-lg: 28px;
$h4-font-size: 26px;
$h4-font-size-lg: 26px;
$h5-font-size: 22px;
$h5-font-size-lg: 22px;
$h6-font-size: 18px;

// Paragraphs
$paragraph-margin-bottom: 15px;

// Breakpoints map
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: 568px,
    md: 768px,
    lg: 1024px,
    lgm: 1240px,
    xl: 1465px
);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-min-width: 375px;
$container-max-widths: (
    // sm: 568px,
    // md: 768px,
    // lg: 1024px,
    xl: 1464px
);

$container-max-widths-pdx: (
    lg: 1124px
);

$global-site-width-max: 1920px;

// Grid columns count
$grid-columns: 12;
$grid-gutter-width: 24px;

// Z-index map
$z-layers: (
    'below': -1,
    'default': 1,
    'above': 2,
    'sticky': 3,
    'overlay': 40,
    'popup-flyout': 50,
    'skipnav': 1000
);

// Animation
$transition-base-duration: 0.2s;
$transition-base: all $transition-base-duration;
$transition-mobile-navigation: left 0.3s ease;

// Global border
$global-border-color: $grey-1;

// Options
$enable-shadows: true;

// Components
$border-radius: 5px;
$border-radius-large: 10px;
$border-radius-pdx: 10px;
$caret-width: 5px;
$base-font-weight: $font-weight-medium;

// Dropdowns
$dropdown-min-width:                130px;
$dropdown-padding-y:                8px;
$dropdown-spacer:                   8px;
$dropdown-border-radius:            5px;
$dropdown-border-width:             1px;
$dropdown-item-padding-y:           10px;
$dropdown-item-padding-x:           20px;

// <hr>
$hr-margin-y:                       25px;
$hr-border-color:                   #f2f1f1;

// Card
$card-spacer-x:                     18px;
$card-spacer-y:                     10px;
