$product-line-item-name-font-size: 16px !default;

.product {
    &-info {
        padding: 16px;
        position: relative;
        border-bottom: 1px solid $card-border-color;
    }

    &-line-item-name {
        margin-bottom: 16px;
        font-size: $product-line-item-name-font-size;
    }

    &-image {
        min-width: 83px;
        min-height: 83px;
    }

    &-line-item-attributes {
        margin-bottom: 8px;
    }

    &-line-item-price-info {
        margin: 8px 0;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }

    &-brand-name {
        color: color('base-4');
        margin-bottom: 6px;
        text-transform: lowercase;
    }

    &-detail {
        .recommendations {
            div:empty {
                height: 502px;
            }
        }
    }
}
