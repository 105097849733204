.b-category {
    &-header {
        padding-top: 32px;

        @include media(md-up) {
            padding-top: 60px;
        }

        &-title {
            margin-bottom: 40px;
            font-size: 40px;

            @include media(md-up) {
                margin-bottom: 48px;
                font-size: 50px;
            }
        }
    }
}
