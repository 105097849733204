$notification-block-padding: 25px !default;
$notification-block-margin: 0 0 40px !default;
$notification-block-bg-color: $grey-1 !default;
$notification-block-border-color: $dark !default;
$notification-block-border-radius: $border-radius-large !default;
$notification-block-msg-padding: 0 0 0 22px !default;
$notification-block-msg-font-size: 16px !default;

.notification-block {
    padding: $notification-block-padding;
    margin: $notification-block-margin;
    background-color: $notification-block-bg-color;
    border: 1px solid $notification-block-border-color;
    border-radius: $notification-block-border-radius;

    &-msg {
        position: relative;
        padding: $notification-block-msg-padding;
        font-size: $notification-block-msg-font-size;

        &::before {
            @include inline-svg-bg($icon-info-dark);

            position: absolute;
            top: 1px;
            left: 0;
            width: 16px;
            height: 16px;
            content: '';
        }
    }
}
