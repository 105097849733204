.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}
