.b-carousel {
    @include when-inside('.m-page_designer .l-grid_layout') {
        &-title {
            letter-spacing: 0.36px;
            color: $blue;
            font-size: 36px;
            text-align: left;

            @include media(md-up) {
                font-size: 42px;
            }
        }

        &-nav {
            column-gap: 16px;
            margin-right: 0;
        }

        &-ctrl {
            border: 1px solid $blue;
            border-radius: 50%;
            padding: 8px;
            height: 40px;
            width: 40px;

            svg {
                height: 24px;
                width: 24px;
                color: $blue;
            }
        }

        &-item {
            .b-product_tile {
                width: 100%;

                &-body {
                    gap: 5px;
                }

                &-category {
                    margin: 2px 0 0;
                }

                &-image_link {
                    &::before {
                        padding-bottom: 52%;

                        @include media(lg-up) {
                            padding-bottom: 76%;
                        }
                    }
                }

                &-badge-wrapper,
                &-cta_wrapper,
                &-pricing {
                    padding-top: 5px;
                }

                .pdp-link,
                .b-product_tile-description {
                    margin-bottom: 0;
                }

                @include media(md-down) {
                    &-image_link {
                        &::before {
                            padding-bottom: 65%;
                        }
                    }
                }
            }

            .b-product_tile-pricing {
                margin: 0;

                .value {
                    font-size: 14px;
                }

                .price-value {
                    display: inline;
                }
            }
        }

        &_simple {
            .b-carousel {
                &-ctrl {
                    color: $dark;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        &_ext {
            .b-carousel {
                &-nav {
                    margin: 0;
                    gap: 16px;
                }
            }
        }

        &.m-pdx {
            @include when-inside('.l-pdp') {
                display: block;
            }

            @include media(md-up) {
                padding: 0;
                display: flex;
                gap: 20px;
            }

            .b-product_tile {
                height: 100%;

                @include media(md-up) {
                    display: flex;
                    flex-direction: column;
                }

                &-body {
                    padding: 0 16px 20px;
                }
            }

            h2 {
                @include media(md-down) {
                    font-size: 36px;
                }
            }

            .b-carousel {
                &-title {
                    @include when-inside('.l-pdp') {
                        width: auto;
                    }

                    @include media(md-up) {
                        width: 300px;
                        min-width: 300px;
                        gap: 20px;
                    }
                }

                &-content {
                    @include when-inside('.l-pdp') {
                        max-width: none;
                    }

                    @include media(md-up) {
                        max-width: calc(100% - 300px);
                    }
                }

                &-pagination {
                    display: none;
                }

                &-ctrl {
                    top: auto;
                    bottom: 150px;

                    @include when-inside('.l-pdp') {
                        top: 28px;
                        bottom: auto;
                        right: 55px;
                        left: auto;
                    }

                    &.m-next {
                        right: auto;
                        left: 55px;

                        @include when-inside('.l-pdp') {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }

            .price {
                margin: 0;

                > span {
                    @include flex($vertical: center);

                    font-size: 14px;
                    line-height: 20px;
                }

                .sales {
                    font-size: inherit;
                    margin-left: 5px;
                }
            }

            &.m-no-title {
                .b-carousel {
                    &-ctrl {
                        top: -60px;
                        left: initial;
                        right: 55px;

                        &.m-next {
                            left: initial;
                            right: 0;
                        }
                    }

                    &-content {
                        max-width: 100%;
                    }
                }
            }
        }

        &.m-products {
            .b-carousel-item {
                > div {
                    height: 100%;
                }
            }
        }

        // stylelint-disable no-descending-specificity
        &_ext,
        &.m-pdx {
            .b-carousel {
                &-ctrl {
                    width: 40px;
                    height: 40px;
                    color: $blue;
                    border-radius: 50%;
                    border: 1px solid $blue;

                    &:hover {
                        color: $white;
                        background: $blue;

                        svg {
                            color: $white;
                        }
                    }

                    &[disabled] {
                        opacity: 1;
                        background: none;

                        svg {
                            color: $blue;
                        }
                    }

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        &_ext,
        &_simple,
        &.m-pdx {
            .b-carousel {
                &-pagination_dot {
                    &:hover,
                    &.m-current {
                        fill: $blue;
                    }
                }
            }
        }
        // stylelint-enable no-descending-specificity
    }

    &-item {
        position: relative;
    }

    &.m-products:has(.m-promo) {
        .b-carousel-item {
            padding-top: 34px;
        }
    }
}
