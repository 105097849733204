@mixin inline-svg($name) {
    background: transparent url(nth($name, 1)) no-repeat 50% 50%;
    background-size: contain;
    width: nth($name, 2);
    height: nth($name, 3);
}

@mixin inline-svg-bg($icon-name, $rtl-icon-name: false) {
    @if ($rtl-icon-name) {
        background-color: transparent;
        background-image: url(nth($icon-name, 1)) rtl(url(nth($rtl-icon-name, 1)));
        background-repeat: no-repeat;
        background-position: 50% 50%;
    } @else {
        background: transparent url(nth($icon-name, 1)) no-repeat 50% 50%;
    }

    background-size: contain;
}

@function svg($name) {
    @return nth($name, 1);
}
// For example, how to use function svg():
// url("#{svg($icon-name)}");

@function inline-svg-width($name) {
    @return nth($name, 2);
}

@function inline-svg-height($name) {
    @return nth($name, 3);
}

@mixin background-svg($svg-variable, $background-position, $background-size) {
    background-image: url('#{svg($svg-variable)}');
    background-repeat: no-repeat;
    background-position: $background-position;
    background-size: $background-size;
}
