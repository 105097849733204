$search-widget-border-radius: 5px !default;
$search-widget-bg-color: $white !default;
$search-widget-divider-color: color('base-6') !default;
$search-widget-form-control-font-size: 13px !default;

.search-widget {
    margin: 0 auto;
    background-color: $search-widget-bg-color;

    @include media-breakpoint-up(md) {
        max-width: 414px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 464px;
    }

    &.m-primary {
        padding: 8px 0 0;

        @include media-breakpoint-up(md) {
            padding: 8px 20px 0;
            border-radius: $search-widget-border-radius;
        }
    }

    &.m-grid {
        .row {
            margin-left: -5px;
            margin-right: -5px;
        }

        [class*="col-"],
        .col {
            position: static;
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    &.m-left,
    &.m-horizontal_left {
        margin: 0 auto 0 0;
    }

    &.m-right,
    &.m-horizontal_right {
        margin: 0 0 0 auto;
    }

    &.m-vertical_top {
        align-self: flex-start;
    }

    &.m-vertical_middle {
        align-self: center;
    }

    &.m-vertical_bottom {
        align-self: flex-end;
    }

    &-inner {
        position: relative;
    }

    .form-group {
        margin: 0 0 15px;

        @include media-breakpoint-up(lg) {
            margin: 0 0 10px;
        }
    }

    .form-control {
        font-size: $search-widget-form-control-font-size;

        option[value=""] {
            font-weight: 600;
            font-size: 14px;
        }
    }

    &-title,
    &-subtitle {
        display: none;
        margin-bottom: 15px;
        font-size: 16px;
    }

    &-divider {
        border-color: $search-widget-divider-color;
        margin: 0 0 15px;

        @include media-breakpoint-up(lg) {
            margin: 0 0 10px;
        }
    }

    &-quantity {
        padding: 15px 0 8px;

        @include media-breakpoint-down(sm) {
            margin-left: -23px;
        }
    }

    &-footer {
        padding: 12px ($grid-gutter-width * 0.5) 30px;
        margin: 0 (-$grid-gutter-width * 0.5);

        @include media-breakpoint-up(md) {
            padding: 12px 20px 30px;
            margin: 0 -20px;
            border-radius: 0 0 $search-widget-border-radius $search-widget-border-radius;
        }

        @include when-inside('.is-invalid-form') {
            position: relative;
            background-color: $alert-background;
            padding-bottom: 0;
        }

        .alert-danger {
            background-color: transparent;
        }
    }
}

.js-search_widget-group {
    .form-group {
        margin: 0 0 15px;
        position: relative !important;
    
        @include media-breakpoint-up(lg) {
            margin: 0 0 10px;
        }                   
    
        .form-control-label {
            position: absolute;
            color: #766a65;
            margin-left: 15px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.2s ease;
    
            &::before {
                display: none;
            }
        }
    
        &:has(.form-control:focus)  {
            .form-control-label {
               background-color: #fff !important;
               top: 0px;
               padding: 0 5px;
               font-size: 12px;
           }
        }

        @include media-breakpoint-up(lg) {
            &:has(.form-control:not(:placeholder-shown)) {
                .form-control-label {                
                   background-color: #fff !important;
                   top: 0px;
                   padding: 0 5px;
                   font-size: 12px;
               }
            } 
        }

        @include media-breakpoint-down(md) {
            &.active {
                .form-control-label {
                    background-color: #fff !important;
                    top: 0px;
                    padding: 0 5px;
                    font-size: 12px;
                }
            }
        }
    }
}
