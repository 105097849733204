$comparison-table-th-color: $white;
$comparison-table-th-bg-color: color('base-4');
$comparison-description-font-weight: $font-weight-normal;
$comparison-description-color: color('base-4');
$comparison-badge-color: color('base-0');
$comparison-badge-bg-color: color('primary-1');
$comparison-head-secondary-border-color: rgba(color('base-0'), 0.15);
$comparison-price-through-color: color('base-3');
$comparison-section-inner-box-shadow-color: rgba(color('base-0'), 0.25);
$comparison-section-inner-border-radius: 0;
$comparison-description-icon-img-filter: none;
$comparison-caption-font-weight: $font-weight-bold;
$comparison-caption-font-size: 18px;
$comparison-caption-font: $comparison-caption-font-weight $comparison-caption-font-size $font-emirates !default;

.comparison {
    &-table {
        th {
            &:first-child {
                border-radius: 25px 0 0 0;
                border: none;

                .comparison-caption {
                    font-family: 'Helvetica Neue';
                    font-weight: 400;
                }
            }

            &:last-child {
                border-radius: 0 25px 0 0;
            }
        }

        tr:last-child {
            td {
                &:first-child {
                    border-radius: 0 0 0 25px;
                }

                &:last-child {
                    border-radius: 0 0 25px 0;
                }
            }
        }
    }

    &-section-inner {
        border-radius: 25px;
    }

    &-head {
        @include media-breakpoint-down(md) {
            background-color: $comparison-table-th-bg-color !important;
        }
    }
}
