$service-tile-title-color: color('base-4') !default;
$service-tile-content-color: color('base-5') !default;
$service-tile-gradient: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 65%, rgba(255, 255, 255, 0.1) 100%) !default;
$line-height-tile-content: 1.3 !default;

.service-tile {
    &-bg {
        padding-top: 350px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        @if ($theme-brand == "marhaba") {
            border-radius: $border-radius;
        }

        @include media-breakpoint-up(md) {
            padding-top: 300px;
        }
    }

    &-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 34px 26px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: z('default');
        transition: 0.5s all;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            padding: 34px;
            display: block;
        }

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border: 1px solid $white;
            z-index: z('below');

            @if ($theme-brand == "marhaba") {
                border-radius: $border-radius;
            }
        }

        &.m-wrapper-gradient {
            background: $service-tile-gradient;
        }

        .btn.m-tile-btn {
            max-width: 130px;
            order: 4;

            @include media-breakpoint-up(md) {
                max-width: 160px;
                bottom: 34px;
                left: 34px;
                position: absolute;
                z-index: z('default');
            }
        }

        .service-tile-bottoms {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
        .service-content-tile {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: 0.7s all;

            @media screen and (max-width: 1024px) {
                margin-top: 0;
            }

            @media screen and (min-width: 1025px) {
                margin-top: 185px;
            }

            .service-tile-content {
                width: 85%;
            }
        }

        @include media-breakpoint-up(md) {
            &:hover .service-content-tile {
                margin-top: 0;
            }
        }
    }
    .with-hover {
        .service-badge {
            position: relative;
            right: 0;
            top: 0;
            display: block;
            max-width: 97px;
            padding: 12px 10px;

            &.width {
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    max-width: max-content;
                }
            }
        }
        .btn.m-tile-btn {
            bottom: 0;
            left: 0;
            position: relative;
            z-index: z('default');
            order: inherit;

            @include media-breakpoint-up(md) {
                left: 0;
            }
        }
    }

    &-title {
        @include limit-text-lines($max-lines: 1, $font-size: 36px, $is-max-height: true);

        color: $service-tile-title-color;

        @include media-breakpoint-up(lg) {
            @include limit-text-lines($max-lines: 1, $font-size: 44px, $is-max-height: true);
        }
    }

    &-content {
        @include limit-text-lines($max-lines: 4, $is-max-height: true);

        color: $service-tile-content-color;
        width: 70%;
    }

    &-promo {
        margin-top: 20px;
        margin-bottom: 5px;

        /* stylelint-disable */
        span {
            display: block;
        }
        /* stylelint-enable */
    }
}

.service-tiles {
    @include clearfix;

    margin: 0 auto 24px;

    &-top,
    &-bottom {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
    }

    &-bottom {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
            margin: 0 (-$grid-gutter-width)*0.5 60px;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            padding-bottom: 20px;
        }

        &.box-scrollbar.m-horizontal {
            padding-bottom: 20px;
            margin-left: 0;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
                overflow: hidden;
            }
        }

        .service-tile {
            @include media-breakpoint-down(md) {
                flex: 0 0 55vw;
                max-width: initial;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 80vw;
            }

            &:first-child {
                padding-left: 0;

                @include media-breakpoint-up(lg) {
                    margin-left: 0;
                }
            }
        }

        .service-tile-title {
            @include limit-text-lines($max-lines: 1, $font-size: 28px, $is-max-height: true);

            @include media-breakpoint-up(lg) {
                @include limit-text-lines($max-lines: 1, $font-size: 34px, $is-max-height: true);
            }
        }

        .service-tile-content {
            @include limit-text-lines($line-height: $line-height-tile-content, $max-lines: 4, $font-size: 12px, $is-max-height: true);

            font-size: 12px;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }

        .service-content-tile {
            @media screen and (max-width: 1024px) {
                margin-top: 0;
            }
            @media screen and (min-width: 1025px) {
                margin-top: 30%;
            }
        }
        .aa {
            @media screen and (max-width: 1024px) {
                margin-top: 0;
            }
            @media screen and (min-width: 1025px) {
                margin-top: 150px;
            }
            &:hover {
                margin-top: 0;
            }
        }
    }

    &-top {
        margin: 0 (-$grid-gutter-width)*0.5;

        /* stylelint-disable */
        .service-tile {
            &:first-child {
                margin-bottom: 24px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }
        }
        /* stylelint-enable */

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }

        .service-tile-bg {
            padding-top: 360px;
        }

        .service-tile-wrapper {
            display: block;
        }

        .service-tile-content {
            font-size: 16px;
        }

        .btn.m-tile-btn {
            bottom: 34px;
            left: 26px;
            position: absolute;
            z-index: z('default');

            @include media-breakpoint-up(md) {
                left: 34px;
            }
        }
    }
}
