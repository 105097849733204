// ul li
//
// Styleguide 3.3

// List primary
//
// Markup:
// <ul class="list-marker">
//   <li>The quick brown fox jumps over the lazy dog</li>
//   <li>The quick brown fox jumps over the lazy dog</li>
// </ul>
//
// Styleguide 3.3.1

// List secondary
//
// Markup:
// <ul class="list-marker m-secondary">
//   <li>The quick brown fox jumps over the lazy dog</li>
//   <li>The quick brown fox jumps over the lazy dog</li>
// </ul>
//
// Styleguide 3.3.2

// List tertiary
//
// Markup:
// <ul class="list-marker m-tertiary">
//   <li>The quick brown fox jumps over the lazy dog</li>
//   <li>The quick brown fox jumps over the lazy dog</li>
// </ul>
//
// Styleguide 3.3.3

// List unordered
//
// Markup:
// <ul class="list-unordered">
//   <li>The quick brown fox jumps over the lazy dog</li>
//   <li>The quick brown fox jumps over the lazy dog</li>
// </ul>
//
// Styleguide 3.3.4

// List unordered secondary
//
// Markup:
// <ul class="list-unordered m-secondary">
//   <li>The quick brown fox jumps over the lazy dog</li>
//   <li>The quick brown fox jumps over the lazy dog</li>
// </ul>
//
// Styleguide 3.3.5

// list-marker
$list-marker-padding: 0 0 10px !default;
$list-marker-item-padding: 0 0 0 33px !default;
$list-marker-item-margin: 0 0 10px !default;
$list-marker-item-before-svg: $icon-marker !default;
$list-marker-secondary-item-before-svg: $icon-check !default;
$list-marker-tertiary-item-before-svg: $icon-marker-quaternary !default;
$list-marker-item-before-width: 18px !default;
$list-marker-item-before-height: 18px !default;

// list-unordered
$list-unordered-padding: 0 0 10px !default;
$list-unordered-item-margin: 0 0 10px !default;
$list-unordered-item-padding: 0 0 0 14px !default;
$list-unordered-item-before-top: 0.6em !default;
$list-unordered-item-before-color: inherit !default;
$list-unordered-item-before-content: '\2022' !default;
$list-unordered-secondary-item-padding: 0 0 0 22px !default;
$list-unordered-secondary-item-before-content: '\00b0' !default;

$list-icons-gutter: 10px !default;
$list-icons-padding: 0 0 10px !default;
$list-icons-margin: 0 -#{$list-icons-gutter} !default;
$list-icons-item-width: 36px !default;
$list-icons-item-height: $list-icons-item-width !default;
$list-icons-item-margin: #{$list-icons-gutter} !default;
$list-icons-item-bg-color: $grey-1 !default;

// list-ordered
$list-ordered-padding: 0 0 0 14px !default;
// list-option
$list-option-padding: 0 0 10px !default;
$list-option-icon-wrap-size: 50px !default;
$list-option-item-min-height: 50px !default;
$list-option-item-space: 25px !default;
$list-option-item-padding: 5px 0 0 ($list-option-icon-wrap-size + $list-option-item-space) !default;
$list-option-item-margin: 0 0 15px !default;
$list-option-font-size: 14px !default;
$list-option-icon-wrap-bg-color: #ebebeb !default; // exception, for all brands
$list-option-icon-size: 28px !default;
$list-option-icon-color: inherit !default;
$list-option-caption-color: $black !default;
$list-option-caption-font-size: 16px !default;

.list-marker {
    list-style: none;
    padding: $list-marker-padding;
    margin: 0;

    li,
    &-item {
        position: relative;
        padding: $list-marker-item-padding;
        margin: $list-marker-item-margin;
    }

    li.m-nomark {
        padding-left: 0;

        &::before {
            content: none;
        }
    }

    li::before,
    &-item::before {
        @include inline-svg-bg($list-marker-item-before-svg);

        position: absolute;
        top: $list-unordered-item-before-top;
        left: 0;
        width: $list-marker-item-before-width;
        height: $list-marker-item-before-height;
        transform: translateY(-50%);
        content: "";
    }

    &.m-secondary &-item::before,
    &.m-secondary li::before {
        @include inline-svg-bg($list-marker-secondary-item-before-svg);
    }

    &.m-tertiary &-item::before,
    &.m-tertiary li::before {
        @include inline-svg-bg($list-marker-tertiary-item-before-svg);
    }
}

.list-unordered {
    list-style: none;
    padding: $list-unordered-padding;
    margin: 0;

    li {
        position: relative;
        padding: $list-unordered-item-padding;
        margin: $list-unordered-item-margin;

        /* stylelint-disable */
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            color: color('base-0');
            content: $list-unordered-item-before-content;
        }
        /* stylelint-enable */
    }

    &.m-secondary &-item::before,
    &.m-secondary li::before {
        content: $list-unordered-secondary-item-before-content;
        line-height: 1.9;
    }

    &.m-secondary-list {
        padding-left: 15px;
    }
}

.list-ordered {
    padding: $list-ordered-padding;
}

.list-icons {
    list-style: none;
    padding: $list-icons-padding;
    margin: $list-icons-margin;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li,
    &-item {
        width: $list-icons-item-width;
        height: $list-icons-item-height;
        margin: $list-icons-item-margin;
        background-color: $list-icons-item-bg-color;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        &.m-icon {
            &-conditioner {
                background-image: url("#{svg($icon-conditioner-white)}");
            }

            &-wifi {
                background-image: url("#{svg($icon-wifi-white)}");
            }

            &-phone {
                background-image: url("#{svg($icon-phone-white)}");
            }

            &-printer {
                background-image: url("#{svg($icon-printer-white)}");
            }

            &-tv {
                background-image: url("#{svg($icon-tv-white)}");
            }

            &-cup {
                background-image: url("#{svg($icon-cup-white)}");
            }

            &-bar {
                background-image: url("#{svg($icon-bar-white)}");
            }

            &-breakfast {
                background-image: url("#{svg($icon-breakfast-white)}");
            }

            &-wash-machine {
                background-image: url("#{svg($icon-wash-machine-white)}");
            }

            &-refrigerator {
                background-image: url("#{svg($icon-refrigerator-white)}");
            }

            &-dinner {
                background-image: url("#{svg($icon-dinner-white)}");
            }

            &-laptop {
                background-image: url("#{svg($icon-laptop-white)}");
            }

            &-flight-info {
                background-image: url("#{svg($icon-flight-info-white)}");
            }

            &-hair-dryer {
                background-image: url("#{svg($icon-hair-dryer-white)}");
            }

            &-bath {
                background-image: url("#{svg($icon-bath-white)}");
            }

            &-newspaper {
                background-image: url("#{svg($icon-newspaper-white)}");
            }

            &-wheelchair {
                background-image: url("#{svg($icon-wheelchair-white)}");
            }
        }
    }
}

.list-option {
    padding: $list-option-padding;
    margin-bottom: 0;
    font-size: $list-option-font-size;
    list-style: none;

    &-item {
        position: relative;
        min-height: $list-option-item-min-height;
        padding: $list-option-item-padding;
        margin: $list-option-item-margin;
    }

    &-icon {
        width: $list-option-icon-size;
        height: $list-option-icon-size;
        color: $list-option-icon-color;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;

        &-wrap {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $list-option-icon-wrap-size;
            height: $list-option-icon-wrap-size;
            border-radius: 50%;
            background-color: $list-option-icon-wrap-bg-color;
        }
    }

    &-caption {
        display: block;
        color: $list-option-caption-color;
        font-size: $list-option-caption-font-size;
    }
}
