.b-plp_actions {
    @include flex($horizontal: flex-end, $vertical: center, $wrap: wrap, );

    column-gap: 12px;
    margin-bottom: 20px;

    &.m-has_count {
        justify-content: space-between;
    }

    @include media(lg-up) {
        margin-bottom: 28px;
        flex-wrap: nowrap;
    }

    @include media(md-down) {
        background: $white;
        padding-bottom: 20px;
        z-index: z('above');

        &.m-sticky {
            position: sticky;
            padding-top: 20px;
        }
    }

    &-results_count {
        margin-bottom: 0;

        @include media(md-down) {
            margin-bottom: 24px;
            text-align: center;
            width: 100%;

            .m-sticky & {
                display: none;
            }
        }
    }

    &-refinements_toggle {
        @include media(md-down) {
            @include flex($horizontal: center);
            @include button-style($color: $dark, $bg: transparent, $border-radius: $border-radius-large);

            border: 1px solid $dark;
            color: $dark;
            column-gap: 8px;
            font-family: $font-emirates;
            padding: 10px 32px;
            width: calc(50% - 6px);
        }

        @include media(lg-up) {
            display: none;
        }
    }

    &-sorting {
        @include flex($vertical: center);

        column-gap: 20px;

        @include media(md-down) {
            width: calc(50% - 6px);
        }

        &_label {
            margin: 0;

            @include media(md-down) {
                display: none;
            }
        }

        .b-select {
            @include flex($vertical: center);

            position: relative;

            @include media(lg-up) {
                width: 212px;
                max-height: 20px;
            }

            @include media(md-down) {
                width: 100%;
            }

            &::after {
                @include media(md-down) {
                    background: $white;
                    content: attr(data-sort-label);
                    font-size: 16px;
                    font-family: $font-emirates;
                    left: 10px;
                    pointer-events: none;
                    position: absolute;
                    text-align: center;
                    width: calc(100% - 50px);
                }
            }

            svg {
                position: absolute;
                right: 12px;
                pointer-events: none;
            }

            &-input#plp-sort {
                @include media(md-down) {
                    border-radius: $border-radius-large;
                    border-color: $dark;
                    height: 44px;
                    font-size: 16px;
                    font-family: $font-emirates;
                    padding-right: 40px;
                }
            }
        }
    }
}
