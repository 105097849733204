$product-price-value-font: $font-weight-medium 32px / 1 $font-primary !default;
$product-price-value-color: $black !default;
$product-price-currency-color: $dark !default;
$product-price-currency-through-color: $grey-3 !default;

.product-price {
    display: flex;

    .price-value {
        display: block;
        padding-top: 2px;
        font: $product-price-value-font;
        color: $product-price-value-color;
    }

    &-currency {
        color: $product-price-currency-color;

        &.m-through {
            margin-left: 20px;
            color: $product-price-currency-through-color;

            @include media-breakpoint-up(md) {
                margin-left: 35px;
            }

            .price-value {
                text-decoration: line-through;
                color: inherit;
            }
        }
    }
}
