// Date Time picker
//
// Styleguide 10

// Date & Time
//
// Markup:
// <form>
//  <div class="form-group">
//    <label class="form-control-label">Date & Time title</label>
//    <div class="form-input-wrapper m-datepicker">
//      <input class="form-control datetimepicker">
//    </div>
//  </div>
// </form>
// <script>
//   $('.datetimepicker').datetimepicker({
//     useCurrent: false,
//     sideBySide: true
//   });
// </script>
//
// Styleguide 10.1

// Date only
//
// Markup:
// <form>
//  <div class="form-group">
//    <label class="form-control-label">Date & Time title</label>
//    <div class="form-input-wrapper m-datepicker">
//      <input class="form-control datetimepicker2">
//    </div>
//  </div>
// </form>
// <script>
//   $('.datetimepicker2').datetimepicker({
//     useCurrent: false,
//     format: 'MMM DD YYYY',
//     sideBySide: true
//   });
// </script>
//
// Styleguide 10.2

$datetimepicker-border-radius: 0 !default;
$datetimepicker-font-size: $body-font-size !default;
$datetimepicker-color: color('base-0') !default;
$datetimepicker-bg-color: $white !default;
$datetimepicker-cell-active-color: $white !default;
$datetimepicker-container-border-radius: 5px !default;
$datetimepicker-button-color: $white !default;
$datetimepicker-button-font-size: 12px !default;

.bootstrap-datetimepicker-widget {
    font-size: $datetimepicker-font-size;
    line-height: 1;
    color: $datetimepicker-color;
    background-color: $datetimepicker-bg-color;
    list-style: none;
    z-index: 1;

    // TODO create mixin

    &.dropdown-menu {
        display: block;
        width: 232px rtl(280px);
        height: auto;
        margin: 0;
        padding: 0;
        border: 1px solid $datetimepicker-container-border-color;
        border-radius: $datetimepicker-container-border-radius;
        box-shadow: none;
        overflow: visible;

        &.timepicker-sbs {
            @include media-breakpoint-up(md) {
                width: 400px rtl(424px);
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            border: solid transparent;
            height: 0;
            width: 0;
            top: auto;
            left: 15px rtl-ignore();
            right: auto rtl-ignore();
            bottom: auto;
        }

        &::before {
            border-width: 8px;
        }

        &::after {
            border-width: 7px;
            margin: 0 0 0 1px rtl(0 0 0 -7px);
        }

        &.bottom {
            margin: 10px 0 0;

            &::before {
                border-bottom-color: $datetimepicker-container-border-color;
                bottom: 100%;
            }

            &::after {
                border-bottom-color: $datetimepicker-bg-color;
                bottom: 100%;
            }
        }

        &.top {
            margin: 0 0 10px;

            &::before {
                border-top-color: $datetimepicker-container-border-color;
                top: 100%;
            }

            &::after {
                border-top-color: $datetimepicker-bg-color;
                top: 100%;
            }
        }

        &.pull-right {
            &::before,
            &::after {
                left: auto rtl-ignore();
                right: 15px rtl-ignore();
            }

            &::after {
                margin: 0 -7px 0 0 rtl-ignore();
            }
        }
    }

    .row.row {
        margin: 0;
    }

    .datepicker {
        padding: 20px;

        &[class*="col"] {
            padding: 20px;

            @include media-breakpoint-up(md) {
                flex: 0 0 58% rtl(0 0 67%);
                max-width: 58% rtl(67%);
            }
        }

        table {
            table-layout: fixed;
        }

        &-months,
        &-years,
        &-decades {
            span[data-action] {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 32%;
                min-height: 40px;
                margin: 0.5%;
                vertical-align: middle;
                border-radius: $datetimepicker-border-radius;
                cursor: pointer;

                @include on-event {
                    background-color: $datetimepicker-cell-hover;
                }

                &.active {
                    color: $datetimepicker-cell-active-color;
                    background-color: $datetimepicker-cell-active-bg;
                }
            }
        }
    }

    .timepicker {
        padding: 20px;

        &[class*="col"] {
            position: relative;
            padding: 20px;

            @include media-breakpoint-up(md) {
                flex: 0 0 42% rtl(0 0 33%);
                max-width: 42% rtl(33%);
                padding-top: 45px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 20px;
                top: 0;
                right: 20px;
                border: 1px solid $datetimepicker-separator-color;
                border-width: 1px 0 0;

                @include media-breakpoint-up(md) {
                    top: 20px;
                    left: 0;
                    bottom: 20px;
                    right: auto;
                    border-width: 0 0 0 1px;
                }
            }
        }

        &-picker {
            .separator {
                padding: 0 2px;
            }
        }
    }

    .list-unstyled {
        margin: 0;
    }

    a[data-action] {
        display: inline-block;
        width: auto;
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
    }

    .glyphicon {
        display: block;
        width: 14px;
        height: 14px;
        margin: 0 auto;
        background: center / contain no-repeat;

        &-chevron-up {
            background-image: url("#{svg($icon-arrow-top-primary)}");
        }

        &-chevron-down {
            background-image: url("#{svg($icon-arrow-bottom-primary)}");
        }

        &-chevron-left {
            background-image: url("#{svg($icon-arrow-left-primary)}");
        }

        &-chevron-right {
            background-image: url("#{svg($icon-arrow-right-primary)}");
        }
    }

    button[data-action] {
        @include highlight;

        width: 26px;
        padding: 8px 0 6px;
        margin: 0 auto;
        border: none;
        font-size: $datetimepicker-button-font-size;
        border-radius: $datetimepicker-border-radius;
        background-color: $datetimepicker-button-bg-color;
        color: $datetimepicker-button-color;
    }

    .table-condensed {
        width: 100%;
        margin: 0;

        td,
        th {
            padding: 8px 0 5px;
            text-align: center;
            font-weight: inherit;
        }

        .dow {
            font-size: inherit rtl(13px);
            border: solid transparent;
            border-width: 0 2px;
            overflow: hidden;
        }

        .day,
        .hour,
        .minute,
        .second {
            border-radius: $datetimepicker-border-radius;

            @include on-event {
                background-color: $datetimepicker-cell-hover;
                cursor: pointer;
            }

            &.active {
                @include on-event($self: true) {
                    background-color: $datetimepicker-cell-active-bg;
                    color: $datetimepicker-cell-active-color;
                }

                &.today {
                    &::before {
                        border-bottom-color: $datetimepicker-cell-active-color;
                    }
                }
            }
        }

        .old,
        .new {
            color: $datetimepicker-cell-old-color;
        }

        .today {
            position: relative;

            &::before {
                content: '';
                border: solid transparent;
                border-width: 0 0 4px 4px;
                border-bottom-color: $datetimepicker-cell-active-bg;
                position: absolute;
                bottom: 3px;
                right: 3px;
            }
        }

        .disabled {
            @include on-event($self: true) {
                color: $datetimepicker-cell-disabled-color;
                background: none;
                cursor: default;
            }
        }
    }

    thead {
        tr:first-child th {

            @include on-event {
                cursor: pointer;
            }
        }
    }

    .picker-switch {
        text-align: center;

        td {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            line-height: inherit;
        }
    }
}

.input-group.date {
    .input-group-addon {
        cursor: pointer;
    }
}