.b-hero_carousel {
    @include when-inside('.m-page_designer') {
        &-autoplay {
            &_play,
            &_pause {
                fill: $blue;
                stroke: $blue;
            }

            &.m-animated {
                .b-hero_carousel-autoplay_progress {
                    stroke: $blue;
                }
            }
        }

        &-pagination {
            @include media(md-down) {
                left: 0;
                transform: none;
            }
        }

        &-pagination_dot.m-current {
            fill: $blue;
        }

        &_ext {
            &.m-with_booking {
                background: none;
            }
        }
    }
}
