.b-text_block {
    @include when-inside('.m-page_designer') {
        h1,
        h2 {
            color: $blue;
        }

        &-container {
            border-radius: 0;
        }

        &.m-horizontal_left {
            text-align: left;
        }

        &.m-horizontal_center {
            text-align: center;
        }

        &.m-horizontal_right {
            text-align: right;
        }
    }
}
