.footer {
    background-color: initial;
    color: $grey-8;
    position: relative;
    margin-top: 48px;
    border: none;
    padding: 24px 0;

    @include media(lg-up) {
        padding-top: 48px;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        background-image: url('../images/global-bg-aa-sm.svg');
        background-repeat: repeat-x;
        background-size: 375px 4px;
        height: 4px;

        @include media(md-up) {
            background-image: url('../images/global-bg-aa-lg.svg');
            background-size: 1440px 4px;
        }
    }

    &-inner {
        max-width: 100%;
        margin: 0;
    }

    &-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        color: $dark;
        text-align-last: left;
    }

    &-logo {
        max-width: initial;
        margin-bottom: 32px;
        display: block;

        .content-asset {
            height: initial;
            width: initial;
        }

        img {
            max-width: 120px;
        }
    }

    &-subscribe {
        margin: 0 0 32px;
        max-width: initial;

        @include media(lg-up) {
            margin-bottom: 40px;
        }

        .btn {
            color: $blue;
            border-color: $blue;
            display: inline-block;

            &:hover {
                border-color: $yellow;
            }
        }
    }

    &-social {
        padding: 0;
        margin-bottom: 32px;

        @include media(lg-up) {
            margin-bottom: 40px;
        }

        &::before {
            display: none;
        }
    }

    &-social-list {
        justify-content: initial;
        column-gap: 16px;

        li {
            margin: 0;
            width: auto;
        }
    }

    &-switchers {
        @include flex;

        margin-top: 32px;
        column-gap: 20px;

        @include media(lg-up) {
            margin-top: 40px;
        }
    }

    &-nav {
        margin-top: 40px;

        @include media(lg-up) {
            @include flex;

            margin-top: 0;
        }

        > {
            .content-asset {
                @include media(lg-up) {
                    width: 75%;
                }
            }
        }

        .accordion {
            &:not(.accordion-init) {
                @include flex;

                column-gap: 32px;

                .accordion-item {
                    width: 33%;
                }
            }

            &.accordion-init {
                .accordion-control {
                    .footer {
                        &-title {
                            margin-bottom: 0;
                        }
                    }

                    .accordion-icon {
                        @include inline-svg($icon-plus);
                    }

                    &.m-accordion-expanded {
                        .accordion-icon {
                            @include inline-svg($icon-minus);
                        }
                    }
                }
            }
        }

        li {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 20px;
        }
    }

    &-contacts {
        @include media(lg-up) {
            width: 25%;
        }
    }

    &-contacts-item {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 5px 0 0 32px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
        }

        &.m-phone {
            &::before {
                @include inline-svg($icon-footer-phone);
            }
        }

        &.m-whatsapp {
            &::before {
                @include inline-svg($icon-footer-whatsapp);
            }
        }

        &.m-email {
            &::before {
                @include inline-svg($icon-footer-email);
            }
        }

        &.m-contact {
            &::before {
                @include inline-svg($icon-footer-contact);
            }
        }

        &.m-location {
            &::before {
                @include inline-svg($icon-footer-location);
            }
        }
    }

    &-middle {
        @include flex($horizontal: space-between, $vertical: flex-start);

        padding-top: 40px;
        row-gap: 32px;
        flex-direction: column;

        @include media(lg-up) {
            flex-direction: row;
            align-items: flex-end;
            column-gap: 20px;
        }

        &::before {
            display: none;
        }
    }

    &-logos {
        font-size: 12px;
        line-height: 16px;
        max-width: 540px;
    }

    &-logos-content {
        @include flex($vertical: flex-start);

        gap: 12px;
        flex-direction: column;

        @include media(lg-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &-awards {
        margin: 0;
    }

    &-awards-list {
        @include flex;

        column-gap: 20px;

        li {
            margin: 0;

            &:first-child,
            &:last-child {
                height: initial;
                max-width: 100px;
                width: 100px;
            }


            img {
                height: initial;
            }
        }
    }

    &-bottom {
        @include flex($horizontal: space-between, $vertical: center);

        line-height: 1;
        padding-top: 20px;
        border-top: 1px solid $grey-10;
        margin-top: 24px;
        flex-direction: column;

        @include media(lg-up) {
            flex-direction: row;
        }
    }

    &-copyright {
        margin: 0 0 20px;
        text-align: left;

        @include media(lg-up) {
            margin: 0;
        }
    }

    &-policy-list {
        column-gap: 16px;
    }

    &-policy-link {
        margin: 0 16px 0 0;
    }

    .dropdown {
        @include media(md-down) {
            position: relative;
        }

        &-menu {
            margin: 0;

            @include media(md-down) {
                @include box-shadow($dropdown-box-shadow);

                left: 0;
                top: 20px;
            }
        }
    }
}
