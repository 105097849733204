$pdp-product-divider-color: color('base-6') !default;
$pdp-product-element-border-radius: $border-radius !default;
$pdp-product-element-border-radius-large: $border-radius-large !default;
// title
$pdp-product-title-color: $black !default;
// wrapper
$pdp-product-wrapper-border-color: $pdp-product-divider-color !default;
// status
$pdp-product-status-border-color: $pdp-product-divider-color !default;
$pdp-product-status-arrow: $icon-arrow-direction-right-primary !default;
$pdp-product-status-arrow-rtl: $icon-arrow-direction-left-primary !default;
$pdp-product-status-bg-color: #fafafa !default;
$pdp-product-status-divider-color: $black !default;
// details
$pdp-product-details-border-color: $pdp-product-divider-color !default;
$pdp-product-details-head-border-color: $pdp-product-divider-color !default;
$pdp-product-details-head-caption-color: $black !default;
// service
$pdp-product-service-sub-color: $black !default;
// pdp cta
$pdp-product-cta-color: $black !default;
// explanatory
$pdp-product-explanatory-color: $white !default;
$pdp-product-explanatory-bg-color: $dark !default;
// pickup
$pdp-product-pickup-bg-color: color('base-6') !default;
$pdp-product-pickup-border-radius: $pdp-product-element-border-radius-large !default;
// input-msg
$pdp-product-input-msg-color: inherit !default;

.pdp-product {
    &-wrapper {
        @include divider-full-width($color: $pdp-product-wrapper-border-color, $placing: bottom);

        padding: 0 0 40px;
        margin: 0 0 40px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
        }
    }

    &-image {
        position: relative;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            max-width: 378px;
        }

        @include media-breakpoint-up(md) {
            max-width: 616px;
        }
    }

    &-title {
        color: $pdp-product-title-color;
        font: $font-weight-medium 26px $font-primary;

        @include media-breakpoint-up(md) {
            font-size: 28px;
        }
    }

    &-search-widget {
        margin: 0 0 15px;
    }

    &-status {
        padding: 10px 0;
        border-bottom: 1px solid $pdp-product-status-border-color;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            border-radius: 10px;
            background-color: $pdp-product-status-bg-color;
            border: none;
        }

        &-divider {
            display: inline-block;
            font-size: 13px;
            margin: 0 3px;
            vertical-align: middle;
            color: $pdp-product-status-divider-color;
        }

        &-content {
            padding: 0 0 15px;

            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }

        &-button {
            padding: 0 0 15px;

            @include media-breakpoint-up(md) {
                padding: 0 0 0 15px;
            }
        }

        &-arrow {
            @include inline-svg-bg($pdp-product-status-arrow, $pdp-product-status-arrow-rtl);

            position: relative;
            top: -2px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            vertical-align: middle;
        }
    }

    &-details {
        padding: 0 0 12px;
        border-bottom: 1px solid $pdp-product-details-border-color;

        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            margin: 0 0 15px;
            border-bottom: 1px solid $pdp-product-details-head-border-color;

            &-caption {
                font-size: 22px;
                color: $pdp-product-details-head-caption-color;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }
            }

            &-button {
                padding: 0 0 0 15px;
            }
        }

        &-quantity {
            @include media-breakpoint-up(sm) {
                max-width: 300px;

                .row {
                    justify-content: space-between;
                }

                .col {
                    flex-grow: 0;
                }
            }

            .quantity-label {
                font-size: 16px;
            }

            &-chauffeur {
                .chauffeur & {
                    max-width: 500px;
                    margin-left: 15px;

                    .row {
                        flex-wrap: nowrap;
                    }

                    .col {
                        flex-grow: 1;

                        @include media-breakpoint-up(sm) {
                            padding: 0 7px;
                        }
                    }
                }
            }
        }
    }

    &-service {
        text-align: center;
        margin: 0 0 20px;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
        }

        &-title {
            display: block;
            font-size: 22px;

            @include media-breakpoint-up(md) {
                display: inline-block;
                margin: 0 10px 0 0;
                vertical-align: middle;
            }
        }

        &-sub {
            display: inline-block;
            font-size: 12px;
            color: $pdp-product-service-sub-color;
            vertical-align: middle;

            @include media-breakpoint-up(md) {
                margin: 0 10px 3px 0;
            }
        }

        &-cta {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }

            &-mobile {
                text-align: center;
                margin: 0 0 25px;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        &-compare {
            display: none;
        }
    }

    &-attribute {
        align-items: center;
        display: flex;
        margin: 0 0 20px;
        font-weight: $font-weight-medium;

        &-item {
            & + & {
                margin: 0 0 0 15px;
            }
        }

        &-datamissed {
            display: block;
            font-size: 13px;
            font-weight: $font-weight-normal;
        }

        .form-group {
            margin: 0;
        }
    }

    &-attributes {
        &-chauffeur {
            display: flex;

            .pdp-product-attribute {
                align-items: flex-end;
                margin: 0 15px 36px 0;

                .btn-quaternary {
                    height: 35px;
                }

                .invalid-feedback {
                    position: absolute;
                    bottom: 85px;

                    @include media-breakpoint-down(sm) {
                        bottom: 95px;
                    }
                }
            }
        }
    }

    &-chauffeur {
        &-attributes {
            width: 80%;

            .col-md-6 {
                margin-bottom: 36px;
            }

            .form-group {
                .invalid-feedback {
                    position: absolute;
                    padding-left: 17px;
                    margin-top: 2px;
                }
            }
        }
    }

    &-addtocart {
        margin: 0 auto;

        @include when-inside('.pdp-product-pickup') {
            padding: 0;
        }

        @include media-breakpoint-up(md) {
            padding: 0 5px;
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: 450px;
            margin: 0;
        }

        @include media-breakpoint-up(xl) {
            max-width: 350px;
        }

        &-wrap {
            margin: 30px 0;

            @include media-breakpoint-up(md) {
                margin: 15px -5px 10px;
            }
        }

        &-button {
            @include media-breakpoint-up(lg) {
                max-width: 450px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 350px;
            }
        }

        .alert-danger {
            margin: 15px 0 0;
        }
    }

    &-explanatory {
        padding: 11px 12px 10px;
        color: $pdp-product-explanatory-color;
        text-align: center;
        background-color: $pdp-product-explanatory-bg-color;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 16px 12px 14px;
        }

        @include when-inside('.m-active-video') {
            position: absolute;
            opacity: 0;
            z-index: z('below');
        }
    }

    &-lounges-details-link {
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            padding-top: 29px;
        }
    }

    &-baggage {
        .alert-message {
            margin-bottom: 35px;
        }
    }

    .form-control,
    .btn,
    .btn-quaternary { //stylelint-disable-line
        border-radius: $pdp-product-element-border-radius;
    }

    .btn-large {
        border-radius: $pdp-product-element-border-radius-large;
    }

    .product-badge {
        @extend %product-badge-description;

        &-container {
            @extend %product-badge-description-container;
        }
    }

    &-pickup {
        @include media-breakpoint-up(md) {
            padding: 20px;
            background-color: $pdp-product-pickup-bg-color;
            border-radius: 0 0 16px 16px;
        }

        &-time {
            margin: 20px 0 16px;
            font-size: 12px;

            @include media-breakpoint-up(md) {
                margin: 33px 0 16px;
            }
        }
    }

    &-language {
        &-date {
            margin: 20px 0 16px;
            font-size: 12px;

            @include media-breakpoint-up(md) {
                margin: 33px 0 16px;
            }
        }
    }

    &-input-msg {
        display: flex;
        align-items: center;
        min-height: 34px;
        margin-top: 24px;
        font-size: 12px;
        color: $pdp-product-input-msg-color;
    }
}

@include media-breakpoint-down(sm) {
    .chauffeur-standalone-pdp {
        &.search-widget-quantity {
            margin-left: -27px;
        }

        .quantity {
            max-width: 25%;
            padding: 0 10px;

            .quantity-label {
                span {
                    line-height: 1.2;
                    display: block;
                }
            }
        }
    }
}