#loader-overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 999999;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.3);
        overflow-x: hidden;
        transition: 0.5s;
    }
    
    #loader-spinner {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin: -25px 0 0 -25px;
        border: 4px rgba(255, 254, 254, 0) solid;
        border-top: 4px $pink solid;
        border-bottom: 4px $pink solid;
        border-radius: 50%;
        -webkit-animation: spin1 1s infinite linear;
                animation: spin1 1s infinite linear;
      }
    
      .theme-aa {
            #loader-spinner {
                    border-top: 4px $green solid;
                    border-bottom: 4px $green solid;     
            }
      }
      
      @-webkit-keyframes spin1 {
        from {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(359deg);
                  transform: rotate(359deg);
        }
      }
      @keyframes spin1 {
        from {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(359deg);
                  transform: rotate(359deg);
          -webkit-transform: rotate(359deg);
                  transform: rotate(359deg);
        }
      }