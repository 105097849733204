$this: ".b-pdp_promo";

.b-pdp_promo {
    @include flex($horizontal: space-between, $vertical: center);

    background: $white;
    column-gap: 40px;
    row-gap: 24px;

    @include media (md) {
        flex-wrap: wrap;
    }

    @include media (sm) {
        flex-direction: column;
    }

    .product-price {
        justify-content: flex-end;

        @include media (sm) {
            justify-content: flex-start;
        }

        &-currency {
            &.m-through {
                color: $grey-4;
                margin-left: 5px;
            }
        }
    }

    &.m-fixed {
        box-shadow: 0 0 20px 0 rgba(0, 50, 125, 0.25);
        position: fixed;
        inset: auto 0 0;
        margin: 0;
        padding: 20px 12px;
        z-index: z('sticky');

        #{$this}-label {
            display: none;
        }

        #{$this}-prices-notification {
            @include media(md-down) {
                display: none;
            }
        }

        .b-pdp_total-copy {
            @include media(lg-up) {
                display: flex;
                max-width: 200px;
            }
        }
    }

    &-label {
        @include flex;

        border: 1px solid $blue;
        border-radius: $border-radius-small;
        color: $blue;
        font-family: $font-emirates;
        font-size: 16px;
        padding: 10px 16px;
        max-width: 500px;
        column-gap: 8px;
        line-height: 24px;

        .m-fixed & {
            @include media(md-down) {
                display: none;
            }
        }
    }

    &-icon {
        @include inline-svg($icon-callout);

        display: block;
        min-width: 24px;
    }

    &-prices {
        font-size: 16px;
        flex-shrink: 0;
        margin-left: auto;

        @include media (sm) {
            margin: 0 auto 0 0;
        }

        &-current {
            font-weight: bold;
        }

        &-old {
            color: $grey-4;
        }

        &-notification {
            color: $grey;
            font-size: 12px;
            margin: 0;
        }
    }

    &-actions {
        @include flex($horizontal: flex-end, $vertical: center);

        column-gap: 20px;
        flex-shrink: 0;
        text-align: right;
        row-gap: 10px;

        @include media(sm) {
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
        }

        .btn-primary {
            width: 100%;

            @include media(md-up) {
                width: 305px;
            }
        }

        .product-price-currency {
            font-size: 16px;
            color: $black;
        }
    }
}
