@mixin loader($border-width: 3px, $color: color('primary-1'), $size: 36px, $init-class-name: '.carousel-init', $is-nested-class: false) {
    @keyframes dual-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &::after {
        @include transition(opacity $transition-base-duration, visibility $transition-base-duration);

        animation: dual-ring 1.2s linear infinite;
        border: $border-width solid;
        border-color: $color transparent;
        border-radius: 50%;
        content: '';
        height: $size;
        left: calc(50% - #{$size*0.5});
        position: absolute;
        top: calc(50% - #{$size*0.5});
        width: $size;
        will-change: transform;
        z-index: z('default');
    }

    &::before {
        @include transition(opacity $transition-base-duration, visibility $transition-base-duration);

        background: $white;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: z('default');
    }

    @if ($is-nested-class) {
        &#{$init-class-name}::after,
        &#{$init-class-name}::before {
            opacity: 0;
            visibility: hidden;
        }
    } @else {
        #{$init-class-name} &::after,
        #{$init-class-name} &::before {
            opacity: 0;
            visibility: hidden;
        }
    }
}
