$tns-icon-arrow-left-disabled: $icon-arrow-left !default;
$tns-icon-arrow-left-hover: $icon-arrow-left-black !default;
$tns-icon-arrow-left: $icon-arrow-left-primary !default;
$tns-icon-arrow-right-disabled: $icon-arrow-right !default;
$tns-icon-arrow-right-hover: $icon-arrow-right-black !default;
$tns-icon-arrow-right: $icon-arrow-right-primary !default;
$tns-inner-container-gutters-md: 68px !default;
$tns-inner-container-gutters: 38px !default;
$tns-item-border-radius: 5px !default;
$tns-item-link-color: color('base-0') !default;
$tns-item-padding-left: 4px !default;
$tns-item-padding-left-md: 4px !default;
$tns-item-padding-right-md: 16px !default;
$tns-item-padding-right: 4px !default;
$tns-pdp-link-font-size: 18px !default;
$tns-product-main-price-color: #cac0b6 !default;
$tns-product-main-price-font-size: $body-font-size !default;
$tns-product-sales-price-color: #2d2423 !default;
$tns-product-sales-price-font-size: 18px !default;
$tns-product-carousel-loader-color: $black !default;
$tns-product-description-color: inherit !default;

.product-carousel {
    &:not(.m-pdx) {
        margin: 0 auto 50px;
        max-width: 396px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 70px;
            max-width: 794px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 1130px;
            min-height: 502px;
        }
    }

    &-title {
        margin-bottom: 20px;
        text-align: center;
    }

    &-content {
        @include loader($color: $tns-product-carousel-loader-color);

        position: relative;
    }

    &-item {
        display: none;

        &:first-child {
            display: inline-block;
        }
    }

    .tns-carousel {
        direction: ltr;
        display: block !important;
    }

    .tns-inner {
        margin: 0 ($tns-inner-container-gutters - $tns-item-padding-right) 0 $tns-inner-container-gutters !important;
        overflow: hidden;
        padding: 4px 0;

        @include media-breakpoint-up(md) {
            margin: 0 ($tns-inner-container-gutters-md - $tns-item-padding-right-md) 0 $tns-inner-container-gutters-md !important;
        }
    }

    .tns-item {
        padding: 0 $tns-item-padding-right 0 $tns-item-padding-left !important;

        @include media-breakpoint-up(md) {
            padding: 0 $tns-item-padding-right-md 0 $tns-item-padding-left-md !important;
        }
    }

    .tns-controls {
        @include tiny-carousel-controls {
            top: 40%;
        }

        &[style*="display: none"] + .tns-outer .tns-inner {
            direction: ltr;
        }
    }

    .product {
        border-radius: $tns-item-border-radius;
        box-shadow: 0 1px 4px 0 rgba(45, 36, 35, 0.1);
        overflow: hidden;
        text-align: center;
    }

    .image-container {
        margin-bottom: 20px;
    }

    .product-tile {
        position: relative;

        .image-container {
            height: auto;

            .theme-marhaba & {
                @include aspect_ratio(64.1);

                @include media-breakpoint-down(md) {
                    @include aspect_ratio(61);
                }
            }

            .theme-aa & {
                @include aspect_ratio(87.1);

                @include media-breakpoint-down(md) {
                    @include aspect_ratio(63.5);
                }
            }

            .theme-css & {
                @include aspect_ratio(63.5);
            }
        }
    }

    .product-tile-master-link-wrap {
        @include limit-text-lines($line-height: $line-height-base, $font-size: 14px, $max-lines: 1, $is-max-height: false);

        margin-bottom: 14px;
    }

    .product-tile-master-link {
        color: color('base-5');
    }

    .product-link-button {
        min-width: 145px;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 25px;
        vertical-align: middle;

        &.m-secondary {
            min-width: 154px;
        }
    }

    .tile-image {
        display: block;
        height: 202px;
        object-fit: cover;
        width: 100%;
    }

    .tile-body {
        padding: 0 10px;
    }

    .pdp-link {
        margin-bottom: 4px;

        .link {
            @include limit-text-lines($max-lines: 1);
            @include rtl-english-fix;

            color: $tns-item-link-color;
            display: inline-block;
            font-size: $tns-pdp-link-font-size;
            overflow: visible;
        }
    }

    .price {
        font-weight: $font-weight-medium;
        margin-bottom: 14px;

        .strike-through {
            color: $tns-product-main-price-color;
            font-size: $tns-product-main-price-font-size;
            margin-left: 10px;
            text-decoration: line-through;
        }

        .sales {
            font-size: $tns-product-sales-price-font-size;
        }
    }

    .ratings {
        margin-top: 0;
    }

    .product-description {
        @include limit-text-lines($font-size: $body-font-size, $max-lines: 1);

        padding: 0 18px;
        margin: 0 0 20px;
        color: $tns-product-description-color;

        &.m-secondary {
            @include limit-text-lines($font-size: $body-font-size, $max-lines: 4);
        }
    }
}
