.search-widget {
    &-hero {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            z-index: z('default');
            pointer-events: none;
        }
    }

    @include when-inside('.search-widget-hero') {
        pointer-events: auto;
    }
}
