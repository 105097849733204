$cart-promo-banner-color: $white !default;

.cart-promo-banner {
    color: $cart-promo-banner-color;
    display: block;
    margin: 0 15px 15px;
    padding: 15px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 36px 30px 42px;
        margin: 0 0 40px;
    }

    @include on-event {
        text-decoration: none;
    }

    &-image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: z('below');
    }

    &-title {
        color: $cart-promo-banner-color;
        margin-bottom: 10px;
    }

    &-description {
        color: $cart-promo-banner-color;
        font-weight: $font-weight-light;
        margin-bottom: 0;
    }
}

.checkout-cart-timer {
    background-color: #fff7cc;
    border: 1px solid #ffe766;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 5px 5px 5px 15px;
    font-weight: 500;
    display: flex;
    align-items: center;


    &:before {
        content: "";
        display: inline-block;
        background-image: url('../images/timer-svgrepo-com.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;     
        flex-shrink: 0;   
    }    

    .time {
        display: inline-block;
        min-width: 37px;
        text-align: center;
        color: $pink;
    }
}
