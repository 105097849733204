$error-notification-text-color: $white !default;
$error-notification-cookies-bg-color: color('secondary-2') !default;
$error-notification-js-bg-color: color('secondary-4') !default;

.error-notification {
    @include transition($transition-base);
    @include limit-text-lines($max-lines: 5, $is-max-height: true);

    color: $error-notification-text-color;
    text-align: center;

    &.m-cookies {
        background-color: $error-notification-cookies-bg-color;
    }

    &.m-javascript {
        background-color: $error-notification-js-bg-color;
    }

    &.hide {
        max-height: 0;
    }

    &-in {
        position: relative;
    }

    .content-asset {
        padding: 14px 22px 13px;
    }
}
