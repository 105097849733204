$thumbnail-images-carousel-active-dots-background-color: $black !default;
$thumbnail-images-carousel-active-item-border-color: $black !default;
$thumbnail-images-carousel-dots-background-color: $white !default;
$thumbnail-images-carousel-dots-size: 8px !default;
$thumbnail-images-carousel-gutters: 36px !default;
$thumbnail-images-carousel-gutters-lg: 28px !default;
$thumbnail-images-carousel-item-padding-right: 20px !default;
$thumbnail-images-carousel-item-padding-right-lg: 2px !default;
$thumbnail-images-carousel-item-padding-right-xl: 16px !default;
$thumbnail-images-carousel-video-size: 30px !default;
$thumbnail-images-carousel-loader-color: $black !default;

$modal-thumbnail-images-carousel-active-item-border-color: $pink !default;
$modal-thumbnail-images-carousel-background-color: $white !default;
$modal-thumbnail-images-carousel-border-radius: 10px !default;
$modal-thumbnail-images-carousel-gutters: 76px !default;
$modal-thumbnail-images-carousel-item-padding-right: 28px !default;
$modal-thumbnail-images-carousel-width: 620px !default;
$modal-thumbnail-images-carousel-width-md: 732px !default;

.thumbnail-images-carousel {
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-down(sm) {
        bottom: 14px;
    }

    @include media-breakpoint-up(md) {
        @include loader($color: $thumbnail-images-carousel-loader-color, $init-class-name: '.thumbnailcarousel-init', $is-nested-class: true);

        max-width: 616px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 402px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 100%;
    }

    @include when-inside('.m-active-video') {
        @include media-breakpoint-down(sm) {
            background: $black;
            bottom: 6px;
            padding: 8px;
        }
    }

    &-wrap {
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &-content {
        direction: ltr;
        list-style: none;
        margin: 0 auto;
        padding: 0 0 0 1px;

        @include media-breakpoint-down(sm) {
            // !important's are needed for the removing carousel styling
            transform: none !important;
            width: 100% !important;
        }
    }

    &-item {
        display: none;
        line-height: 0;

        @include media-breakpoint-down(sm) {
            border-radius: 50%;
            background: $thumbnail-images-carousel-dots-background-color;
            height: $thumbnail-images-carousel-dots-size;
            margin-right: 10px;
            padding: 0 !important;
            width: $thumbnail-images-carousel-dots-size !important;
        }

        &#{&} {
            &:only-child {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }

        &:first-child {
            display: inline-block;
        }

        &:last-child {
            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }

        &.m-active {
            @include media-breakpoint-down(sm) {
                background: $thumbnail-images-carousel-active-dots-background-color;
            }
        }

        img {
            border: 2px solid transparent;
            border-radius: 0;
            display: none;
            height: 92px;
            object-fit: cover;
            padding: 4px;
            width: 100%;

            @include media-breakpoint-up(md) {
                display: block;
            }

            @include media-breakpoint-only(lg) {
                height: 66px;
            }
        }

        // for the exception linter error and increase readability
        &.m-active img {
            border-color: $thumbnail-images-carousel-active-item-border-color;
        }
    }

    &-video-item-content {
        position: relative;

        &::before {
            @include media-breakpoint-up(md) {
                background: url("#{svg($icon-play-video)}") no-repeat center / 30px;
                content: '';
                height: $thumbnail-images-carousel-video-size;
                left: calc(50% - #{$thumbnail-images-carousel-video-size*0.5});
                position: absolute;
                top: calc(50% - #{$thumbnail-images-carousel-video-size*0.5});
                width: $thumbnail-images-carousel-video-size;
                z-index: z('above');
            }
        }

        &::after {
            @include media-breakpoint-up(md) {
                content: '';
                background-color: rgba($black, 0.4);
                bottom: 6px;
                left: 6px;
                position: absolute;
                right: 6px;
                top: 6px;
            }
        }
    }

    &-controls {
        display: none;

        @include tiny-carousel-controls {
            margin: 0 -5px;
        }

        @include when-inside('.thumbnailcarousel-init') {
            display: block;
        }
    }

    .tns-carousel {
        display: block !important;
    }

    .tns-outer {
        margin-bottom: 0;
    }

    .tns-inner {
        margin: 0 ($thumbnail-images-carousel-gutters - $thumbnail-images-carousel-item-padding-right) 0 $thumbnail-images-carousel-gutters !important;
        overflow: hidden;

        @include media-breakpoint-only(lg) {
            margin: 0 ($thumbnail-images-carousel-gutters-lg - $thumbnail-images-carousel-item-padding-right-lg) 0 $thumbnail-images-carousel-gutters-lg !important;
        }

        @include media-breakpoint-only(xl) {
            margin: 0 ($thumbnail-images-carousel-gutters-lg - $thumbnail-images-carousel-item-padding-right-xl) 0 $thumbnail-images-carousel-gutters-lg !important;
        }

        @include media-breakpoint-down(sm) {
            margin: 0;
            text-align: center;
        }
    }

    .tns-item {
        cursor: default;

        @include media-breakpoint-up(md) {
            padding: 0 $thumbnail-images-carousel-item-padding-right 0 0 !important;
        }

        @include media-breakpoint-only(lg) {
            padding-right: $thumbnail-images-carousel-item-padding-right-lg !important;
        }

        @include media-breakpoint-only(xl) {
            padding-right: $thumbnail-images-carousel-item-padding-right-xl !important;
        }

        img {
            cursor: pointer;
        }
    }
}

.thumbnail-images-carousel.m-modal {
    background-color: $modal-thumbnail-images-carousel-background-color;
    border-radius: 10px;
    bottom: 20px;
    left: calc(50% - #{$modal-thumbnail-images-carousel-width*0.5});
    max-width: initial;
    position: absolute;
    width: $modal-thumbnail-images-carousel-width;

    @include media-breakpoint-up(lg) {
        left: calc(50% - #{$modal-thumbnail-images-carousel-width-md*0.5});
        width: $modal-thumbnail-images-carousel-width-md;
    }

    @include when-inside('.m-active-video') {
        bottom: auto;
        top: calc(100% + 10px);
    }

    &::before,
    &::after {
        display: none;
    }

    .thumbnail-images-carousel {
        &-content {
            padding: 8px 0;

            @include media-breakpoint-up(lg) {
                padding: 14px 0;
            }
        }

        &-controls {
            @include tiny-carousel-controls($arrow-icon-gutters: 28px);

            display: block;
        }

        &-item img {
            height: 72px;

            @include media-breakpoint-up(lg) {
                height: 92px;
            }
        }

        &-item.m-active img {
            border-color: $modal-thumbnail-images-carousel-active-item-border-color;
        }
    }

    .tns-inner {
        margin: 0 ($modal-thumbnail-images-carousel-gutters - $modal-thumbnail-images-carousel-item-padding-right) 0 $modal-thumbnail-images-carousel-gutters !important;
    }

    .tns-item {
        @include media-breakpoint-up(md) {
            padding: 0 ($modal-thumbnail-images-carousel-item-padding-right - 1) 0 1px !important;
        }

        img { // stylelint-disable-line no-descending-specificity
            pointer-events: initial;
        }
    }
}
