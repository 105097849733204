$tooltip-danger-width: 272px !default;
$tooltip-danger-color: color('base-0') !default;
$tooltip-danger-bg: rgba(color('base-0'), 0.1) !default;
$tooltip-danger-border-color: rgba(color('base-0'), 0.75) !default;

.tooltip.m-danger {
    width: $tooltip-danger-width;
    padding: 15px;
    color: $tooltip-danger-color;
    background-color: $white;
    border: 1px solid $tooltip-danger-border-color;
    z-index: z('default');

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: z('below');
        background-color: $tooltip-danger-bg;
    }

    .tooltip {
        &-arrow {
            border-color: $tooltip-danger-border-color;

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border: 6px solid transparent;
            }
        }

        &-close {
            @include inline-svg-bg($icon-close-circle);

            top: 4px;
            right: 4px;
            width: 14px;
            height: 14px;
        }
    }

    &[x-placement^="top"] {
        margin-bottom: 10px;

        .tooltip-arrow {
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            bottom: -7px;

            &::before,
            &::after {
                border-bottom-width: 0;
                border-top-color: $white;
                top: -7px;
                left: -6px;
            }

            &::after {
                border-top-color: $tooltip-danger-bg;
            }
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 10px;

        .tooltip-arrow {
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            top: -7px;

            &::before,
            &::after {
                border-top-width: 0;
                border-bottom-color: $white;
                top: 1px;
                left: -6px;
            }

            &::after {
                border-bottom-color: $tooltip-danger-bg;
            }
        }
    }

    &[x-placement^="right"] {
        margin-left: 10px;

        .tooltip-arrow {
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
            left: -7px;

            &::before,
            &::after {
                border-left-width: 0;
                border-right-color: $white;
                top: -6px;
                left: 1px;
            }

            &::after {
                border-right-color: $tooltip-danger-bg;
            }
        }
    }

    &[x-placement^="left"] {
        margin-right: 10px;

        .tooltip-arrow {
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            right: -7px;

            &::before,
            &::after {
                border-right-width: 0;
                border-left-color: $white;
                top: -6px;
                left: -7px;
            }

            &::after {
                border-left-color: $tooltip-danger-bg;
            }
        }
    }
}
