.l-grid_layout {
    @include when-inside('.m-page_designer') {
        @include media(md-up) {
            max-width: 1280px;
        }

        &-item.m-sm_3 {
            @include media(md-down) {
                grid-column: span 6;
            }
        }

        &-item.m-sm_2 {
            @include media(md-down) {
                grid-column: span 3;
            }
        }

        &.m-background {
            background-color: initial !important;
            padding-top: 0;
            padding-bottom: 0;

            .l-grid_layout {
                &-content {
                    background-color: var(--bg-layout-color);
                    padding: 52px 12px;
                    border-radius: 16px;
                }
            }

            &.m-2_up {
                .l-grid_layout {
                    &-content {
                        @include media(md-down) {
                            padding: 32px 24px;
                            row-gap: 32px;
                        }
                    }
                }

                .b-text_block {
                    &-container {
                        @include media(md-down) {
                            padding: 0;
                        }
                    }
                }
            }

            &.m-brand-strip {
                &::after {
                    margin-left: 12px;
                    margin-right: 12px;
                }

                &.m-strip-bottom {
                    .l-grid_layout {
                        &-content {
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }

                &.m-strip-top {
                    .l-grid_layout {
                        &-content {
                            border-top-right-radius: 0;
                            border-top-left-radius: 0;
                        }
                    }
                }
            }
        }

        &.m-4_pack_wide {
            .b-banner {
                &-caption {
                    &.m-vertical_top {
                        @include media(md-down) {
                            padding: 32px;
                        }

                        h3 {
                            @include media(md-down) {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-only(xs) {
    .b-carousel_ext.shifted-mobile-carousel {
        .b-carousel-item.m-sm_6 {
            max-width: 75%;
        }
    }
}