$dropdown-bg:                       $white;
$dropdown-border-color:             color('base-2');
$dropdown-divider-bg:               color('base-2');
$dropdown-box-shadow:               0 1px 4px rgba($dark, 0.25);

$dropdown-link-color:               color('base-0');
$dropdown-link-hover-color:         color('base-0');
$dropdown-link-hover-bg:            color('base-2');

$dropdown-link-active-color:        color('base-0');
$dropdown-link-active-bg:           color('base-2');

$dropdown-link-disabled-color:      color('base-3');

$dropdown-header-color:             color('base-3');

$dropdown-toggle-hover-color:       color('base-0');
