$switcher-border-top-color: transparent !default;
$switcher-title-font-size: 16px !default;

.switcher {
    // padding: 15px 0;
    // background-color: $switcher-bg-color;
    // border-top: 1px solid $switcher-border-top-color;

    @include media-breakpoint-up(md) {
        // padding: 10px 0;
    }

    &-container {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
    }

    &-title {
        display: block;
        margin-bottom: 10px;
        font-size: $switcher-title-font-size;
        color: $switcher-title-color;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &-list {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 40px;
        }

        &-item {
            max-width: 94px;
            margin: 0 10px;
            flex-shrink: 0;

            @include media-breakpoint-up(md) {
                margin: 0 0 0 40px;
            }
        }

        &-pic {
            width: 100%;
            max-height: 45px;
        }
    }

    @each $brand in marhaba, aa, css {
        &-#{$brand} {
            @include when-inside('.theme-#{$brand}') {
                filter: grayscale(100%) opacity(30%);
                pointer-events: none;
            }
        }
    }
}
