.video {
    &-item {
        border-radius: $border-radius;

        @include media(md-up) {
            height: 500px;
        }

        & + & {
            margin-top: 80px;

            @include media(md-up) {
                margin-top: 140px;
            }
        }
    }
}

.b-product_images-main_view-item {
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.b-product_images-main__video {
    video {        
        max-height: 100vh;
        object-fit: contain;
    }
}

.main-image-carousel-element-wrapper {
    video {        
        object-fit: cover;
        width: 100%;
        height: 378px;

        @include media-breakpoint-up(md) {
            height: 616px;
        }
    }
}