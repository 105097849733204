$content-tile-title-font-size: 20px !default;
$content-tile-description-font-size: 16px !default;
$content-tile-title-color: color('base-0') !default;
$content-tile-description-color: color('base-4') !default;
$content-page-border-color: #f2f1f1 !default;

.content-tile {
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid $content-page-border-color;

    @include media-breakpoint-up(lg) {
        padding: 30px 0;
    }

    &-list {
        .content-tile:last-child {
            border-bottom: none;
        }

        &-left {
            @include media-breakpoint-up(lg) {
                padding-right: 20px;
            }

            @include media-breakpoint-up(xl) {
                padding-right: 0;
            }

            .content-tile:last-child {
                border-bottom: 1px solid $content-page-border-color;

                @include media-breakpoint-up(lg) {
                    border-bottom: none;
                }
            }
        }

        &-right {
            @include media-breakpoint-up(lg) {
                padding-left: 20px;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 0;
            }
        }
    }

    &-img {
        min-width: 108px;
        margin-right: 20px;
        flex: 0;

        @include media-breakpoint-up(xl) {
            min-width: 170px;
            margin-right: 34px;
        }
    }

    &-title {
        font-size: $content-tile-title-font-size;
        color: $content-tile-title-color;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }

    &-description {
        font-size: $content-tile-description-font-size;
        text-align: left;
        color: $content-tile-description-color;
    }
}
