// Tooltips
//
// Styleguide 9

// Tooltip
//
// .top - top placement
// .bottom - bottom placement
// .left - left placement
// .right - right placement
//
// Markup:
// <div class="position-relative" style="width: 300px;">
//     <button type="button" class="btn btn-block js-tooltip-{{modifier_class}}">button</button>
// </div>
// <script>
//     var referenceElement = $('.js-tooltip-{{modifier_class}}');
//     new Tooltip(referenceElement, {
//         placement: '{{modifier_class}}',
//         title: "To continue checkout process please fill required fields marker as *, if you have any questions please do not hesitate to use our Support Centre",
//         trigger: 'click',
//         closeOnClickOutside: true,
//     });
// </script>
//
// Styleguide 9.1

// Tooltip input
//
// Markup:
// <div class="position-relative">
//     <input class="form-control js-tooltip2" placeholder="tooltip">
// </div>
// <script>
//     var referenceElement2 = $('.js-tooltip2');
//     new Tooltip(referenceElement2, {
//         placement: 'bottom-start',
//         title: "Confirmation of your order will be sent to this email address",
//         trigger: 'click',
//         closeOnClickOutside: true,
//     });
// </script>
//
// Styleguide 9.2

// Tooltip Error
//
// .top - top placement
// .bottom - bottom placement
// .left - left placement
// .right - right placement
//
// Markup:
// <div class="position-relative" style="width: 300px;">
//     <button type="button" class="btn btn-block js-tooltip-danger-{{modifier_class}}">button</button>
// </div>
// <script>
//     var referenceElement3 = $('.js-tooltip-danger-{{modifier_class}}');
//     new Tooltip(referenceElement3, {
//         placement: '{{modifier_class}}',
//         title: "To continue checkout process please fill required fields marker as *, if you have any questions please do not hesitate to use our Support Centre",
//         trigger: 'click',
//         closeOnClickOutside: true,
//         template: `<div class="tooltip m-danger" role="tooltip"><div class="tooltip-arrow"></div><button class="tooltip-close" type="button"></button><div class="tooltip-inner"></div></div>`
//     });
// </script>
//
// Styleguide 9.3

$tooltip-primary-color: $white !default;
$tooltip-primary-bg-color: $black !default;
$tooltip-primary-border-color: $tooltip-primary-bg-color !default;
$tooltip-primary-border-radius: 5px !default;
$tooltip-primary-font-size: 13px !default;
$tooltip-primary-line-height: 1.3 !default;
$tooltip-primary-width: 300px !default;
// secondary
$tooltip-secondary-color: $dark !default;
$tooltip-secondary-bg-color: $white !default;
$tooltip-secondary-box-shadow-color: rgba($dark, 0.3) !default;
$tooltip-secondary-box-shadow: 0 0 4px $tooltip-secondary-box-shadow-color !default;
$tooltip-secondary-border-color: rgba($dark, 0.2) !default;
$tooltip-secondary-text-color: $grey-4 !default;

.tooltip {
    font-size: $tooltip-primary-font-size;
    line-height: $tooltip-primary-line-height;
    color: $tooltip-primary-color;
    background-color: $tooltip-primary-bg-color;
    border-radius: $tooltip-primary-border-radius;
    z-index: z('default');
    text-align: left;
    width: $tooltip-primary-width;

    &-inner {
        @include when-inside('.m-limited-tooltip') {
            @include limit-text-lines($line-height: 1.22, $font-size: 13px, $max-lines: 10, $is-max-height: true);
        }
    }

    &-arrow {
        border-color: $tooltip-primary-bg-color;
    }

    &-p {
        display: block;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-title {
        font-weight: $font-weight-medium;
        margin: 0 0 10px;

        &:last-child {
            margin: 0;
        }
    }

    &-text {
        margin: 0 0 15px;

        &:last-child {
            margin: 0;
        }
    }

    &-close {
        @include inline-svg-bg($icon-close-white);
        @include highlight;

        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border: none;
        cursor: pointer;
    }

    &.m-secondary {
        padding: 15px;
        color: $tooltip-secondary-color;
        background-color: $tooltip-secondary-bg-color;
        box-shadow: $tooltip-secondary-box-shadow;

        .tooltip {
            &-arrow {
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                }
            }

            &-close {
                @include inline-svg-bg($icon-close-dark);
            }

            &-text {
                color: $tooltip-secondary-text-color;
            }
        }

        &[x-placement^="top"] {
            .tooltip-arrow {
                border-top-color: $tooltip-secondary-border-color;
                bottom: -7px;

                &::before {
                    border-bottom-width: 0;
                    border-top-color: $tooltip-secondary-bg-color;
                    top: -7px;
                    left: -6px;
                }
            }
        }

        &[x-placement^="bottom"] {
            .tooltip-arrow {
                border-bottom-color: $tooltip-secondary-border-color;
                top: -7px;

                &::before {
                    border-top-width: 0;
                    border-bottom-color: $tooltip-secondary-bg-color;
                    top: 1px;
                    left: -6px;
                }
            }
        }

        &[x-placement^="right"] {
            .tooltip-arrow {
                border-right-color: $tooltip-secondary-border-color;
                left: -7px;

                &::before {
                    border-left-width: 0;
                    border-right-color: $tooltip-secondary-bg-color;
                    top: -6px;
                    left: 1px;
                }
            }
        }

        &[x-placement^="left"] {
            .tooltip-arrow {
                border-left-color: $tooltip-secondary-border-color;
                right: -7px;

                &::before {
                    border-right-width: 0;
                    border-left-color: $tooltip-secondary-bg-color;
                    top: -6px;
                    left: -7px;
                }
            }
        }
    }
}
