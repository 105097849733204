$this: '.b-tiers';

.b-tiers {
    &-list {
        list-style: none;
        padding-left: 0;
        max-width: 100%;
        min-width: 100%;
    }

    &-item {
        display: block !important;
        width: 100% !important;
        padding-right: 10px;

        @include when-inside('.b-pdp_aside-modal') {
            padding-right: 1px !important;
        }

        &-link {
            @include flex($vertical: flex-start);

            column-gap: 12px;
            color: $grey;
            padding: 12px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            margin-top: 12px;
            white-space: normal;
            position: relative;

            @include hover {
                text-decoration: none;
                color: $black;
            }

            &.selected {
                border: 2px solid $blue;

                #{$this}-item-circle {
                    border: 6px solid $blue;
                }
            }
        }

        .tile-highlight {
            background-color: #fef4e5;

            &::before {
                @include inline-svg-bg($icon-exclusive);

                content: "";
                height: 14px;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 14px;
                z-index: 1;
            }

            &::after {
                content: "";
                background-color: #ed2938;
                border-radius: 50%;
                height: 24px;
                position: absolute;
                right: 10px;
                top: 10px;
                width: 24px;
            }

            .b-tiers-item-title {
                padding-right: 25px;
            }

        }

        &-circle {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            display: block;
            border: 2px solid $black;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 50%;
        }

        &-title {
            display: inline-block;
            color: $black;
            font-family: $font-primary;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 6px;
            height: auto;
            text-align: left;
        }

        &-description {
            font-size: 12px;
            line-height: 16px;
            color: $grey-8;
            margin-bottom: 12px;
            font-weight: 400;
            height: auto;
            text-align: left;

            > * {
                font-weight: inherit;
            }

            .text-container {
                position: relative;
                max-height: 60px; /* Adjust according to the line-height */
                overflow: hidden;
                line-height: 15px; /* Line height for one row */
                position: relative;

                .read-more-btn {
                    display: inline-block;
                    cursor: pointer;
                    color: $green;
                    background: none;
                    border: none;
                    font-size: 1em;
                    text-decoration: underline;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 50%;
                    background-color: $white;
                    padding-left: 5px;
                }

                &.expanded {
                    max-height: none; /* Allow full height when expanded */

                    .read-more-btn {
                        position: relative;
                        width: initial;
                        background-color: initial;
                        padding-left: initial;
                    }
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0 0 10px;

                li {
                    margin: 0 0 3px;
                    padding: 0 0 0 26px;
                    position: relative;

                    &::before {
                        @include inline-svg($icon-bullet);

                        color: $dark;
                        content: '';
                        left: 0;
                        position: absolute;
                        top: 50%;
                        translate: 0 -65%;
                    }
                }
            }

            .img-wrap {
                display: flex;
                gap: 10px;

                img {
                    display: inline-block;
                    width: calc(33.33% - 10px);
                    max-width: 80px;
                    border-radius: 8px;
                }
            }
        }

        &.m-selected {
            #{$this}-item-link {
                border-color: $blue;
            }

            #{$this}-item-circle {
                border: 6px solid $blue;
            }
        }
    }

    &-item-price {
        @include flex;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        min-height: auto;

        .tier-tiles-carousel-currency {
            font-size: initial;
            line-height: initial;
            font-weight: 700;
            margin-right: 5px;
        }
    }
}
