$navigation-menu-link-color: color('base-0') !default;
$navigation-menu-link-font-size: 20px !default;
$navigation-menu-link-font-size-lg: 16px !default;

$navigation-main-title-font-size-xl: 32px !default;
$navigation-main-title-font-size: 28px !default;

$navigation-main-tiles-description-font-size-xl: 16px !default;
$navigation-main-tiles-description-font-size: 14px !default;
$navigation-main-tiles-description-margin-xl: 0 0 28px !default;
$navigation-main-tiles-description-margin: 0 0 28px !default;
$navigation-main-tiles-padding-xl: 14px ($grid-gutter-width * 0.5) !default;
$navigation-main-tiles-padding: 14px ($grid-gutter-width * 0.5) !default;
$navigation-main-tiles-title-margin-xl: 26px 0 6px !default;
$navigation-main-tiles-title-margin: 26px 0 12px !default;

$navigation-main-tiles-item-border-color: color('base-6') !default;
$navigation-main-tiles-item-border-radius-xl: 0 !default;
$navigation-main-tiles-item-border-radius: 0 !default;
$navigation-main-tiles-item-button-background-color: transparent !default;
$navigation-main-tiles-item-button-border-color: $button-bg-primary !default;
$navigation-main-tiles-item-button-border-radius: 6px !default;
$navigation-main-tiles-item-button-color: $button-bg-primary !default;
$navigation-main-tiles-item-hover-background: #fbfafa !default;
$navigation-main-tiles-item-hover-title-color: color('base-3') !default;
$navigation-main-tiles-item-icon-size: 40px !default;
$navigation-main-tiles-item-margin-xl: 0 !default;
$navigation-main-tiles-item-margin: 0 !default;
$navigation-main-tiles-item-padding-xl: 0 !default;
$navigation-main-tiles-item-padding: 0 !default;
$navigation-main-tiles-item-text-margin-xl: 0 0 28px !default;
$navigation-main-tiles-item-text-margin: 0 0 28px !default;
$navigation-main-tiles-item-text-padding-xl: 0 18px !default;
$navigation-main-tiles-item-text-padding: 0 !default;
$navigation-main-tiles-item-title-font-size-xl: 30px !default;
$navigation-main-tiles-item-title-font-size: 28px !default;

$navigation-footer-button-min-lg: 384px !default;
$navigation-footer-button-min: 0 !default;
$navigation-footer-bottom-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.1) !default;
$navigation-footer-text-font-size: 14px !default;

$navigation-footer-standard-background: rgba(246, 159, 13, 0.95) !default;
$navigation-footer-standard-padding-xl: 22px 0 28px !default;
$navigation-footer-standard-padding: 22px 0 28px !default;

$navigation-footer-bus-routes-background-color: rgba(250, 250, 250, 0.95) !default;
$navigation-footer-bus-routes-image-xl: 43px !default;
$navigation-footer-bus-routes-image: 34px !default;
$navigation-footer-bus-routes-item-margin-xl: 0 70px 0 0 !default;
$navigation-footer-bus-routes-item-margin: 0 30px 0 0 !default;
$navigation-footer-bus-routes-item-size-xl: 129px !default;
$navigation-footer-bus-routes-item-size: 100px !default;
$navigation-footer-bus-routes-padding-xl: 32px ($grid-gutter-width * 0.5) 36px !default;
$navigation-footer-bus-routes-padding: 14px ($grid-gutter-width * 0.5) 18px !default;
$navigation-footer-bus-routes-title-xl: 34px !default;
$navigation-footer-bus-routes-title: 28px !default;

$navigation-footer-left-align-button-padding-lg: 42px 0;
$navigation-footer-left-align-button-padding: 30px 0 16px;
$navigation-footer-left-align-button-background-color-lg: rgba(56, 47, 46, 0.95) !default;
$navigation-footer-left-align-button-background-color: transparent !default;
$navigation-footer-left-align-button-list-marker-lg: $icon-marker-secondary !default;
$navigation-footer-left-align-button-list-marker: $icon-marker-tertiary !default;
$navigation-footer-left-align-button-list-marker-item-line-height-lg: 1.7 !default;
$navigation-footer-left-align-button-list-marker-item-line-height: 1.9 !default;

.navigation {
    @include media-breakpoint-down(md) {
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: z('below');

        @include when-inside('.menu-opened') {
            @include navigation-overlay;

            pointer-events: auto;
            z-index: z('sticky');
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        width: 68%;

        @include when-inside('.m-sticky') {
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-around;
            padding-top: 5px;
            width: 80%;
        }
    }

    @include media-breakpoint-up(xl) {
        width: 960px;

        @include when-inside('.m-sticky') {
            width: 1060px;
        }
    }

    &-menu {
        @include media-breakpoint-down(md) {
            background: $white;
            left: -100%;
            position: absolute;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: z('default');

            @include when-inside('.menu-opened') {
                @include transition($transition-mobile-navigation);

                left: 0;
            }
        }

        @include media-breakpoint-only(sm) {
            overflow-y: scroll;

            @include when-inside('.submenu-opened') {
                overflow-y: hidden;
            }
        }

        @include media-breakpoint-only(md) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            &-item:first-child &-link {
                padding-left: 0;
            }

            &-item:last-child &-link {
                padding-right: 0;
            }
        }

        &-active-tab {
            > .dropdown-menu,
            + .dropdown-menu {
                display: block;
            }
        }

        &-in {
            @include media-breakpoint-down(md) {
                @include transition($transition-mobile-navigation);

                background: $white;
                left: 0;
                position: absolute;
                width: 100%;

                @include when-inside('.submenu-opened') {
                    left: -100%;
                }
            }

            @include media-breakpoint-only(sm) {
                height: calc(100% - 40px);
                overflow-x: hidden;
                overflow-y: scroll;

                @include when-inside('.submenu-opened') {
                    overflow: visible;
                }
            }
        }

        &-list {
            list-style: none;
            margin: 0;
            padding: 14px 0;

            @include media-breakpoint-up(lg) {
                display: flex;
                padding: 0;
            }
        }

        &-link {
            @include navigation-link($navigation-menu-link-color);

            @include media-breakpoint-down(md) {
                font-family: $font-secondary;
                font-size: $navigation-menu-link-font-size;
            }

            @include media-breakpoint-up(lg) {
                @include transition($transition-base);

                font-size: $navigation-menu-link-font-size-lg;
                font-weight: $font-weight-medium;
                padding: 0 14px;
                white-space: nowrap;

                @include when-inside('.m-sticky') {
                    font-weight: $font-weight-normal;
                    padding: 0 8px;
                }

                &.dropdown-toggle {
                    @include media-breakpoint-up(lg) {
                        &::after {
                            margin-left: 8px;

                            @include when-inside('.m-sticky') {
                                margin-left: 5px;
                            }
                        }
                    }
                }

                @include when-inside('.show') {
                    color: $link-hover-color;
                    text-decoration: none;

                    &::before {
                        content: '';
                        height: 35px;
                        position: absolute;
                        top: 100%;
                        width: 100%;

                        @include when-inside('.m-sticky') {
                            height: 30px;
                        }
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                font-size: $navigation-menu-link-font-size;

                @include when-inside('.m-sticky') {
                    font-size: $navigation-menu-link-font-size-lg;
                    padding: 0 25px;
                }
            }
        }

        &-backlink {
            @include navigation-backlink;
        }

        &-item {
            position: static;

            &-content {
                @include media-breakpoint-up(lg) {
                    @include border-radius(0);

                    background-color: transparent;
                    border: 0;
                    border-top: 1px solid $navigation-main-tiles-item-border-color;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: block;
                    max-height:0px;
                    overflow: hidden;
                    transition: max-height 0.4s linear;

                    &::after,
                    &::before {
                        display: none;
                    }
                }
                &.show {
                    max-height: 400px;
                }
            }

            &-wrap {
                background-color: $white;
            }
        }
    }

    &-main {
        &-tiles {
            @include media-breakpoint-up(lg) {
                padding: $navigation-main-tiles-padding;
            }

            @include media-breakpoint-up(xl) {
                padding: $navigation-main-tiles-padding-xl;
            }

            &-item {
                border-right: 1px solid $navigation-main-tiles-item-border-color;
                margin: $navigation-main-tiles-item-margin;
                padding: $navigation-main-tiles-item-padding;

                @include media-breakpoint-up(xl) {
                    margin: $navigation-main-tiles-item-margin-xl;
                    padding: $navigation-main-tiles-item-padding-xl;
                }

                &:last-child {
                    border-right: 0;
                }

                &-link {
                    @include navigation-submenu-link($navigation-menu-link-color);

                    @include media-breakpoint-up(lg) {
                        @include transition($transition-base);
                        @include border-radius($navigation-main-tiles-item-border-radius);

                        color: $black;
                        display: block;
                        padding: 26px ($grid-gutter-width * 0.5);
                        text-align: center;

                        @include hover {
                            background: $navigation-main-tiles-item-hover-background;
                            color: $black;
                            text-decoration: none;

                            .navigation-main-tiles-item-title {
                                color: $navigation-main-tiles-item-hover-title-color;
                            }

                            .navigation-main-tiles-item-button {
                                background: $navigation-main-tiles-item-button-background-color;
                                border: 2px solid $navigation-main-tiles-item-button-border-color;
                                color: $navigation-main-tiles-item-button-color;
                            }
                        }
                    }

                    @include media-breakpoint-up(xl) {
                        @include border-radius($navigation-main-tiles-item-border-radius-xl);
                    }
                }

                &-title {
                    @include limit-text-lines($line-height: 1, $font-size: 16px, $max-lines: 2, $is-max-height: true);

                    display: inline-block;

                    @include media-breakpoint-up(lg) {
                        @include limit-text-lines($line-height: $line-height-base, $font-size: $navigation-main-tiles-item-title-font-size, $max-lines: 2, $is-max-height: true);

                        color: $black;
                        display: block;
                        font-size: $navigation-main-tiles-item-title-font-size;
                        font-weight: $font-weight-medium;
                        margin-bottom: 10px;
                        text-decoration: none;
                    }

                    @include media-breakpoint-up(xl) {
                        @include limit-text-lines($line-height: $line-height-base, $font-size: $navigation-main-tiles-item-title-font-size-xl, $max-lines: 2, $is-max-height: true);

                        font-size: $navigation-main-tiles-item-title-font-size-xl;
                    }
                }

                &-icon,
                &-button {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: inline-block;
                    }
                }

                &-content,
                &-price {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }

                &-icon {
                    margin-bottom: 20px;
                    max-height: $navigation-main-tiles-item-icon-size;
                }

                &-content {
                    @include limit-text-lines($font-size: $navigation-main-tiles-description-font-size, $max-lines: 4);

                    font-size: $navigation-main-tiles-description-font-size;
                    margin: $navigation-main-tiles-item-text-margin;
                    padding: $navigation-main-tiles-item-text-padding;

                    @include media-breakpoint-up(xl) {
                        margin: $navigation-main-tiles-item-text-margin-xl;
                        padding: $navigation-main-tiles-item-text-padding-xl;
                    }
                }

                &-price {
                    @include limit-text-lines($max-lines: 2);

                    font-weight: $font-weight-medium;
                    margin: 0 0 6px;
                }

                &-button {
                    @include border-radius($navigation-main-tiles-item-button-border-radius);

                    cursor: pointer;
                    min-width: 134px;
                    padding: 7px 0;

                    @include media-breakpoint-up(xl) {
                        min-width: 170px;
                    }
                }
            }

            &-title {
                line-height: 1;
                margin: $navigation-main-tiles-title-margin;

                @include media-breakpoint-up(xl) {
                    margin: $navigation-main-tiles-title-margin-xl;
                }
            }

            &-description {
                color: color('base-4');
                font-size: $navigation-main-tiles-description-font-size;
                margin: $navigation-main-tiles-description-margin;

                @include media-breakpoint-up(xl) {
                    font-size: $navigation-main-tiles-description-font-size-xl;
                    margin: $navigation-main-tiles-description-margin-xl;
                }
            }

            &-content {
                @include limit-text-lines($max-lines: 10, $is-max-height: true);

                letter-spacing: $body-letter-spacing;
                margin: 0 0 26px;
            }
        }

        &-image {
            padding-bottom: 36px;
            position: relative;

            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            &-row {
                @include media-breakpoint-down(md) {
                    flex-flow: column-reverse;
                }
            }

            &-title {
                margin: 20px 0 16px;
            }

            &-picture-wrap {
                display: block;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;

                @include media-breakpoint-only(lg) {
                    margin-right: -20%;
                }

                @include media-breakpoint-down(md) {
                    @include make-col-ready;

                    position: static;
                }
            }

            &-picture {
                height: 100%;

                @include media-breakpoint-down(md) {
                    max-height: 120px;
                    object-fit: cover;
                    width: 100%;
                }
            }

            &-content-wrap {
                z-index: z('default');
            }

            &-content {
                @include limit-text-lines($max-lines: 10, $is-max-height: true);

                margin: 0 0 26px;

                @include media-breakpoint-up(xl) {
                    max-height: 220px;
                }
            }
        }

        &-link {
            display: inline-block;
            font-weight: $font-weight-medium;
        }

        &-title {
            font: $navigation-main-title-font-size $font-secondary;
            letter-spacing: $heading-letter-spacing;
            line-height: 1.2;

            @include media-breakpoint-up(xl) {
                font-size: $navigation-main-title-font-size-xl;
            }
        }
    }

    &-footer {
        &-standard {
            @include box-shadow($navigation-footer-bottom-shadow);

            background-color: $navigation-footer-standard-background;
            color: $white;
            display: none;
            padding: $navigation-footer-standard-padding;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            @include media-breakpoint-up(xl) {
                padding: $navigation-footer-standard-padding-xl;
            }

            &-title {
                font: 28px $font-secondary;
                line-height: 1;
                letter-spacing: $heading-letter-spacing;
                margin: 0 0 14px;
                padding: 0;
            }

            &-text {
                @include limit-text-lines($font-size: $navigation-footer-text-font-size, $max-lines: 2, $is-max-height: true);

                font-size: $navigation-footer-text-font-size;
                margin: 0;
            }

            &-button-wrap {
                align-self: center;
                text-align: right;
            }

            &-button {
                display: inline-block;
                margin-top: 4px;
                min-width: $navigation-footer-button-min-lg;
                padding: 10px;
                text-align: center;

                @include media-breakpoint-down(md) {
                    min-width: $navigation-footer-button-min;
                }
            }
        }

        &-bus-routes {
            align-items: center;
            display: flex;
            justify-content: space-between;

            &-wrap {
                @include box-shadow($navigation-footer-bottom-shadow);

                background-color: $navigation-footer-bus-routes-background-color;
                display: none;
                padding: $navigation-footer-bus-routes-padding;

                @include media-breakpoint-up(lg) {
                    display: block;
                }

                @include media-breakpoint-up(xl) {
                    padding: $navigation-footer-bus-routes-padding-xl;
                }
            }

            &-title {
                font: $navigation-footer-bus-routes-title $font-secondary;
                letter-spacing: $heading-letter-spacing;
                line-height: 1;

                @include media-breakpoint-up(xl) {
                    font-size: $navigation-footer-bus-routes-title-xl;
                }
            }

            &-list {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            &-item {
                align-items: center;
                background-color: $white;
                border-radius: 50%;
                display: flex;
                flex-flow: column;
                height: $navigation-footer-bus-routes-item-size;
                justify-content: center;
                margin: $navigation-footer-bus-routes-item-margin;
                text-align: center;
                width: $navigation-footer-bus-routes-item-size;

                @include media-breakpoint-up(xl) {
                    height: $navigation-footer-bus-routes-item-size-xl;
                    margin: $navigation-footer-bus-routes-item-margin-xl;
                    width: $navigation-footer-bus-routes-item-size-xl;
                }

                &:last-child {
                    margin-right: 0;
                }

                &-red {
                    @include header-navigation-bus-route-text-color($red);
                }

                &-blue {
                    @include header-navigation-bus-route-text-color($blue);
                }

                &-yellow {
                    @include header-navigation-bus-route-text-color($yellow);
                }

                &-pink {
                    @include header-navigation-bus-route-text-color($pink);
                }

                &-orange {
                    @include header-navigation-bus-route-text-color($orange);
                }
            }

            &-image {
                height: $navigation-footer-bus-routes-image;
                margin-bottom: 6px;

                @include media-breakpoint-up(xl) {
                    height: $navigation-footer-bus-routes-image-xl;
                }
            }

            &-description {
                @include limit-text-lines($font-size: $navigation-footer-text-font-size, $max-lines: 2, $is-max-height: true);

                font-size: $navigation-footer-text-font-size;
                margin: 0;
            }

            &-details {
                &-item {
                    @include border-radius(5px);

                    align-items: center;
                    border: 2px solid $white;
                    display: flex;
                    padding: 4px;

                    &:first-child {
                        margin-bottom: 10px;
                    }
                }

                &-image {
                    height: 42px;
                    margin-right: 12px;
                }

                &-content {
                    align-items: center;
                    display: flex;
                    line-height: 1;
                    margin: 0;
                }

                &-count {
                    font-size: 36px;
                    font-weight: $font-weight-bold;
                    color: $red;
                    margin-top: 4px;
                }

                &-description {
                    font-size: 16px;
                    color: $red;

                    &::before {
                        background-color: $white;
                        content: '';
                        display: inline-block;
                        height: 30px;
                        margin: 0 12px 0 10px;
                        vertical-align: middle;
                        width: 2px;
                    }
                }
            }
        }

        &-left-align-button {
            background-color: $navigation-footer-left-align-button-background-color;
            padding: $navigation-footer-left-align-button-padding;
            position: relative;

            @include media-breakpoint-up(lg) {
                background-color: $navigation-footer-left-align-button-background-color-lg;
                color: $white;
                padding: $navigation-footer-left-align-button-padding-lg;
            }

            &-logo {
                background: url("#{svg($icon-brand-logo)}") no-repeat;
                background-position: top left;
                height: 24px;
            }

            &-content-image {
                background-image: url('../images/icon-brand-content-image.svg');
                background-size: cover;
                height: 185px;
                left: ($grid-gutter-width * 0.5);
                top: 50px;
                position: absolute;
                width: 44vw;
            }

            &-list {
                margin-bottom: 20px;
            }

            &-submit {
                display: inline-block;
                min-width: $navigation-footer-button-min-lg;
                padding: 10px 30px;
                text-align: center;

                @include media-breakpoint-down(md) {
                    border-color: $link-color;
                    color: $link-color;
                    min-width: $navigation-footer-button-min;
                }

                @include on-event {
                    color: $black;

                    @include media-breakpoint-down(md) {
                        background-color: $link-color;
                        color: $white;
                    }
                }
            }

            .list-marker {
                @include limit-text-lines($line-height: $navigation-footer-left-align-button-list-marker-item-line-height, $font-size: $font-size-base, $max-lines: 10, $is-max-height: true);

                @include media-breakpoint-up(lg) {
                    @include limit-text-lines($line-height: $navigation-footer-left-align-button-list-marker-item-line-height-lg, $font-size: $font-size-base, $max-lines: 10, $is-max-height: true);
                }

                li {
                    line-height: $navigation-footer-left-align-button-list-marker-item-line-height;
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        line-height: $navigation-footer-left-align-button-list-marker-item-line-height-lg;
                    }

                    &::before {
                        @include inline-svg-bg($navigation-footer-left-align-button-list-marker-lg);

                        height: 14px;
                        width: 14px;

                        @include media-breakpoint-down(md) {
                            @include inline-svg-bg($navigation-footer-left-align-button-list-marker);

                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
}
