.b-refinement-navigation {
    .b-refinement-sublist {
        padding-left: 20px;

        .b-refinement-list_item {
            &:first-child {
                margin-top: 16px;
            }
        }
    }

    .b-refinement_link {
        &.m-selected {
            font-weight: 700;
        }
    }
}
