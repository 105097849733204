.b-pdp_recommend {
    margin-bottom: 30px;

    @include media(md-up) {
        margin-bottom: 70px;
    }

    h2 {
        text-align: left;
    }

    .product-carousel {
        height: auto;
        max-width: none;

        .tns {
            &-carousel {
                display: flex !important;
            }

            &-controls {
                position: absolute;
                right: 0;
                top: -82px;

                @include media(md-down) {
                    display: none;
                }

                button {
                    @include on-disabled($self: true) {
                        background-size: 50% !important;
                        border: 1px solid $blue;
                        border-radius: 50%;
                        box-sizing: content-box;
                        padding: 8px;
                        position: static;
                        margin-left: 16px;
                    }
                }
            }

            &-inner {
                margin: 0 !important;
            }

            &-item {
                > div {
                    height: 100%;
                }
            }
        }

        .image-container {
            border-radius: 0;

            &::before {
                display: none;
            }
        }

        .pdp-link {
            margin-bottom: 16px;
            font-family: $font-primary !important;
        }
    }

    .b-product_tile {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        flex-direction: column;
        overflow: hidden;
        height: 100%;

        .price {
            margin: 0;
        }

        &-body {
            flex-direction: column;
            padding: 0 16px 20px;
        }

        &-category {
            margin-bottom: 5px;
            order: -1;
        }

        &-actions {
            align-self: flex-start;
        }
    }

    .product-tile-badge {
        &-wrapper {
            margin-bottom: 20px;
        }

        &:nth-child(n + 2) {
            display: none;
        }
    }
}
