$sticky-header-logo-width-lg: 86px !default;
$sticky-header-logo-width-xl: 112px !default;

.header-logo {
    @include header-logo-widths($header-logo-widths);
    @include header-logo-heights($header-logo-heights);

    display: block;

    @include when-inside('.m-sticky') {
        @if ($theme-brand == 'css') {
            @include media-breakpoint-down(sm) {
                width: 165px;
                height: 24px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: $sticky-header-logo-width-lg;
            height: $sticky-header-logo-height-lg;
        }

        @include media-breakpoint-up(xl) {
            width: $sticky-header-logo-width-xl;
            height: $sticky-header-logo-height-xl;
        }
    }

    // CSS desktop sticky header logo
    .sticky-logo {
        display: none;

        @include when-inside('.m-sticky') {
            display: block;

            @include media-breakpoint-only(md) {
                display: none;
            }
        }
    }

    .hide-when-sticky {
        @include when-inside('.m-sticky') {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}
