h1,
h2,
h3 {
    .m-waved {
        color: $white;
        white-space: nowrap;

        > span {
            position: relative;
            display: inline-block;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
                width: 150%;
                height: 100%;
                border-radius: 50%;
                translate: -50% -50%;
                transform: skew(-20deg);
                background: var(--bg-cloud-color, $red);
            }

            > span {
                position: relative;
                z-index: z('above');
            }
        }
    }
}
