$circle-bg-1: color('primary-1') !default;
$circle-bg-2: #de684b !default;
$circle-bg-3: #f59a00 !default;
$circle-text-color: $white !default;
$circle-text-title-color: color('base-0') !default;
$circle-border-color: $white !default;
$service-overview-button-horizontal-padding: 68px !default;
$service-overview-image-height: 400px !default;
$service-overview-image-height-small: 300px !default;
$service-overview-margin-bottom: 50px !default;
$service-overview-margin-bottom-md: 70px !default;
$service-overview-description-max-width: 575px !default;

.service-overview {
    margin-bottom: $service-overview-margin-bottom;

    @include media-breakpoint-up(md) {
        margin-bottom: $service-overview-margin-bottom-md;
    }

    &-title {
        margin: 0 auto 40px;
        text-align: center;
    }

    &-image {
        object-fit: cover;
        width: 100%;

        @include media-breakpoint-up(lg) {
            height: $service-overview-image-height;
        }

        @include when-inside('.m-small') {
            @include media-breakpoint-up(md) {
                height: $service-overview-image-height-small;
            }
        }
    }

    &-content {
        background-color: $gray-100;
        padding: 35px 15px 30px;

        @include when-inside('.m-small') {
            padding: 20px 15px;
        }

        @include media-breakpoint-up(md) {
            margin: 0 auto;
            padding: 35px 60px 30px;

            @include when-inside('.m-small') {
                align-items: center;
                display: flex;
                padding: 20px 60px;
            }
        }

        @include media-breakpoint-up(lg) {
            align-items: center;
            display: flex;
            padding: 20px 60px;
        }

        @if ($theme-brand == 'css') {
            @include media-breakpoint-only(lg) {
                padding: 20px 16px;
            }
        }

        &.background-svg.m-overview-svg {
            &::before {
                @if ($theme-brand == 'marhaba') {
                    background-position: -40px 90px;
                    background-size: 900px;

                    @include media-breakpoint-up(md) {
                        background-position: -70px 120px;
                        background-size: 1100px;
                        transform: scaleX(-1);

                        @include when-inside('.m-small') {
                            transform: none;
                        }
                    }
                } @else if ($theme-brand == 'aa') {
                    @include background-svg($icon-aa-grey-logo, -55px -40px, 500px);

                    background-position: -55px -40px;
                    background-size: 500px;

                    @include media-breakpoint-up(md) {
                        background-position: 0 -226px;
                        background-size: 800px;
                    }

                    @include media-breakpoint-up(lg) {
                        background-position: 0 -190px;
                    }
                } @else if ($theme-brand == 'css') {
                    background-image: url('../images/icon-burj-al-arab.svg');
                    background-repeat: no-repeat;
                    background-position: 100% 5px;
                    background-size: 120px;

                    @include media-breakpoint-up(md) {
                        background-position: 104% 20px;
                        background-size: 200px;
                    }

                    @include media-breakpoint-up(lg) {
                        background-size: 215px;
                    }
                }
            }
        }
    }

    &-wrapper {
        @if ($theme-brand == "css") {
            @include media-breakpoint-up(md) {
                width: 70%;
                margin-left: 85px;

                @include when-inside('.m-small') {
                    max-width: 100%;
                }
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
                margin-left: 0;
            }
        } @else {
            margin: 0 auto;
            max-width: 478px;

            @include media-breakpoint-up(md) {
                max-width: 75%;

                @include when-inside('.m-small') {
                    max-width: 100%;
                }
            }

            @include media-breakpoint-up(lg) {
                margin: 0;
                max-width: 100%;
            }
        }
    }

    &-list {
        display: flex;
        justify-content: center;

        @if ($theme-brand == "css") {
            align-items: flex-start;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: flex-start;
            }
        } @else {
            align-items: center;
            margin-bottom: 35px;
        }

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }
    }

    &-item {
        text-align: center;
        position: relative;
        color: $circle-text-title-color;

        @if ($theme-brand == "css") {
            max-width: 250px;
            width: 100%;
            padding: 10px;
            margin-bottom: 25px;
            background-color: $white;
            box-shadow: 1px 1px 5px rgba($black, 0.2);
            border-radius: $border-radius;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(md) {
                min-width: 240px;
                margin-right: 30px;
            }

            @include media-breakpoint-only(lg) {
                min-width: 230px;
                margin-right: 10px;
            }

            &:last-child {
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }
            }
        } @else {
            margin-right: 20px;
            width: percentage(calc(1/3));
            margin-bottom: 35px;

            @include media-breakpoint-up(md) {
                margin-right: 70px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 110px;
                margin-right: 60px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @if ($theme-brand != "css") {
            &::after {
                content: '';
                position: absolute;
                border-radius: 50%;

                @if ($theme-brand == "aa") {
                    border: 1px solid $circle-border-color;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                } @else {
                    border: 6px solid $circle-border-color;
                    left: 1px;
                    top: 1px;
                    right: 1px;
                    bottom: 1px;
                }
            }

            &::before {
                content: '';
                border-radius: 50%;
                background-color: $circle-bg-1;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
            }

            &.m-overview-item-1 {
                &::before {
                    background-color: $circle-bg-1;
                }
            }

            &.m-overview-item-2 {
                &::before {
                    background-color: $circle-bg-2;
                }
            }

            &.m-overview-item-3 {
                &::before {
                    background-color: $circle-bg-3;
                }
            }
        } @else {
            &::before {
                content: '';
                display: inline-block;
                height: 44px;
                width: 44px;
                margin-right: 15px;
            }

            &.m-overview-item-1 {
                &::before {
                    @include inline-svg-bg($icon-bus-stops);
                }
            }

            &.m-overview-item-2 {
                &::before {
                    @include inline-svg-bg($icon-bus-tour-languages);
                }
            }
        }
    }

    &-item-inner {
        font-family: $font-primary;

        @if ($theme-brand != "css") {
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            color: $circle-text-color;
            position: absolute;
            top: 54%;
            left: 0;

            @if ($theme-brand == "aa") {
                font-weight: $font-weight-medium;
            }
        } @else {
            margin-bottom: 0;
            font-size: 44px;
            font-weight: $font-weight-bold;
            position: relative;
            padding-right: 30px;
            padding-top: 8px;
            line-height: 35px;

            &::after {
                content: '';
                border-right: 1px solid $circle-text-title-color;
                position: absolute;
                height: 32px;
                right: 15px;
                top: 5px;
            }
        }
    }

    &-item-title {
        @if ($theme-brand != "css") {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding-top: 25px;
            font-size: 15px;
            font-weight: $font-weight-medium;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                padding-top: 15px;
            }
        } @else {
            font-size: 18px;
        }
    }

    &-description {
        font-size: 16px;
        max-width: $service-overview-description-max-width;

        @include media-breakpoint-up(md) {
            font-size: 18px;

            @include when-inside('.m-small') {
                font-size: 16px;
            }
        }
    }

    &-extra {
        max-width: 500px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    &-button-wrapper {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        @if ($theme-brand == "css") {
            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
    }

    &-button {
        display: inline-block;
        margin: 30px auto 0;
        padding-left: $service-overview-button-horizontal-padding;
        padding-right: $service-overview-button-horizontal-padding;
        vertical-align: middle;
        width: auto;

        @include media-breakpoint-up(md) {
            margin: 15px auto 0;
        }

        @if ($theme-brand == "css") {
            @include media-breakpoint-up(md) {
                margin-left: 0;
            }
        }
    }
}
