.utility-navigation {
    @include media(lg-up) {
        @include flex($horizontal: space-between);

        margin-bottom: 0;
    }

    .m-sticky & {
        display: none;
    }

    &-item {
        .dropdown-currency-selector {
            @include media(lg-up) {
                bottom: 100%;
                top: auto;
            }

            @include media(lg-down) {
                left: 0;
                width: 200px;
            }
        }
    }
}
