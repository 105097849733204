// TODO: rename mixin
@mixin text-alignment($horizontal: true, $vertical: true) {
    position: absolute;

    // How to use: @include text-alignment;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    // How to use: @include text-alignment(true, false);
    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }

    // How to use: @include text-alignment(false, true);
    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

// Another way to center any element that has width and height
// $margin: auto by default
@mixin block-alignment-secondary(
    $horizontal: true,
    $vertical: true,
    $margin: auto,
    $top: 0,
    $right: 0,
    $left: 0,
    $bottom: 0) {
    position: absolute;
    @if ($top) {
        top: $top;
    }
    @if ($right) {
        right: $right;
    }
    @if ($left) {
        left: $left;
    }
    @if ($bottom) {
        bottom: $bottom;
    }

    // How to use (element centered): @include block-alignment-secondary;
    @if ($horizontal and $vertical) or not ($horizontal) and not ($vertical) {
        margin: $margin;
    }

    // How to use: @include block-alignment-secondary(false, true);
    @else if not ($horizontal) {
        margin: auto 0;
    }

    // How to use: @include block-alignment-secondary(true, false);
    @else if not ($vertical) {
        margin: 0 auto;
    }
    // How to use (custom aligment): @include block-alignment-secondary($margin: 0 0 0 auto);
}

// Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text#{$infix}-justify {
            text-align: justify !important;
        }
    }
}
