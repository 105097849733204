@mixin flex($horizontal: flex-start, $vertical: normal, $wrap: nowrap, $direction: row) {
    display: flex;

    @if $vertical != normal {
        align-items: $vertical;
    }

    @if $horizontal != flex-start {
        justify-content: $horizontal;
    }

    @if $wrap != nowrap {
        flex-wrap: $wrap;
    }

    @if $direction != row {
        flex-direction: $direction;
    }
}
