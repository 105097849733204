.secure-block {
    display: inline-block;
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 1;
    text-transform: uppercase;
    color: #766a65;

    @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
    }

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    &-wrapper.m-divider {
        @include media-breakpoint-up(md) {
            border-left: 1px solid #d4d2d0;
            display: inline-block;
            padding: 0 0 0 20px;
            margin: 0 0 0 20px;
            vertical-align: middle;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 0 0 50px;
            margin: 0 0 0 50px;
        }
    }

    &::before {
        @include inline-svg-bg($icon-secure-secondary);

        content: '';
        display: inline-block;
        width: 16px;
        height: 20px;
        margin-right: 10px;
    }
}
