$subtitle-color: inherit !default;
$service-locations-font-size-md: 18px !default;
$service-locations-font-size: 16px !default;
$service-locations-item-font-weight: $font-weight-medium !default;
$service-locations-item-margin-bottom: 25px !default;

.service-locations {
    margin-bottom: 60px;

    &-title {
        text-align: center;
        margin-bottom: 40px;
    }

    &-content {
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            width: 80%;
            max-width: 930px;
        }

        @include media-breakpoint-up(lg) {
            width: 75%;
        }
    }

    &-subtitle {
        @if ($theme-brand == "aa") {
            color: $subtitle-color;
            margin-bottom: 15px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }
        }
    }

    &-list {
        padding-left: 15px;
    }

    &-description {
        font-size: $service-locations-font-size;

        @include media-breakpoint-up(md) {
            font-size: $service-locations-font-size-md;
        }

        @if ($theme-brand == "aa") {
            margin-bottom: 30px;
        }

        @if ($theme-brand == "css") {
            margin-top: 15px;
        }
    }

    &-action {
        margin: 40px auto 60px;
        text-align: center;
    }

    &-action-link {
        max-width: 160px;
        margin: 0 auto;
    }

    li {
        font-size: $service-locations-font-size;
        font-weight: $service-locations-item-font-weight;
        margin-bottom: $service-locations-item-margin-bottom;

        @include media-breakpoint-up(md) {
            font-size: $service-locations-font-size-md;
        }
    }
}
