// List of classes [[.tingle-modal--overflow, .tingle-modal-box__content, .tingle-modal__close, .tingle-modal__closeIcon, .tingle-modal__closeLabel]] are become from modal plugin

$tingle-modal-m-small-max-width: 470px !default;
$tingle-modal-m-medium-max-width: 708px !default;
$tingle-modal-m-large-max-width: 1120px !default;
$tingle-modal-overflow-padding-top-md: 30px !default;
$tingle-modal-box-border-radius: 0 !default;
$tingle-modal-box-content-padding: 30px !default;
$tingle-modal-close-icon: $icon-close !default;
$tingle-modal-close-top: 30px !default;
$tingle-modal-close-right: 30px !default;
$tingle-modal-close-size: 16px !default;

.tingle-modal {
    padding: 0;

    &.checkout-dialog {
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(0);

        @include media-breakpoint-down(sm) {
            > .tingle-modal-box {
                max-width: 94% !important;
                top: 50%;
                transform: translateY(-50%);
            }            
        }

        .modal-header {
            border-bottom: none;
        }

        .send-email-btn {
            > div.text-center {
                display: none;
            }
        }
    }

    &--overflow {
        padding-top: 0;

        @include media-breakpoint-up(md) {
            padding-top: $tingle-modal-overflow-padding-top-md;
        }
    }

    &-box {
        width: auto;
        max-width: 100%;
        margin: auto;
        border-radius: $tingle-modal-box-border-radius;

        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

                @include when-inside ('.tingle-modal.m#{$infix}-small') {
                    max-width: $tingle-modal-m-small-max-width;
                    width: 100%;
                }

                @include when-inside ('.tingle-modal.m#{$infix}-medium') {
                    max-width: $tingle-modal-m-medium-max-width;
                    width: 100%;
                }

                @include when-inside ('.tingle-modal.m#{$infix}-large') {
                    max-width: $tingle-modal-m-large-max-width;
                    width: 100%;
                }
            }
        }

        &__content {
            padding: $tingle-modal-box-content-padding;
        }
    }

    &__close {
        @include inline-svg-bg($tingle-modal-close-icon);
        @include highlight;

        left: auto;
        top: $tingle-modal-close-top;
        right: $tingle-modal-close-right;
        width: $tingle-modal-close-size;
        height: $tingle-modal-close-size;
    }

    &__closeIcon,
    &__closeLabel {
        display: none;
    }

    &.m-secondary {
        .tingle-modal {
            &-box__content {
                @include media-breakpoint-down(sm) {
                    padding: 15px;
                }
            }

            &__close {
                @include media-breakpoint-down(sm) {
                    top: 20px;
                    right: 15px;
                }
            }
        }
    }

    &.m-blank {
        .tingle-modal-box__content {
            padding: 0;
        }
    }
}
