// Body
$content-hero-body-max-width: 300px;
$content-hero-body-max-width-md: none;
$content-hero-body-margin: 0 auto;
$content-hero-body-margin-md: 0;
$content-hero-body-font-weight: $font-weight-medium;

// Title
$content-hero-title-font-size: 44px;
$content-hero-title-font-size-md: 56px;
$content-hero-title-font-size-lg: 60px;
$content-hero-title-font-size-xl: 80px;
$content-hero-title-color: $white;
$content-hero-title-line-height: 1.1;
$content-hero-title-margin: 0 0 20px;

// Description
$content-hero-description-font-size: 24px;
$content-hero-description-font-size-md: 32px;
$content-hero-description-font-size-lg: 32px;
$content-hero-description-font-size-xl: 36px;
$content-hero-description-line-height: 1.3;
$content-hero-description-line-height-md: 1.2;
$content-hero-description-color: $blue;
$content-hero-description-margin: 0 0 25px;
$content-hero-description-margin-md: 0 0 30px;
$content-hero-description-margin-lg: 0 0 25px;

// Button
$content-hero-btn-min-width-lg: 260px;

// Copy
$content-hero-copy-margin-md: 0 0 35px;
