$content-page-width: 1196px !default;
$content-page-sidebar-bg: #f2f1f1 !default;
$content-page-sidebar-bg-secondary: $white !default;
$content-page-sidebar-active-bg: #b00837 !default;
$content-page-table-border: 1px solid #d4d2d0 !default;
$content-page-sidebar-title: #2d2423 !default;
$content-page-sidebar-title-font-size: 24px !default;
$content-page-description-font-size: 18px !default;
$content-page-text-font-size: 16px !default;
$content-page-sidebar-active-font-size: 20px !default;
$content-page-sidebar-active-link-color: #eeaf00 !default;
$content-page-sidebar-content-font-size: 14px !default;
$content-page-subtitle-font-size: 28px !default;
$content-page-box-shadow: 0 1px 4px 0 rgba(#2d2423, 0.1) !default;
$content-page-sidebar-width: 342px;
$content-page-content-margin: 50px;
$content-page-where-to-find-banner-font-size: 50px !default;
$content-page-where-to-find-content-margin: 24px !default;
$content-page-why-choose-us-page-border: 1px solid #ebebeb;
$content-page-ourpeople-photo-square-size: 166px !default;
$content-page-ourpeople-quot-font-size: 22px !default;
$content-page-ourpeople-name-title-font-size: 14px !default;
$content-page-ourpeople-photo-margin: 77px !default;
$content-page-ourpeople-name-color: #de422d !default;
$content-page-ourpeople-name-subtitle-color: #9f9593 !default;
$content-page-border-color: #f2f1f1 !default;

.content-page {
    padding: 50px 0 60px;
    margin: 0 auto;
    font-size: $content-page-text-font-size;

    &.m-secondary {
        padding-top: 0;
    }

    @include media-breakpoint-up(xl) {
        text-align: justify;
    }

    &-content {
        margin-bottom: 25px;

        @include when-inside('.m-secondary') {
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: calc(100% - #{$content-page-sidebar-width + $content-page-content-margin});
            margin-right: $content-page-content-margin;
            margin-bottom: 50px;
        }
    }

    &-title {
        margin-bottom: 40px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    &-subtitle {
        font-size: $content-page-subtitle-font-size;
        color: #2d2423;
        margin: 30px 0;
    }

    &-wrapper {
        position: relative;

        @include media-breakpoint-up(lg) {
            max-width: $content-page-width;
        }
    }

    &-wrapper-inner {
        display: flex;
        flex-wrap: wrap;
    }

    &-description {
        font-size: $content-page-description-font-size;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 30px;

        p {
            margin-bottom: 8px;
        }
    }

    &-text {
        font-size: $content-page-text-font-size;
        text-align: left;
        margin-bottom: 10px;
    }

    &-table-wrapper {
        margin-top: 50px;
        padding-bottom: 20px;

        &.box-scrollbar.m-horizontal {
            @include media-breakpoint-up(md) {
                overflow: hidden;
            }
        }
    }

    &-table {
        border: $content-page-table-border;
        table-layout: fixed;
        border-collapse: collapse;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        th,
        td {
            border: $content-page-table-border;
            padding: 20px;
            vertical-align: top;
        }

        th {
            font-size: $content-page-text-font-size;

            @include media-breakpoint-down(sm) {
                min-width: 80vw;
            }

            @include media-breakpoint-up(lg) {
                min-width: 120px;
            }
        }
    }

    &-block {
        margin-bottom: 60px;
    }

    &-side-content {
        padding: 20px;

        @include media-breakpoint-up(xl) {
            padding: 60px;
        }
    }

    &-sidebar {
        @include media-breakpoint-up(sm) {
            max-width: $content-page-sidebar-width;
        }

        @include media-breakpoint-up(lg) {
            margin-left: auto;
        }
    }

    &-sidebar-wrapper {
        padding: 35px;
        background-color: $content-page-sidebar-bg;
        border-radius: $border-radius;
        box-shadow: 0 1px 4px 0 rgba(45, 36, 35, 0.15);

        &.m-secondary {
            background-color: $content-page-sidebar-bg-secondary;
        }
    }

    &-sidebar-title {
        color: $content-page-sidebar-title;
        font-size: $content-page-sidebar-title-font-size;
        font-family: $font-secondary;
        margin-bottom: 25px;
    }

    &-sidebar-description {
        font-size: $content-page-text-font-size;
    }

    &-sidebar-image {
        display: block;
        margin: 0 auto;
        max-width: 236px;
    }

    &-sidebar-link {
        display: block;
        font-weight: $font-weight-medium;

        &::before {
            content: '';
            width: 21px;
            height: 21px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 11px;
        }

        &.content-page-sidebar-phone {
            margin-bottom: 20px;
            font-size: $content-page-text-font-size;

            &::before {
                @include inline-svg-bg($icon-phone-support);
            }
        }

        &.content-page-sidebar-mail {
            text-decoration: underline;

            &::before {
                @include inline-svg-bg($icon-email-support);
            }
        }
    }

    &-sidebar-border {
        border-color: color('base-3');
    }

    &-sidebar-content {
        font-size: $content-page-sidebar-content-font-size;
    }

    &-sidebar-content-title {
        font-weight: $font-weight-medium;
    }

    &-sidebar-block {
        margin-bottom: 30px;
    }

    &-sidebar-active {
        background-color: $content-page-sidebar-active-bg;

        .content-page-sidebar-title {
            color: $white;
        }

        .content-page-sidebar-description {
            color: $white;
        }

        .content-page-sidebar-link {
            font-size: $content-page-sidebar-active-font-size;
            color: $content-page-sidebar-active-link-color;
        }

        .content-page-sidebar-link.content-page-sidebar-phone {
            &::before {
                @include inline-svg-bg($icon-phone-support-white);
            }
        }
    }

    .list-unordered.m-nobullets {
        padding-bottom: 0;

        li {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }

    /* stylelint-disable */
    &.content-page-legal,
    &.content-page-choose-us {
        .content-page-content {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }

    &.content-page-choose-us {
        padding-bottom: 0;
        border-bottom: $content-page-why-choose-us-page-border;
    }

    &.content-page-choose-us,
    &.content-page-find-us {
        .content-page-title {
            text-align: center;
        }

        .content-page-wrapper-inner {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    &.content-page-choose-us {
        .content-page-banner-wrapper {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            width: auto;

            &.m-position-top {
                top: 20px;
            }

            &.m-position-bottom {
                bottom: 20px;
            }

            &.m-position-left {
                left: 20px;
            }

            &.m-position-right {
                right: 20px;
            }

            img {
                &:first-child {
                    margin-right: 2px;
                }
            }
        }

        .content-page-block,
        .content-page-description {
            margin-bottom: 0;
        }

        .content-page-description {
            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
            }
        }

        .content-page-banner {
            height: 220px;

            @include media-breakpoint-up(sm) {
                height: 380px;
            }

            @include media-breakpoint-up(md) {
                height: 400px;
            }
        }

        .content-page-block {
            .content-page-banner {
                background-position: 50% 0;
                background-size: cover;
                background-repeat: no-repeat;
            }

            img {
                max-height: 60px;

                @include media-breakpoint-up(sm) {
                    max-height: 100%;
                }
            }
        }
    }

    &.content-page-find-us {
        .content-top-banner.m-content-banner {
            height: 240px;
            margin-bottom: 40px;

            .content-top-banner-wrapper {
                width: 80%;
            }

            .content-top-banner-title {
                font-size: $content-page-where-to-find-banner-font-size;
            }
        }

        .content-page-content {
            @include media-breakpoint-up(lg) {
                width: calc(100% - #{$content-page-sidebar-width + $content-page-where-to-find-content-margin});
                margin-right: $content-page-where-to-find-content-margin;
                margin-bottom: 0;
            }
        }

        .content-page-block {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.content-page-rugby {
        .m-text-highlighted {
            color: $link-color;
        }

        .content-page-sidebar {
            @include media-breakpoint-down(md) {
                margin: 0 auto;
                max-width: 100%;
                width: 100%;
            }
        }

        .content-page-table td {
            font-size: $body-font-size;
            padding: 2px 8px;
            vertical-align: middle;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
        }

        .card:last-of-type {
            margin-bottom: 0;
        }

        .tabs {
            &-nav-item {
                font-size: 14px;
            }

            &-content-container {
                margin: 0;
                padding: 0;
            }

            &-content {
                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }

        .accordion {
            &-container {
                padding: 20px 0;
            }

            &-icon {
                @include inline-svg-bg($icon-plus);

                height: 12px;
                flex: 0 0 12px;
                position: absolute;
                right: 30px;
                top: calc(50% - 6px);
                width: 12px;
            }

            &-control {
                background-color: color('base-1');
                color: color('base-4');
                font-size: 18px;
                font-weight: $font-weight-medium;
                justify-content: center;
                padding: 12px 50px;

                &.m-accordion-expanded {
                    background-color: $white;
                    border-bottom: 1px solid $global-border-color;
                    color: color('primary-3');

                    .accordion-icon {
                        @include inline-svg-bg($icon-minus);
                    }
                }
            }
        }
    }

    .content-page-rugby {
        &-carousel {
            position: relative;
        }

        &-carousel-item {
            display: none;
            line-height: 0;
            width: 100%;

            &:first-child {
                display: inline-block;
            }
        }

        &-brand-image {
            bottom: 5px;
            left: 5px;
            max-width: 150px;
            position: absolute;
            z-index: z('default');

            @include media-breakpoint-up(md) {
                max-width: 200px;
            }
        }
    }

    .content-page-ourpeople {
        border-bottom: 1px solid $content-page-border-color;
        padding-bottom: 40px;
        margin-top: 40px;

        &:last-child {
            border-bottom: 0;
        }
    }

    .content-page-ourpeople-photo {
        width: $content-page-ourpeople-photo-square-size;
        height: $content-page-ourpeople-photo-square-size;
        margin: 25px auto 30px;

        @include media-breakpoint-up(md) {
            float: left;
            margin-right: $content-page-ourpeople-photo-margin;
            margin-bottom: 0;
        }
    }

    .content-page-ourpeople-description {
        width: 85%;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            width: calc(100% - #{$content-page-ourpeople-photo-square-size + $content-page-ourpeople-photo-margin});
            margin: 0 0 0 auto;
        }
    }

    .content-page-ourpeople-title {
        position: relative;
        font-size: $content-page-ourpeople-quot-font-size;
        padding: 20px 0;

        &::before {
            @include inline-svg-bg($icon-quote-sign);

            content: '';
            position: absolute;
            top: 0;
            left: -30px;
            width: 100px;
            height: 70px;
        }
    }

    .content-page-ourpeople-name {
        color: $content-page-ourpeople-name-color;

        span {
            display: block;
            font-size: $content-page-ourpeople-name-title-font-size;
            color: $content-page-ourpeople-name-subtitle-color;
        }
    }
    /* stylelint-enable */
}
