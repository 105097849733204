$circle-tiles-list-font-size: 14px !default;
$circle-tiles-list-font-size-md: 16px !default;

$circle-tiles-list-item-title-font-size-xl: 30px !default;
$circle-tiles-list-item-title-font-size: 28px !default;
$circle-tiles-list-item-hover-background: #fbfafa !default;
$circle-tiles-list-item-image-size-xl: 130px !default;
$circle-tiles-list-item-image-size: 80px !default;
$circle-tiles-list-item-mobile-link-color: color('base-0') !default;
$circle-tiles-list-item-secondary-color: color('base-0') !default;
$circle-tiles-list-item-price-color: color('base-0') !default;
$circle-tiles-list-item-price-hover-color: color('base-0') !default;
$circle-tiles-list-item-price-background: #f7f7f7 !default;
$circle-tiles-list-item-price-font-size: 13px !default;
$circle-tiles-list-item-price-font-size-md: $font-size-base !default;
$circle-tiles-list-item-price-padding: 0 5px !default;
$circle-tiles-list-item-price-margin: 20px 0 0 !default;

// Secondary
$circle-tiles-list-secondary-item-image-size: 125px !default;
$circle-tiles-list-secondary-item-image-size-md: 180px !default;
$circle-tiles-list-secondary-item-image-size-lg: 115px !default;
$circle-tiles-list-secondary-item-image-size-xl: 170px !default;
$circle-tiles-list-secondary-text-font-size: 16px !default;
$circle-tiles-list-secondary-text-font-size-md: 18px !default;
$circle-tiles-list-secondary-text-font-size-lg: 16px !default;
$circle-tiles-list-secondary-text-font-size-xl: 18px !default;

$circle-tiles-list-menu-link-color: color('base-0') !default;
$circle-tiles-list-menu-link-font-size: 20px !default;
$circle-tiles-list-menu-link-font-size-lg: 16px !default;

$circle-tiles-list-main-title-font-size-xl: 52px !default;
$circle-tiles-list-main-title-font-size: 48px !default;

$circle-tiles-list-main-tiles-price-font-size: 18px !default;
$circle-tiles-list-main-tiles-price-line-height: 1.2 !default;
$circle-tiles-list-main-tiles-description-font-size-xl: 16px !default;
$circle-tiles-list-main-tiles-description-font-size: 14px !default;
$circle-tiles-list-main-tiles-description-margin-xl: 0 0 20px !default;
$circle-tiles-list-main-tiles-description-margin: 0 0 20px !default;
$circle-tiles-list-main-tiles-padding-xl: 14px ($grid-gutter-width * 0.5) !default;
$circle-tiles-list-main-tiles-padding: 14px ($grid-gutter-width * 0.5) !default;
$circle-tiles-list-main-tiles-title-margin-xl: 26px 0 6px !default;
$circle-tiles-list-main-tiles-title-margin: 26px 0 12px !default;

$circle-tiles-list-main-tiles-item-border-color: color('base-6') !default;
$circle-tiles-list-main-tiles-item-border-radius-xl: 0 !default;
$circle-tiles-list-main-tiles-item-border-radius: 0 !default;
$circle-tiles-list-main-tiles-item-button-background-color: transparent !default;
$circle-tiles-list-main-tiles-item-button-border-color: $button-bg-primary !default;
$circle-tiles-list-main-tiles-item-button-border-radius: 6px !default;
$circle-tiles-list-main-tiles-item-button-color: $button-bg-primary !default;
$circle-tiles-list-main-tiles-item-hover-background: #fbfafa !default;
$circle-tiles-list-main-tiles-item-hover-title-color: color('base-3') !default;
$circle-tiles-list-main-tiles-item-icon-size: 45px !default;
$circle-tiles-list-main-tiles-item-margin-xl: 0 !default;
$circle-tiles-list-main-tiles-item-margin: 0 !default;
$circle-tiles-list-main-tiles-item-padding-xl: 0 !default;
$circle-tiles-list-main-tiles-item-padding: 0 !default;
$circle-tiles-list-main-tiles-item-text-margin-xl: 0 0 20px !default;
$circle-tiles-list-main-tiles-item-text-margin: 0 0 28px !default;
$circle-tiles-list-main-tiles-item-text-padding-xl: 0 18px !default;
$circle-tiles-list-main-tiles-item-text-padding: 0 !default;
$circle-tiles-list-main-tiles-item-title-font-size-xl: 30px !default;
$circle-tiles-list-main-tiles-item-title-font-size: 28px !default;

.circle-tiles-list {
    font-size: $circle-tiles-list-font-size;

    @include media-breakpoint-up(md) {
        font-size: $circle-tiles-list-font-size-md;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 12px;
        padding-top: 10px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 12px;
    }

    &-title {
        margin-top: 4px;
    }

    &-subtitle {
        font-family: $font-primary;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }
    }

    &-content {
        margin: 0 0 26px;

        &-header {
            display: block;
            font: $circle-tiles-list-item-title-font-size $font-secondary;
            letter-spacing: $heading-letter-spacing;
            margin-bottom: 10px;

            @include media-breakpoint-up(xl) {
                font-size: $circle-tiles-list-item-title-font-size-xl;
            }
        }
    }

    &-items-wrap {
        justify-content: center;
    }

    &-image-left {
        display: none;

        @include media-breakpoint-up(md) {
            display: inline-block;
            left: -80px;
            top: -30px;
            position: absolute;
            height: 143px;
            width: 143px;
        }

        @include media-breakpoint-up(lg) {
            left: -75px;
        }

        @include media-breakpoint-up(xl) {
            left: 0;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-image-right {
        display: none;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            bottom: -20px;
            right: -50px;
            position: absolute;
            height: 419px;
            width: 245px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-item {
        @include navigation-submenu-link($circle-tiles-list-item-mobile-link-color);

        position: relative;

        @include media-breakpoint-up(lg) {
            @include transition($transition-base);
            @include border-radius(5px);

            color: $black;
            display: block;
            margin: 0 auto;
            max-width: 174px;
            padding-bottom: 18px;
            padding-top: 24px;
            text-align: center;

            @include hover {
                background: $circle-tiles-list-item-hover-background;
                color: $link-hover-color;
                text-decoration: none;
            }
        }

        @include media-breakpoint-up(xl) {
            max-width: 180px;
        }

        &-image {
            border-radius: 50%;
            display: none;
            height: $circle-tiles-list-item-image-size;
            object-fit: cover;
            width: $circle-tiles-list-item-image-size;

            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }

        &-text {
            @include media-breakpoint-up(lg) {
                @include limit-text-lines($max-lines: 2);

                display: block;
                margin: 16px 6px 0;
            }
        }

        &-price-wrapper {
            @include limit-text-lines($font-size: $circle-tiles-list-item-price-font-size, $max-lines: 2);

            display: flex;
            align-items: center;
            justify-content: center;
            background: $circle-tiles-list-item-price-background;
            font: $circle-tiles-list-item-price-font-size $font-secondary;
            margin: $circle-tiles-list-item-price-margin;
            padding: $circle-tiles-list-item-price-padding;
            color: $circle-tiles-list-item-price-color;
            line-height: $line-height-base;

            @include media-breakpoint-up(md) {
                @include limit-text-lines($font-size: $circle-tiles-list-item-price-font-size-md, $max-lines: 2);

                font-size: $circle-tiles-list-item-price-font-size-md;
            }

            &:hover {
                color: $circle-tiles-list-item-price-hover-color;
            }
        }
    }

    .circle-tiles-list-item {
        display: flex;
        align-items: center;

        .circle-tiles-list-item-text {
            margin-left: 5px;

            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            display: block
        }
    }


    &.m-secondary {
        .circle-tiles-list-item {
            @include transition($transition-base);
            @include border-radius(5px);

            color: $black;
            display: block;
            margin: 0 auto 20px;
            min-height: 220px;
            padding: 20px 10px;
            text-align: center;
            background: $white;
            box-shadow: 0 0 5px 0 rgba($black, 0.1);
            max-width: none;

            @include hover {
                box-shadow: 0 0 15px 0 rgba($black, 0.15);
                color: $link-hover-color;
                text-decoration: none;
            }

            @include media-breakpoint-only(md) {
                min-height: 275px;
            }

            @include media-breakpoint-only(lg) {
                padding: 20px 15px;
            }

            .circle-tiles-list-item-image {
                display: inline-block;
                height: $circle-tiles-list-secondary-item-image-size;
                width: $circle-tiles-list-secondary-item-image-size;

                @include media-breakpoint-only(md) {
                    height: $circle-tiles-list-secondary-item-image-size-md;
                    width: $circle-tiles-list-secondary-item-image-size-md;
                }

                @include media-breakpoint-only(lg) {
                    height: $circle-tiles-list-secondary-item-image-size-lg;
                    width: $circle-tiles-list-secondary-item-image-size-lg;
                }

                @include media-breakpoint-only(xl) {
                    height: $circle-tiles-list-secondary-item-image-size-xl;
                    width: $circle-tiles-list-secondary-item-image-size-xl;
                }
            }

            &-text {
                @include limit-text-lines($line-height: $line-height-base, $font-size: $circle-tiles-list-secondary-text-font-size, $max-lines: 2);

                display: block;
                font-size: $circle-tiles-list-secondary-text-font-size;
                line-height: $line-height-base;
                margin: 15px 5px 0;

                @include media-breakpoint-only(md) {
                    @include limit-text-lines($font-size: $circle-tiles-list-secondary-text-font-size-md, $max-lines: 2);

                    font-size: $circle-tiles-list-secondary-text-font-size-md;
                }

                @include media-breakpoint-only(lg) {
                    @include limit-text-lines($font-size: $circle-tiles-list-secondary-text-font-size-lg, $max-lines: 2);

                    font-size: $circle-tiles-list-secondary-text-font-size-lg;
                }

                @include media-breakpoint-only(xl) {
                    @include limit-text-lines($font-size: $circle-tiles-list-secondary-text-font-size-xl, $max-lines: 2);

                    font-size: $circle-tiles-list-secondary-text-font-size-xl;
                }
            }
        }

        .circle-tiles-list-title {
            margin-bottom: 35px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 50px;
            }
        }
    }

    // CLP tiles
    &-main {
        &-tiles {
            padding: $circle-tiles-list-main-tiles-padding;

            @include media-breakpoint-up(xl) {
                padding: $circle-tiles-list-main-tiles-padding-xl;
            }

            &-item {
                border-right: 1px solid $circle-tiles-list-main-tiles-item-border-color;
                margin: $circle-tiles-list-main-tiles-item-margin;
                padding: $circle-tiles-list-main-tiles-item-padding;

                @include media-breakpoint-up(xl) {
                    margin: $circle-tiles-list-main-tiles-item-margin-xl;
                    padding: $circle-tiles-list-main-tiles-item-padding-xl;
                }

                &:last-child {
                    border-right: 0;
                }

                &-link {
                    @include navigation-submenu-link($circle-tiles-list-menu-link-color);

                    @include media-breakpoint-up(lg) {
                        @include transition($transition-base);
                        @include border-radius($circle-tiles-list-main-tiles-item-border-radius);

                        color: $black;
                        display: block;
                        padding: 15px ($grid-gutter-width * 0.5);
                        text-align: center;

                        @include hover {
                            background: $circle-tiles-list-main-tiles-item-hover-background;
                            color: $black;
                            text-decoration: none;

                            .circle-tiles-list-main-tiles-item-title {
                                color: $circle-tiles-list-main-tiles-item-hover-title-color;
                            }

                            .circle-tiles-list-main-tiles-item-button {
                                background: $circle-tiles-list-main-tiles-item-button-background-color;
                                color: $circle-tiles-list-main-tiles-item-button-color;
                            }
                        }
                    }
                }

                &-title {
                    color: $black;
                    display: block;
                    font-size: $circle-tiles-list-main-tiles-item-title-font-size;
                    font-weight: $font-weight-medium;
                    margin-bottom: 10px;
                    text-decoration: none;

                    @include media-breakpoint-up(xl) {
                        font-size: $circle-tiles-list-main-tiles-item-title-font-size-xl;
                    }
                }

                &-icon,
                &-button {
                    display: inline-block;
                }

                &-content,
                &-price {
                    display: block;
                }

                &-icon {
                    margin-bottom: 20px;
                    height: $circle-tiles-list-main-tiles-item-icon-size;

                }

                &-content {
                    @include limit-text-lines($font-size: $circle-tiles-list-main-tiles-description-font-size, $max-lines: 4);

                    font-size: $circle-tiles-list-main-tiles-description-font-size;
                    margin: $circle-tiles-list-main-tiles-item-text-margin;
                    padding: $circle-tiles-list-main-tiles-item-text-padding;
                    line-height: 1.4;

                    @include media-breakpoint-up(xl) {
                        margin: $circle-tiles-list-main-tiles-item-text-margin-xl;
                        padding: $circle-tiles-list-main-tiles-item-text-padding-xl;
                    }

                    &.m-secondary {
                        @include limit-text-lines($font-size: $circle-tiles-list-main-tiles-description-font-size, $max-lines: 5);

                        color: $black;
                    }
                }

                &-price {
                    @include limit-text-lines($line-height: $circle-tiles-list-main-tiles-price-line-height, $font-size: $circle-tiles-list-main-tiles-price-font-size, $max-lines: 2);

                    color: $black;
                    font-weight: $font-weight-medium;
                    line-height: $line-height-base;
                    margin: 0 0 6px;

                    &.m-discount {
                        color: $red;
                    }

                    b {
                        font-weight: $font-weight-bold;
                    }
                }

                &-price-line-through {
                    text-decoration: line-through;
                    color: $grey-4;
                }

                &-button {
                    cursor: pointer;
                    min-width: 134px;
                    padding: 7px 0;

                    @include media-breakpoint-up(xl) {
                        min-width: 170px;
                    }
                }
            }

            &-title {
                line-height: 1;
                margin: $circle-tiles-list-main-tiles-title-margin;

                @include media-breakpoint-up(xl) {
                    margin: $circle-tiles-list-main-tiles-title-margin-xl;
                }
            }

            &-description {
                color: color('base-4');
                font-size: $circle-tiles-list-main-tiles-description-font-size;
                margin: $circle-tiles-list-main-tiles-description-margin;

                @include media-breakpoint-up(xl) {
                    font-size: $circle-tiles-list-main-tiles-description-font-size-xl;
                    margin: $circle-tiles-list-main-tiles-description-margin-xl;
                }
            }

            &-content {
                @include limit-text-lines($max-lines: 10, $is-max-height: true);

                letter-spacing: $body-letter-spacing;
                margin: 0 0 26px;
                line-height: 1.4;
            }
        }

        &-image {
            padding-bottom: 36px;
            position: relative;

            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            &-row {
                @include media-breakpoint-down(md) {
                    flex-flow: column-reverse;
                }
            }

            &-title {
                margin: 20px 0 16px;
            }

            &-picture-wrap {
                display: block;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;

                @include media-breakpoint-only(lg) {
                    margin-right: -20%;
                }

                @include media-breakpoint-down(md) {
                    @include make-col-ready;

                    position: static;
                }
            }

            &-picture {
                height: 100%;

                @include media-breakpoint-down(md) {
                    max-height: 120px;
                    object-fit: cover;
                    width: 100%;
                }
            }

            &-content-wrap {
                z-index: z('default');
            }

            &-content {
                @include limit-text-lines($max-lines: 10, $is-max-height: true);

                margin: 0 0 26px;

                @include media-breakpoint-up(xl) {
                    max-height: 220px;
                }
            }
        }

        &-link {
            display: inline-block;
            font-weight: $font-weight-medium;
        }

        &-title {
            font: $circle-tiles-list-main-title-font-size $font-secondary;
            letter-spacing: $heading-letter-spacing;

            @include media-breakpoint-up(xl) {
                font-size: $circle-tiles-list-main-title-font-size-xl;
            }
        }
    }
}
