// -----------------------------------------------------------------------------
// This file contains all colors related to the Arabian Adventures theme
// -----------------------------------------------------------------------------

// Colors
//
// Markup:
// <div class="kss-color-item"><span style="background: #2d2423;"></span>#2d2423<br>$dark<br>color('base-0')</div>
// <div class="kss-color-item"><span style="background: #fafafa;"></span>#fafafa<br>$grey-1<br>color('base-1')</div>
// <div class="kss-color-item"><span style="background: #ebebeb;"></span>#ebebeb<br>$grey-2<br>color('base-2')</div>
// <div class="kss-color-item"><span style="background: #cac0b6;"></span>#cac0b6<br>$grey-3<br>color('base-3')</div>
// <div class="kss-color-item"><span style="background: #766a65;"></span>#766a65<br>$grey-4<br>color('base-4')</div>
// <div class="kss-color-item"><span style="background: #ffb652;"></span>#ffb652<br>$yellow<br>color('primary-1')</div>
// <div class="kss-color-item"><span style="background: #bb8c5f;"></span>#bb8c5f<br>$brown<br>color('primary-2')</div>
// <div class="kss-color-item"><span style="background: #007934;"></span>#007934<br>$green<br>color('primary-3')</div>
// <div class="kss-color-item"><span style="background: #0039a6;"></span>#0039a6<br>$blue<br>color('primary-4')</div>
// <div class="kss-color-item"><span style="background: #ed2939;"></span>#ed2939<br>$red<br>color('primary-5')</div>
// <div class="kss-color-item"><span style="background: #000;"></span>#000<br>$black</div>
// <div class="kss-color-item"><span style="background: #fff;"></span>#fff<br>$white</div>
// <style type="text/css">
// .kss-color-item {display: inline-block; vertical-align: top; padding: 0 0 15px; width: 33%; text-align: center;}
// .kss-color-item span {display: block; margin: 0 0 5px; height: 80px; border: 1px solid #333;}
// </style>
//
// Styleguide 2

// Base Colors
$grey-1: #fafafa;
$grey-2: #ebebeb;
$grey-3: #cac0b6;
$grey-4: #7c7d84;
$grey-6: #f2f1f1;
$grey-7: #d5d6df;
$grey-8: #5b5c64;
$grey-9: #a0a1aa;
$grey-10: #dfdde3;
$dark:   #222;

// Primary Colors
$yellow: #ffb652;
$orange: #cd7700;
$brown:  #bb8c5f;
$green:  #007934;
$blue:   #0039a6;
$red:    #ed2939;
$white:  #fff;
$black:  #222;
$grey: #5b5c64;
$fader: rgba(#222, 0.6);

// Service
$service-background: #f8f8f8;
$disabled-background: #d5d6df;
$disabled-text: #a0a1aa;

$colors: (
    'base-0':    $dark,
    'base-1':    $grey-1,
    'base-2':    $grey-2,
    'base-3':    $grey-3,
    'base-4':    $grey-4,
    'base-6':    $grey-6,
    'base-7':    $grey-7,
    'base-8':    $grey-8,
    'base-9':    $grey-9,
    'base-10':    $grey-10,
    'primary-1': $yellow,
    'primary-2': $brown,
    'primary-3': $green,
    'primary-4': $blue,
    'primary-5': $red
);
