.b-pdp_total {
    &-travelers {
        margin-bottom: 12px;

        > * {
            margin-bottom: 8px;
        }
    }

    &-sum {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 4px;
    }

    &-disclaimer {
        color: $grey;
        font-size: 12px;
        margin-bottom: 20px;
    }

    &-atc {
        margin-bottom: 20px;
    }

    &-copy {
        @include flex($vertical: center);

        column-gap: 8px;

        @include media(md-down) {
            display: none;
        }

        &-icon {
            @include inline-svg($icon-refund);

            flex-shrink: 0;
        }

        &-message {
            color: $grey;
            font-size: 12px;
        }

        .b-pdp_promo & {
            display: none;
        }
    }
}
