$header-top-border-color: color('primary-3');
$header-banner-bg-color: color('primary-3');
$header-qty-label-bg-color: color('primary-2');
$header-height: 78px;
$header-logo-widths: (
    xs: 64px,
    md: 64px,
    lg: 240px,
    xl: 240px
);
$header-logo-heights: (
    xs: 48px,
    md: 48px,
    lg: 45px,
    xl: 45px
);

$sticky-header-logo-width-lg: 90px;
$sticky-header-logo-width-xl: 78px;

$sticky-header-logo-height-lg: 57px;
$sticky-header-logo-height-xl: 49px;

$header-banner-text-color: $white;
$header-banner-link-color: color('primary-1');
$header-utils-link-color-lg: color('base-4');
$header-utils-divider-color: color('base-3');

$navigation-menu-link-color: color('base-4');

$error-notification-cookies-bg-color: color('primary-1');
$error-notification-js-bg-color: color('primary-5');
