// -----------------------------------------------------------------------------
// This file contains all styles related to the carousel hero component.
// -----------------------------------------------------------------------------
$content-hero-copy-font-size: 16px !default;
$content-hero-copy-color: $white !default;
$content-hero-copy-margin: 0 0 40px !default;
$content-hero-btn-max-width-md: 260px !default;
$content-hero-max-width: 1920px !default;

.content-hero {
    max-width: $content-hero-max-width;
    margin: 0 auto;
    position: relative;

    &-layout {
        @include text-alignment;
    }

    &-inner {
        @include when-inside('.m-without-search') {
            @include media-breakpoint-only(md) {
                justify-content: center !important;
            }
        }
    }

    &-body {
        max-width: $content-hero-body-max-width;
        margin: $content-hero-body-margin;
        font-weight: $content-hero-body-font-weight;

        @include media-breakpoint-up(md) {
            margin: $content-hero-body-margin-md;
            max-width: $content-hero-body-max-width-md;
        }

        @include media-breakpoint-up(lg) {
            max-width: none;
        }
    }

    &-title {
        font-size: $content-hero-title-font-size;
        line-height: $content-hero-title-line-height;
        color: $content-hero-title-color;
        margin: $content-hero-title-margin;

        @include media-breakpoint-up(md) {
            font-size: $content-hero-title-font-size-md;
        }

        @include media-breakpoint-up(lg) {
            font-size: $content-hero-title-font-size-lg;
        }

        @include media-breakpoint-up(xl) {
            font-size: $content-hero-title-font-size-xl;
        }
    }

    &-description {
        font-size: $content-hero-description-font-size;
        line-height: $content-hero-description-line-height;
        color: $content-hero-description-color;
        margin: $content-hero-description-margin;

        @include media-breakpoint-up(md) {
            font-size: $content-hero-description-font-size-md;
            margin: $content-hero-description-margin-md;
            line-height: $content-hero-description-line-height-md;
        }

        @include media-breakpoint-up(lg) {
            font-size: $content-hero-description-font-size-lg;
            margin: $content-hero-description-margin-lg;
        }

        @include media-breakpoint-up(xl) {
            font-size: $content-hero-description-font-size-xl;
        }
    }

    &-copy {
        font-size: $content-hero-copy-font-size;
        color: $content-hero-copy-color;
        margin: $content-hero-copy-margin;

        @include media-breakpoint-up(md) {
            margin: $content-hero-copy-margin-md;
        }
    }

    .btn {
        @include media-breakpoint-up(lg) {
            min-width: $content-hero-btn-min-width-lg;
        }
    }

    .theme-css & {
        @include aspect_ratio(31.5);

        @include media-breakpoint-down(sm) {
            @include aspect_ratio(21.9);
        }
    }
}
