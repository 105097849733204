// Default LTR direction for html
html {
    direction: ltr;
    height: 100%;
    min-width: $container-min-width;

    &.menu-opened {
        @include media-breakpoint-down(md) {
            overflow: hidden;
        }
    }
}

// Body
body {
    font-size: $body-font-size;
    height: 100%;
    letter-spacing: $body-letter-spacing;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    @include when-inside('.menu-opened') {
        overflow: hidden;
    }
}

.page {
    min-height: 100vh;

    @include when-inside('html.menu-opened') {
        overflow: hidden;
    }

    @include media-breakpoint-up(lg) {
        overflow: visible;
    }

    @include media-breakpoint-down(sm) {
        &.m-cart {
            padding-bottom: 70px;
        }
    }

    @include when-inside('.menu-opened') {
        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }
}

// Images
img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

figure {
    margin: 0;
}

input,
textarea,
select,
button {
    letter-spacing: inherit;
}

[type="number"] {
    -moz-appearance: textfield;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    appearance: none;
}

b,
strong {
    font-weight: $base-font-weight;
}

.b-sr_only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    left: 0;
    max-height: 1px;
    max-width: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
}
