.product-carousel {
    .b-product_tile {
        flex-direction: column;
        border-radius: 16px;
        border: 1px solid $border-color;
        overflow: hidden;
    }

    .image-container {
        &::before {
            content: none;
        }
    }

    .b-product_tile-body {
        padding: 0 16px 20px;
    }
}

.tier-tiles-carousel-container {
    transform: none !important;
}

.m-page_designer {
    .l-grid_layout {
        .b-carousel-item {
            .b-product_tile-cta_wrapper {
                display: flex;
                flex-grow: 1;
                align-items: flex-end;
            }

            .b-product_tile-pricing {
                flex-grow: 0;
            }
        }
    }
}
