$this: ".suggestions";

.suggestions {
    padding: 24px 0;

    @include media(md-up) {
        max-width: 1224px;
        padding: 48px 80px;
        margin: 0 auto;
    }

    &-wrapper {
        background: $white;
        border-top: 1px solid $border-color;
        position: absolute;
        right: 0;
        left: 0;
        max-height: 80vh;
        overflow-y: auto;
        top: 100%;
        z-index: z('above');
        width: 100%;
    }

    &-row {
        @include flex($horizontal: space-between);

        column-gap: 20px;
        row-gap: 40px;

        @include media(sm) {
            flex-direction: column;
            row-gap: 20px;
        }

        #{$this}-title {
            margin-top: 20px;
        }

        #{$this}-list {
            &-link {
                align-items: flex-start;
            }

            &-image {
                min-width: 80px;
            }

            &-item {
                margin-bottom: 16px;
            }

            &-name {
                display: block;
                font-size: 12px;
                color: color('base-8');

                &:nth-child(2) {
                    font-size: 14px;
                    line-height: 20px;
                    color: $dark;
                    margin: 2px 0 10px;
                }
            }
        }

        .product-price-currency {
            @include flex;

            font-size: 12px;
            color: $dark;

            .price-value {
                font-size: 12px;
                color: $dark;
                margin-left: 8px;
            }
        }
    }

    &-section {
        @include media(md-up) {
            &:not(:first-child) {
                border-left: 1px solid $border-color;
                padding-left: 40px;
                min-width: 300px;
            }
        }

        @include media(sm) {
            padding: 0 16px;

            &:not(:last-child) {
                border-bottom: 1px solid $border-color;
                padding-bottom: 20px;
            }
        }

        &.suggestions-category {
            #{$this}-category-title {
                margin-top: 0;
            }

            #{$this}-list {
                &-link {
                    align-items: center;
                }

                &-image {
                    min-width: 40px;
                    height: 40px;
                    width: 40px;
                }
            }

            #{$this}-category-name {
                margin: 0;
            }
        }

        &.suggestions-popular {
            #{$this}-popular-title {
                margin-top: 0;
            }
        }
    }

    &-title {
        margin-bottom: 20px;

        span {
            font-family: $font-primary;
            font-size: 14px;
            font-weight: bold;
        }
    }

    &-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        &-item {
            margin-bottom: 8px;
        }

        &-link {
            @include flex($vertical: center);

            color: $black;
            column-gap: 16px;
            font-size: 14px;
        }

        &-image {
            @include aspect_ratio(100);

            border-radius: $border-radius-small;
            display: block;
            position: relative;
            overflow: hidden;
            height: 80px;
            width: 80px;
        }
    }

    &-mean {
        margin-bottom: 40px;
    }

    .container {
        @include media(sm) {
            padding: 0;
        }
    }
}
