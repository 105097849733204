.breadcrumbs {
    @include when-inside('.l-pdp-header') {
        @include media(sm) {
            display: none;
        }
    }

    &-item + &-item {
        @include flex($vertical: flex-start);

        gap: 8px;

        &::before {
            @include inline-svg($icon-breadcrumb-divider);
        }
    }
}
