.content-top-banner {
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 380px;
    position: relative;
    background-position: 50% 0;

    @include media-breakpoint-up(md) {
        height: 400px;
    }

    &.m-overlay,
    &.m-wrapper-gradient {
        &::after {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &.m-overlay::after {
        background-color: rgba($black, 0.35);
    }

    &.m-wrapper-gradient::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 65%, rgba(255, 255, 255, 0.1) 100%);
    }

    &-wrapper {
        margin: 0 auto;
        z-index: z('default');

        @include media-breakpoint-up(md) {
            max-width: 1196px;
            width: 100%;
        }
    }

    &-title {
        color: $white;
        font-size: 44px;
        margin: 0 ($grid-gutter-width * 0.5);
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 71px;
            text-align: left;
        }
    }
}
