// -----------------------------------------------------------------------------
// This file contains all styles related to the content banner component.
// -----------------------------------------------------------------------------
$content-banner-font-size: 16px !default;
$content-banner-margin: 0 auto 60px !default;
$content-banner-title-font-size: 36px !default;
$content-banner-title-font-size-md: 50px !default;
$content-banner-title-font-size-lg: 66px !default;
$content-banner-title-color-lg: $white !default;
$content-banner-description-color-lg: $white !default;
$content-banner-inner-layout-padding-lg: 0 80px 0 !default;
$content-banner-inner-layout-padding-xl: 0 0 0 125px !default;
$content-banner-max-width: 1920px !default;

.content-banner {
    position: relative;
    font-size: $content-banner-font-size;
    margin: $content-banner-margin;
    max-width: $content-banner-max-width;
    width: 100%;
    overflow: hidden;

    &-layout {
        @include media-breakpoint-up(lg) {
            @include text-alignment(true, true);
        }

        @if ($theme-brand == 'marhaba') {
            @include media-breakpoint-up(md) {
                @include text-alignment(true, true);
            }
        }
    }

    &-inner-layout {
        position: relative;
        text-align: center;
        height: 100%;

        @include media-breakpoint-down(md) {
            padding: 30px 0 0;
        }

        @include media-breakpoint-only(md) {
            height: 350px;
        }

        @include media-breakpoint-up(lg) {
            padding: $content-banner-inner-layout-padding-lg;
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            padding: $content-banner-inner-layout-padding-xl;
        }
    }

    &-title {
        @include heading($content-banner-title-font-size, $content-banner-title-font-size-lg, $h1-color);

        @include media-breakpoint-only(md) {
            font-size: $content-banner-title-font-size-md;
        }

        @include media-breakpoint-up(md) {
            color: $content-banner-title-color-md;
        }
    }

    &-description {
        @include media-breakpoint-up(md) {
            color: $content-banner-description-color-md;
        }
    }

    &-location {
        color: $content-banner-location-color;
        padding: $content-banner-location-padding;

        @include media-breakpoint-up(md) {
            color: $content-banner-location-color-md;
            padding: $content-banner-location-padding-md;
        }

        @include media-breakpoint-up(lg) {
            color: $white;
            padding: 0;
        }
    }

    .theme-css & {
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }
}

