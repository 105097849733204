.comparison-carousel {
    .tns-carousel {
        direction: ltr;
    }

    .tns-controls {
        @include tiny-carousel-controls;

        position: absolute;
        left: 15px;
        right: 15px;
        top: 5px;
        height: 68px;
    }

    .tns-nav {
        top: 44px;
        bottom: auto;
    }
}
