// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

// Buttons
//
// Styleguide 4

// Buttons
//
// .btn - default button
// .btn.btn-primary - primary button
// .btn.btn-secondary - secondary button
// .btn.btn-tertiary - tertiary button
// .btn-quaternary - quaternary button
// .btn.btn-block - full width button
// .btn.disabled - disabled button
// .btn.btn-transparent - transparent button
// .btn-link - link button
//
// Markup:
// <button type="button" class="{{modifier_class}}">button</button>
//
// Styleguide 4.1

// Buttons with icons
//
// .btn-icon.m-edit - edit icon
// .btn-icon.m-compare - compare icon
//
// Markup:
// <button type="button" class="btn-quaternary"><span class="{{modifier_class}}">button</span></button>
//
// Styleguide 4.2

// Button large with inner content
//
// Markup:
// <button class="btn btn-primary btn-large btn-block btn-extend">
//     <span class="btn-extend-price">
//         <span class="btn-extend-price-caption">Subtotal</span>
//         <span class="btn-extend-price-value">AED 1,110</span>
//     </span>
//     <span class="btn-extend-text btn-large-inner">Add to cart</span>
// </button>
//
// Styleguide 4.3

// Buttons large (disabled)
//
// Markup:
// <button class="btn btn-primary btn-block btn-large" disabled>
//     <span class="btn-large-inner">Check availability</span>
// </button>
//
// Styleguide 4.4

$button-border-radius: false !default;
$button-border-transparent: $white !default;
$button-text-primary: $white !default;
$button-text-secondary: $white !default;
$button-text-tertiary: $white !default;
$button-bg-primary: $yellow !default;
$button-bg-secondary: $gray-400 !default;
$button-bg-tertiary: $gray-400 !default;
$button-bg-disabled: $gray-300 !default;
$button-border-disabled: $button-bg-disabled !default;
$button-bg-transparent: rgba(0, 0, 0, 0) !default;
$button-font-size: 16px !default;
$button-color-header-nav: $white !default;
$button-color-header-nav-hover: #f69f0d !default;
// button link
$button-link-color: color('base-0') !default;
$button-link-color-event: color('base-0') !default;
$button-link-color-disabled: $button-bg-disabled !default;
// button quaternary
$button-quaternary-padding: 8px 10px 7px !default;
$button-quaternary-font-size: 13px !default;
$button-quaternary-color: color('base-0') !default;
$button-quaternary-color-event: $button-quaternary-color !default;
$button-quaternary-bg-color: $white !default;
$button-quaternary-border-color: currentColor !default;
$button-quaternary-border-color-event: currentColor !default;
$button-quaternary-border-radius: $border-radius !default;
// button-icon
$button-icon-width: 18px !default;
$button-icon-height: 18px !default;
$button-icon-indent: 8px !default;
$button-icon-padding: 0 0 0 $button-icon-width + $button-icon-indent !default;
// button-icon-secondary
$button-icon-secondary-color: $black !default;
$button-icon-secondary-color-event: $black !default;
$button-icon-secondary-indent: 8px !default;
$button-icon-secondary-opacity-disabled: 0.8 !default;
$button-icon-secondary-before-size: 20px !default;
$button-icon-secondary-padding: 3px 0 3px $button-icon-secondary-before-size + $button-icon-secondary-indent !default;
$button-icon-secondary-after-left: $button-icon-secondary-before-size + $button-icon-secondary-indent !default;
$button-icon-secondary-after-border-bottom: 1px dashed currentColor !default;
// button large
$button-large-min-height: 60px !default;
$button-large-font-size: 17px !default;
$button-large-border-radius: false !default;
// button extend
$button-extend-padding: 5px 0 !default;
$button-extend-price-padding: 0 18px !default;
$button-extend-price-min-height: 35px !default;
$button-extend-price-before-opacity: 0.3 !default;
$button-extend-price-caption-margin: 0 0 3px !default;
$button-extend-price-caption-font-size: 12px !default;
$button-extend-price-value-font-size: 20px !default;
$button-extend-text-padding: 0 4px !default;
$button-extend-text-font-size: 22px !default;

.btn {
    @include button;
    @include button-style($border-radius: $button-border-radius);

    // Primary button
    &-primary {
        @include button-style($color: $button-text-primary, $bg: $button-bg-primary);
    }

    // Secondary button
    &-secondary {
        @include button-style($color: $button-text-secondary, $bg: $button-bg-secondary);
    }

    // Tertiary button
    &-tertiary {
        @include button-style($color: $button-text-tertiary, $bg: $button-bg-tertiary);

        @if ($theme-brand != "aa") {
            @include on-event {
                color: $white;
                background-color: $button-bg-transparent;
            }
        }
    }

    // Quaternary button
    &-quaternary {
        @include text-overflow;
        @include button-default;

        display: inline-block;
        padding: $button-quaternary-padding;
        font-size: $button-quaternary-font-size;
        background-color: $button-quaternary-bg-color;
        border: 1px solid $button-quaternary-border-color;
        color: $button-quaternary-color;
        border-radius: $button-quaternary-border-radius;
        vertical-align: middle;
        transition: border-color $transition-base-duration;

        @include media-breakpoint-up(lg) {
            @include on-event {
                border-color: $button-quaternary-border-color-event;
                color: $button-quaternary-color;
                text-decoration: none;
                outline: none;
            }
        }
    }

    // Button's inner icon
    &-icon {
        position: relative;
        display: inline-block;
        padding: $button-icon-padding;

        &::before {
            @include block-alignment-secondary($margin: 0 auto 0 0, $top: -4px);

            width: $button-icon-width;
            height: $button-icon-height;
            background: center / contain no-repeat;
            content: '';
        }

        &.m-compare {
            @include button-icon-name($icon-compare);
        }

        &.m-edit {
            @include button-icon-name($icon-edit);
        }
    }

    &-icon-secondary {
        @include button-default;
        @include text-overflow;

        position: relative;
        display: inline-block;
        max-width: 100%;
        padding: $button-icon-secondary-padding;
        color: $button-icon-secondary-color;
        background-color: transparent;
        border: none;
        transition: color $transition-base-duration;
        text-decoration: none;
        vertical-align: middle;

        @media (pointer: fine) {
            @include on-event {
                text-decoration: none;
                color: $button-icon-secondary-color-event;
                outline: none;

                &::after {
                    opacity: 0;
                }
            }
        }

        @include on-disabled {
            pointer-events: none;
            text-decoration: none;
            opacity: $button-icon-secondary-opacity-disabled;
        }

        &::before {
            @include block-alignment-secondary($margin: 0 auto 0 0, $top: -1px);

            width: $button-icon-secondary-before-size;
            height: $button-icon-secondary-before-size;
            background: center / contain no-repeat;
            content: '';
        }

        &::after {
            position: absolute;
            right: 0;
            left: $button-icon-secondary-after-left;
            bottom: 1px;
            border-bottom: $button-icon-secondary-after-border-bottom;
            transition: opacity $transition-base-duration;
            content: '';
        }

        &.m-not-emphasized {
            &::after {
                opacity: 0;
            }
        }

        &.m-wishlist {
            @include button-icon-name($icon-wishlist, $icon-wishlist-primary);
        }

        &.m-share {
            @include button-icon-name($icon-share, $icon-share-primary);
        }

        &.m-edit {
            @include button-icon-name($icon-edit-secondary, $icon-edit-primary);

            padding-left: 19px;

            &::before {
                width: 12px;
                height: 12px;
                top: 40%;
                transform: translateY(-50%);
            }

            &::after {
                left: 19px;
            }
        }

        &.m-remove {
            @include button-icon-name($icon-close, $icon-close-primary);

            padding-left: 17px;

            &::before {
                width: 10px;
                height: 10px;
                top: 40%;
                transform: translateY(-50%);
            }

            &::after {
                left: 17px;
            }
        }

        &.m-blank {
            padding-left: 0;

            &::after {
                left: 0;
            }
        }
    }

    // Button as a link
    &-link {
        @include text-overflow;
        @include button-default;

        max-width: 100%;
        padding: 5px 0;
        color: $button-link-color;
        background-color: transparent;
        text-decoration: underline;
        border: none;

        @include on-event {
            color: $button-link-color-event;
            text-decoration: none;
            outline: none;
        }

        @include on-disabled {
            pointer-events: none;
            text-decoration: none;
            color: $button-link-color-disabled;
        }
    }

    // Full width button
    &-block {
        display: block;
        width: 100%;
    }

    &-large {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: $button-large-min-height;
        font-weight: $font-weight-bold;
        font-size: $button-large-font-size;
        text-transform: uppercase;
        text-align: center;

        @if ($button-large-border-radius) {
            border-radius: $button-large-border-radius;
        }

        &-inner {
            @include text-overflow;
        }
    }

    &-extend {
        padding: $button-extend-padding;

        &-price {
            position: relative;
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $button-extend-price-padding;
            min-height: $button-extend-price-min-height;
            text-align: left;

            &::before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                border-right: 1px solid currentColor;
                opacity: $button-extend-price-before-opacity;
                content: '';
            }

            &-caption {
                display: block;
                margin: $button-extend-price-caption-margin;
                font-size: $button-extend-price-caption-font-size;
                font-weight: $font-weight-normal;
                text-transform: none;
            }

            &-value {
                display: block;
                font-size: $button-extend-price-value-font-size;
                font-weight: $font-weight-medium;
            }
        }

        &-text {
            padding: $button-extend-text-padding;
            font-size: $button-extend-text-font-size;
            flex: 1 1 auto;
        }
    }

    &-navigation {
        @include button-style($color: $button-color-header-nav, $bg: transparent, $border-radius: 6px);
        @include transition($transition-base);

        border: 2px solid $button-color-header-nav;
        color: $button-color-header-nav;
        line-height: 1;
        padding: 7px 30px;

        @include on-event {
            color: $button-color-header-nav-hover;
        }
    }

    // Disabled button
    &-disabled,
    &.disabled,
    &:disabled {
        color: $button-text-primary;
        border-color: $button-border-disabled;
        background: $button-bg-disabled;
        pointer-events: none;
    }

    // Transparent button
    &-transparent {
        @include button-style($color: $button-text-primary, $bg: $button-bg-transparent, $disable-hover: true);

        border: 2px solid $button-border-transparent;
    }

    &-checkout {
        .btn-in {
            display: inline-block;
            position: relative;
            padding-left: 23px;

            &::before {
                @include inline-svg-bg($icon-secure-white);

                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
            }
        }

        @include on-event {
            @media (pointer: fine) {
                .btn-in {
                    &::before {
                        @include inline-svg-bg($icon-secure-primary);
                        @include transition(background-image $transition-base-duration);
                    }
                }
            }
        }
    }
}

