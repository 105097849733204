// -----------------------------------------------------------------------------
// This file contains styles related to the form-group component.
// -----------------------------------------------------------------------------

// Form elements
//
// Styleguide 6

// Inputs
//
// Styleguide 6.1

// Input
//
// .disabled:disabled - Disabled state
// .readonly - Readonly state
// .m-readonly-ignore - Ignore readonly styles
// .is-invalid - Error state
// .is-invalid.m-invalid-secondary - Error secondary state
//
// Markup:
// <form>
//  <div class="form-group required">
//    <label class="form-control-label" for="test-input">Input title</label>
//    <input class="form-control {{modifier_class}}" id="test-input" placeholder="Uruguay" required />
//    <div class="invalid-feedback">Some hint message</div>
//  </div>
// </form>
// <script>
//   $('.m-readonly-ignore').attr('readonly', 'readonly');
// </script>
//
// Styleguide 6.1.1

// Input with icon
//
// .disabled:disabled - Disabled state
// .is-invalid - Error state
// .is-invalid.m-invalid-secondary - Error secondary state
//
// Markup:
// <form>
//  <div class="form-group required">
//    <label class="form-control-label">Input title</label>
//    <div class="form-input-wrapper m-datepicker">
//      <input class="form-control {{modifier_class}}" placeholder="Uruguay" required />
//      <div class="invalid-feedback">Some hint message</div>
//    </div>
//  </div>
// </form>
//
// Styleguide 6.1.2

// Select
//
// .disabled:disabled - Disabled state
// .is-invalid - Error state
// .is-invalid.m-invalid-secondary - Error secondary state
//
// Markup:
// <form>
//  <div class="form-group required">
//    <label class="form-control-label" for="test-select">Select title</label>
//    <div class="form-select-wrapper">
//      <select class="form-control {{modifier_class}}" id="test-select" required>
//         <option selected value=""></option>
//         <option>1</option>
//         <option>2</option>
//         <option>3</option>
//         <option>4</option>
//      </select>
//    <div class="invalid-feedback">Some hint message</div>
// </div>
// </div>
// </form>
//
// Styleguide 6.2

// Textarea
//
// .disabled:disabled - Disabled state
// .is-invalid - Error state
// .is-invalid.m-invalid-secondary - Error secondary state
//
// Markup:
// <form>
//  <div class="form-group required">
//     <label class="form-control-label" for="test-area">Textarea title</label>
//     <textarea class="form-control {{modifier_class}}" id="test-area" required></textarea>
//     <div class="invalid-feedback">Some hint message</div>
//   </div>
// </form>
//
// Styleguide 6.3

// Form level error
//
// .m-small - small
//
// Markup:
// <form>
//     <div class="alert-danger {{modifier_class}}">ERROR Your shopping cart can currently not be ordered since one or more of product in your cart have an invalid price or are not availiable in the requested quantity. Please remove the product(s) with prices marked as N/A from your cart or change the quantities to the availiable amount. If you have any questions, please contact customer service.</div>
// </form>
//
// Styleguide 6.4

// Checkbox
//
// Default Checkbox
//
// .disabled:disabled - Disabled state
//
// Markup:
// <div class="form-group custom-control custom-checkbox">
//     <input type="checkbox" class="custom-control-input {{modifier_class}}" id="checkbox1" name="checkboxMe" value="true">
//     <label class="custom-control-label" for="checkbox1">
//         test checkbox
//     </label>
// </div>
//
// Styleguide 6.5

// Radio
//
// Default Radio
//
// .disabled:disabled - Disabled state
//
// Markup:
// <div class="form-group custom-control custom-radio">
//     <input type="radio" class="custom-control-input {{modifier_class}}" id="radio1" name="radioMe" value="true"
//     >
//     <label class="custom-control-label" for="radio1">
//         test radio
//     </label>
// </div>
//
// Styleguide 6.6

// Radio with caption
//
// Markup:
// <div class="form-group custom-control custom-radio">
//     <input type="radio" class="custom-control-input" id="radio10" name="radioMe" value="true"
//     >
//     <label class="custom-control-label" for="radio10">
//         test radio
//     </label>
//     <div class="form-control-caption">
//         caption text
//     </div>
// </div>
//
// Styleguide 6.7

// Quantity
//
// Default Quantity
//
// .is-invalid - Error state
//
// Markup:
// <div class="quantity">
//     <label class="form-control-label">Quantity title</label>
//     <div class="quantity-group">
//         <button class="quantity-button quantity-button-subtract m-disabled" type="button"></button>
//         <input class="quantity-input {{modifier_class}}" type="number" name="" required="" value="0" max="10" min="0" tabindex="-1">
//         <button class="quantity-button quantity-button-add" type="button"></button>
//     </div>
// </div>
//
// Styleguide 6.8

$form-border-grey: color('base-1') !default;
$form-border-focus: color('base-5') !default;
$form-font-size: $body-font-size !default;
$form-font-grey: color('base-5') !default;
$form-font-focus: color('base-0') !default;
$form-font-disabled: color('base-4') !default;
$form-font-readonly: $form-font-disabled !default;
$form-bg-disabled: rgba(color('base-1'), 0.3) !default;

select,
input,
textarea {
    &.form-control {
        @include form-reset;

        min-height: 40px;
        border: 1px solid $form-border-grey;
        padding: 9px 15px;
        color: $form-font-grey;
        font-size: $form-font-size;
        text-align: left;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $form-border-focus;
            color: $form-font-focus;
            box-shadow: none;
        }

        // rewritten bootstrap  styles
        &[readonly] {
            background-color: $white;
        }

        // Disabled state
        @include on-disabled {
            pointer-events: none;
            color: $form-font-disabled;
            background-color: $form-bg-disabled;
        }

        // Readonly state
        @include on-readonly {
            pointer-events: none;
            color: $form-font-readonly;
            background-color: $form-bg-disabled;
        }
    }
}

.form-input-wrapper {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 10px;
        width: 22px;
        height: 22px;
        background: center/cover no-repeat;
        pointer-events: none;
    }

    &.m-datepicker {
        &::before {
            background-image: url("#{svg($icon-calendar)}");
        }
    }

    .form-control {
        padding-left: 42px;

        @include when-inside('.form-group-wrap-small') {
            padding-left: 37px;
        }
    }
}

.form-select {
    &-wrapper {
        position: relative;

        &::before {
            @include css-triangle($form-font-grey, $triangle-size: 6px, $triangle-direction: down);

            border-width: 6px 4px 0;
            position: absolute;
            right: 15px;
            top: 17px;
            pointer-events: none;
        }

        &:active,
        &:focus,
        &:focus-within {
            &::before {
                @include css-triangle($form-border-focus, $triangle-size: 6px, $triangle-direction: up);

                border-width: 0 4px 6px;
            }
        }

        &.m-disabled-wrapper {
            pointer-events: none;

            &::before {
                opacity: 0.6;
            }
        }

        .form-control {
            padding-right: 25px;

            &:not([size]):not([multiple]) {
                height: 40px;
            }

            @include when-inside('.form-group-wrap-small') {
                padding-right: 20px;
            }

            option {
                @include on-event {
                    background-color: rgba($form-border-grey, 0.3);
                }
            }
        }
    }
}

.form-control-input {
    border: 0;
    border-radius: $border-radius;
    padding: 0 80px 7px 10px;
    position: absolute;
    width: 100%;
    max-width: 600px;
    outline: 0;
}

.form-control-close {
    position: relative;
    right: 0;
    top: 1px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 16px;
        left: 7px;
        background-color: $form-border-focus;
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.form-control-list {
    padding: 0 10px 0 22px;
    border-left: 1px solid $form-border-grey;
    z-index: z('default');
    text-decoration: underline;
    cursor: pointer;
    position: relative;

    &-icon {
        @include inline-svg-bg($icon-pin);

        height: 19px;
        flex: 0 0 12px;
        position: absolute;
        left: 4px;
        top: calc(50% - 10px);
        width: 17px;
    }
}

.form-control-list-pick {
    position: relative;

    &::after {
        content: '';
        width: calc(100% - 30px);
        position: absolute;
        bottom: 0;
        left: 15px;
        height: 1px;
        border-bottom: 1px dashed $form-border-grey;
    }
}
