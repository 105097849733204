.search {
    form {
        @include flex($vertical: center);

        background: #f4f4f4;
        border-radius: $border-radius-large;
        column-gap: 10px;
        padding: 7px 12px;
    }

    .form-control {
        background: transparent;
        border: none;
        padding: 0 2px;

        @include hover-focus-active {
            border: none;
        }
    }

    &-overlay {
        .m-open & {
            background: $fader;
            inset: 0;
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    &-icon {
        @include inline-svg($icon-search);

        @include media(sm) {
            display: none;
        }
    }

    &-content {
        @include flex($vertical: center, $horizontal: space-between);

        background: $white;
        column-gap: 15px;
        padding: 10px 16px;
        position: relative;

        @include media(lg-up) {
            justify-content: center;
        }

        .m-sticky & {
            position: absolute;
            visibility: hidden;
        }

        .m-open & {
            position: relative;
            visibility: visible;
        }
    }

    &.m-open {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        width: 100vw;
        z-index: z('overlay');

        form {
            width: 284px;

            @include media(md-up) {
                width: 630px;
            }
        }
    }

    &-close {
        display: none;

        @include plain-hover-focus {
            @include inline-svg($icon-close-small);

            border: none;
            cursor: pointer;
            padding: 0;
            outline: none;
        }

        .m-open & {
            display: block;
        }
    }
}
