//table
$comparison-table-bg-color-sm: color('base-7');
$comparison-table-th-color-sm: color('dark-pdx');

// head
$comparison-head-font-size: 14px !default;
$comparison-head-line-height: 1.2 !default;
$comparison-head-font-weight: $font-weight-light !default;
$comparison-head-font: $comparison-head-font-weight #{$comparison-head-font-size} / #{$comparison-head-line-height} $font-primary !default;
$comparison-head-secondary-border-color: $black !default;
$comparison-row-bg-color: #e8e8e8 !default;
$comparison-cell-border-color: #e1e1e1 !default;
$comparison-column-box-shadow: 0 1px 4px rgba(color('base-0'), 0.25) !default;
// caption
$comparison-caption-font-size: 18px !default;
$comparison-caption-title-font-size: 20px !default;
$comparison-caption-font-weight: $font-weight-light !default;
$comparison-caption-font: $comparison-caption-font-weight $comparison-caption-font-size $font-primary !default;
$comparison-caption-secondary-font-size: 20px !default;
$comparison-caption-secondary-font-weight: $font-weight-medium !default;
$comparison-caption-secondary-font: $comparison-caption-secondary-font-weight $comparison-caption-secondary-font-size $font-primary !default;
// price
$comparison-price-value-font-weight: $font-weight-normal !default;
$comparison-price-through-color: $black !default;
$comparison-price-through-font-weight: $font-weight-medium !default;
$comparison-price-sales-font-weight: $font-weight-medium !default;
$comparison-price-sales-color: $black !default;
$comparison-head-price-font-size: 18px !default;
$comparison-head-price-font-size-xl: 20px !default;
$comparison-head-price-font-weight: $font-weight-medium !default;
$comparison-head-price-font: $comparison-head-price-font-weight $comparison-head-price-font-size $font-primary !default;
// description
$comparison-description-font-size: 13px !default;
$comparison-description-font-size-lg: 14px !default;
$comparison-description-line-height: 1.4 !default;
$comparison-description-font: $comparison-description-font-weight #{$comparison-description-font-size} / #{$comparison-description-line-height} $font-primary !default;
$comparison-description-opacity-disabled: 0.5 !default;
$comparison-description-icon-img-filter: none !default;
// note
$comparison-note-color: color('base-0') !default;
$comparison-note-font-size: 14px !default;
$comparison-note-font-weight: $font-weight-medium !default;
$comparison-note-font: $comparison-note-font-weight $comparison-note-font-size $font-primary !default;
// badge
$comparison-badge-font-size: 12px !default;
$comparison-badge-font-size-xl: 14px !default;
$comparison-badge-font-weight: $font-weight-medium !default;
$comparison-badge-line-height: 1.15 !default;
$comparison-badge-font: $comparison-badge-font-weight #{$comparison-badge-font-size} / #{$comparison-badge-line-height} $font-primary !default;
// section
$comparison-section-inner-box-shadow-color: $balck !default;
$comparison-section-inner-box-shadow: 0 1px 4px $comparison-section-inner-box-shadow-color !default;
$comparison-section-inner-border-radius: 0 !default;
// comparison m-secondary
$comparison-m-secondary-comparison-title-font-size: 20px !default;
$comparison-m-secondary-comparison-title-font-size-md: 24px !default;
$comparison-m-secondary-comparison-title-font-size-lg: 28px !default;
$comparison-m-secondary-comparison-caption-font-size: 20px !default;
$comparison-m-secondary-comparison-description-font-size: 13px !default;

.comparison {
    margin-bottom: 50px;
    font-weight: $font-weight-normal;

    @include media-breakpoint-up(md) {
        margin-bottom: 65px;
    }

    &-title-wrapper {
        border-bottom: 0;
    }

    &-title {
        margin-bottom: 0;
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }
    }

    &-subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }

    &-tabs {
        padding: 30px 20px 5px;
    }

    &-btn {
        width: 100%;
        max-width: 145px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 10px auto 15px;
        white-space: normal;
    }

    &-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
    }

    &-head {
        position: relative;
        padding: 15px 20px;
        font: $comparison-head-font;
        color: $comparison-table-th-color;
        background-color: $comparison-table-th-bg-color;

        @include media-breakpoint-up(lg) {
            border-width: 0 1px 0 0;
            border: 1px solid $comparison-cell-border-color;
            min-width: 120px;
            padding: 15px 10px;
            vertical-align: top;
        }

        @include media-breakpoint-up(xl) {
            padding: 20px;
        }

        @include media-breakpoint-down(md) {
            background-color: $comparison-table-bg-color-sm;
            color: $comparison-table-th-color-sm;
            text-align: center;
        }

        &:first-child {
            @include media-breakpoint-up(lg) {
                border-left: 0;
                text-align: left;
                vertical-align: middle;
            }
        }

        &:last-child {
            @include media-breakpoint-up(lg) {
                border-right: 0;
            }
        }

        &.m-secondary {
            @include media-breakpoint-up(lg) {
                width: 165px;
                vertical-align: middle;
            }
        }

        &.m-tertiary {
            @include media-breakpoint-up(lg) {
                width: 145px;
                vertical-align: middle;
            }
        }

        &-price {
            display: inline-block;
            font: $comparison-head-price-font;

            @include media-breakpoint-up(xl) {
                font-size: $comparison-head-price-font-size-xl;
            }
        }

        &-secondary {
            height: 68px;
            padding: 10px 15px;
            margin: 0 15px;
            border-bottom: 1px solid $comparison-head-secondary-border-color;
            text-align: center;
        }
    }

    &-head-wrapper {
        @include limit-text-lines($max-lines: 1.75, $is-max-height: true);
    }

    &-caption {
        display: block;
        font: $comparison-caption-font;

        @include media-breakpoint-up(lg) {
            margin-bottom: 35px;
        }

        @include media-breakpoint-down(md) {
            font-size: 23px;
        }


        &:only-child {
            margin-bottom: 0;
            font-size: $comparison-caption-title-font-size;
        }

        &-secondary {
            @include text-overflow;

            display: block;
            font: $comparison-caption-secondary-font;
        }
    }

    &-caption_general {
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    &-price {
        text-align: center;
        margin: 0 -4px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin-top: 4px;
        }

        &-value {
            display: block;
            margin: 0 4px;
            font-weight: $comparison-price-value-font-weight;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-top: 4px;
            }
        }

        &-through {
            display: inline-block;
            margin: 0 4px;
            color: $comparison-price-through-color;
            text-decoration: line-through;
            font-weight: $comparison-price-through-font-weight;
        }

        &-sales {
            display: inline-block;
            margin: 0 4px;
            font-weight: $comparison-price-sales-font-weight;
            color: $comparison-price-sales-color;
        }
    }

    &-row,
    li {
        padding: 10px 15px;

        &:last-child td {
            border-bottom-width: 1px;
        }

        &:nth-child(odd) {
            background-color: $comparison-row-bg-color;
        }
    }

    &-cell {
        text-align: center;

        @include media-breakpoint-up(lg) {
            height: 70px;
            padding: 12px 20px;
            border: 1px solid $comparison-cell-border-color;
            border-width: 0 1px 0 0;
        }

        &:first-child {
            text-align: left;

            @include media-breakpoint-up(lg) {
                border-left-width: 1px;
            }
        }
    }

    &-description {
        align-items: center;
        color: $comparison-description-color;
        display: flex;
        font: $comparison-description-font;
        padding: 5px 0;

        @include media-breakpoint-up(lg) {
            font-size: $comparison-description-font-size-lg;
        }

        @include media-breakpoint-down(md) {
            gap: 5px;
        }

        &.m-disabled {
            opacity: $comparison-description-opacity-disabled;
        }

        &-icon {
            width: 22px;
            height: 22px;
            margin-right: 18px;
            flex-shrink: 0;
            text-align: center;

            @include media-breakpoint-up(lg) {
                width: 30px;
                height: 30px;
            }

            img {
                filter: $comparison-description-icon-img-filter;
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        &-text {
            @include rtl-english-fix;

            padding-top: 2px;
            flex-grow: 1;
        }
    }

    &-note {
        font: $comparison-note-font;
        color: $comparison-note-color;
    }

    &-columns {
        display: flex;
        padding: 0 0 20px;

        &::after {
            content: '';
            min-width: 1px;
            max-width: 1px;
        }
    }

    &-column {
        flex: 0 0 80vw;
        min-width: 280px;
        margin: 25px 8px 5px 2px;
        box-shadow: $comparison-column-box-shadow;

        @include on-event {
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 62vw;
            margin-right: 20px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-sections {
        @include loader($color: $thumbnail-images-carousel-loader-color, $is-nested-class: true);

        margin: 0 -5px;

        @include media-breakpoint-down(md) {
            margin: 0 -10px 0 -5px;
        }
    }

    &-section {
        padding: 5px;
        display: none;

        &:first-child {
            display: inline-block;
        }

        &-inner {
            box-shadow: $comparison-section-inner-box-shadow;
            border-radius: $comparison-section-inner-border-radius;

            @include media-breakpoint-down(md) {
                background-color: $comparison-table-bg-color-sm;
            }
        }
    }

    &-badge {
        position: absolute;
        top: -25px;
        right: 5px;
        z-index: z('default');
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        padding: 2px 5px;
        font: $comparison-badge-font;
        color: $comparison-badge-color;
        background-color: $comparison-badge-bg-color;
        text-align: center;
        border-radius: 50%;

        @include media-breakpoint-up(lg) {
            top: -35px;
        }

        @include media-breakpoint-up(xl) {
            width: 68px;
            height: 68px;
            top: -40px;
            right: 15px;
            font-size: $comparison-badge-font-size-xl;
        }
    }

    &-icon {
        &-check {
            @include inline-svg-bg($icon-check);

            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            flex-shrink: 0;
        }

        &-cross {
            display: inline-block;
            flex-shrink: 0;
            font-size: 21px;
            height: 18px;
            text-align: center;
            vertical-align: middle;
            width: 18px;

            &::before {
                content: '-';
            }
        }
    }

    &.m-secondary {
        padding: 0;

        .comparison {
            &-title {
                margin-bottom: 0;
                font-size: $comparison-m-secondary-comparison-title-font-size;
                text-align: left;

                @include media-breakpoint-up(md) {
                    font-size: $comparison-m-secondary-comparison-title-font-size-md;
                }

                @include media-breakpoint-up(lg) {
                    font-size: $comparison-m-secondary-comparison-title-font-size-lg;
                }
            }

            &-head {
                padding: 8px 20px;
            }

            &-caption {
                font-size: $comparison-m-secondary-comparison-caption-font-size;
            }

            &-description {
                font-size: $comparison-m-secondary-comparison-description-font-size;
            }

            li {
                background-color: transparent;
            }

            &-cell {
                padding: 10px 15px;
                height: auto;
            }

            &-btn {
                margin: 5px auto 10px;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                }
            }
        }
    }
}
