.b-product_images {
    &-main_view {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 1fr;
        position: relative;

        @include media(md-up) {
            grid-template-columns: 67% 1fr;
            grid-template-rows: 1fr 1fr;
            max-height: 500px;
        }

        &-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &-item {
            overflow: hidden;
            cursor: pointer;

            &:first-child {
                border-radius: $border-radius $border-radius 0 0;
                grid-column: 1 / 3;
                max-height: 200px;
                position: relative;

                @include media(md-up) {
                    border-radius: $border-radius 0 0 $border-radius;
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;
                    max-height: none;

                    @include when-inside('.m-one-image') {
                        border-radius: $border-radius;
                        grid-column: 1 / 3;
                    }
                }
            }

            &:nth-child(2) {
                border-bottom-left-radius: $border-radius;
                grid-column: 1 / 2;
                max-height: 100px;

                @include media(md-up) {
                    border-radius: 0 $border-radius 0 0;
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    max-height: none;
                }
            }

            &:nth-child(n+3) {
                display: none;

                @include media(md-up) {
                    border-bottom-right-radius: $border-radius;
                    display: block;
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }
            }
        }
    }

    &-view_all {
        @include flex($horizontal: center, $vertical: center);

        border-radius: 0 0 $border-radius 0;
        border: 1px solid color('base-0');
        cursor: pointer;
        font: 700 16px $font-emirates;
        grid-column: 2 / 3;
        overflow: hidden;

        @include media(md-up) {
            background-color: $white;
            border-radius: $border-radius-large;
            bottom: 25px;
            position: absolute;
            right: 25px;
        }

        &:hover {
            text-decoration: underline;
        }

        @include when-inside('.m-one-image') {
            @include media(sm) {
                border-radius: 0 0 $border-radius $border-radius;
                grid-column: 1 / 3;
            }
        }
    }

    .pdp-product-explanatory,
    &-carousel-inner,
    &-thumbnails {
        display: none !important;
    }

    .main-image-carousel-element.no-image {
        border-radius: $border-radius;
        overflow: hidden;
    }

    .b-pdp-images-original-sticker,
    .b-pdp-images-badge-sticker {
        position: absolute;
        bottom: 12px;
        left: 12px;

        @include media(md-up) {
            bottom: 15px;
            left: 15px;
        }
    }

    .b-pdp-images-badge-sticker {
        right: 12px;
        left: auto;

        @include media(md-up) {
            right: 15px;
        }
    }
}
