@keyframes fade-in {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}


@mixin fade-in($modifier-class: false, $timing-function: ease, $duration: 0.3s, $delay: 0s) {
    opacity: 0;
    visibility: hidden;

    @if ($modifier-class) {
        &.#{$modifier-class} {
            animation: fade-in $duration $timing-function $delay forwards;
        }
    } @else {
        animation: fade-in $duration $timing-function $delay forwards;
    }
}

