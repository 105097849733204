// Product tile badges
$product-badge-container-top: 15px !default;
$product-badge-container-top-large: 70px !default;
$product-badge-default-background-color: $white !default;
$product-badge-default-color: color('base-0') !default;
$product-badge-default-line-height: 1.2 !default;
$product-badge-default-font-size: 14px !default;
$product-badge-default-font-weight: $font-weight-medium !default;
$product-badge-all-inclusive-background-color: $green !default;
$product-badge-all-inclusive-color: $white !default;
$product-badge-promo-offer-border-radius: 22px 0 0 22px !default;
$product-badge-promo-offer-padding: 8px 10px 4px 14px !default;
// Services badges
$service-badge-color: $white !default;
$service-badge-font-size: 16px !default;
$service-badge-title-font-size: 14px !default;
$service-badge-border-radius: 0 !default;
$service-badge-background-color: $black !default;
$service-badge-highlighted-background-color: $black !default;
// Main product image badges
$product-badge-main-image-background-color: $black !default;
$product-badge-main-image-color: $white !default;
// Product description badges
$product-badge-description-background-color: $green !default;
$product-badge-description-color: $white !default;
$product-badge-description-border-radius: $border-radius !default;

// Product tile badges
.product-badge {
    background-color: $product-badge-default-background-color;
    border-radius: 15px 0 0 15px;
    color: $product-badge-default-color;
    font-size: $product-badge-default-font-size;
    font-weight: $product-badge-default-font-weight;
    line-height: $product-badge-default-line-height;
    margin-bottom: 10px;
    padding: 8px 10px 6px 12px;

    &.m-all-inclusive {
        background-color: $product-badge-all-inclusive-background-color;
        color: $product-badge-all-inclusive-color;
    }

    &.m-promo-offer {
        border-radius: $product-badge-promo-offer-border-radius;
        padding: $product-badge-promo-offer-padding;
        text-align: center;
    }

    &-container {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: $product-badge-container-top;
        z-index: z('default');

        &.m-top-large {
            top: $product-badge-container-top-large;
        }
    }
}

// Services badges
.service-badge {
    background-color: $service-badge-background-color;
    border-radius: $service-badge-border-radius;
    bottom: 34px;
    color: $service-badge-color;
    font-size: $service-badge-font-size;
    line-height: 1;
    padding: 12px;
    position: absolute;
    right: 26px;
    text-align: center;
    text-overflow: initial;
    top: auto;
    user-select: none;
    z-index: z('default');

    @if ($theme-brand != "marhaba") {
        max-width: 150px;
    } @else {
        max-width: 120px;
    }

    @include media-breakpoint-up(md) {
        right: 34px;
        margin-bottom: 0;
    }

     @include when-inside('.service-tiles-bottom') {
        @include media-breakpoint-down(sm) {
            position: static;
        }
    }

    &.m-highlighted {
        background-color: $service-badge-highlighted-background-color;
    }

    &-old-price {
        text-decoration: line-through;
    }

    span {
        font-size: $service-badge-title-font-size;

        @if ($theme-brand == "marhaba") {
            display: block;
        }

        &:first-child {
            margin-bottom: 10px;
        }

        &:not(:first-child):last-child {
            margin-top: 10px;

            @if ($theme-brand != "marhaba") {
                display: block;
            }
        }
    }
}

// Main product image badges
%product-main-image-badge {
    background-color: $product-badge-main-image-background-color;
    border-radius: 0;
    color: $product-badge-main-image-color;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
}

%product-main-image-badge-container {
    align-items: flex-start;
    left: 0;
    right: auto;
    top: 0;
}

// Product description badges
%product-badge-description {
    background-color: $product-badge-description-background-color;
    border-radius: $product-badge-description-border-radius;
    color: $product-badge-description-color;
    font-size: 13px;
    font-weight: $font-weight-normal;
    padding: 7px 10px 4px;
    text-transform: uppercase;

    &:last-child {
        margin-bottom: 20px;
    }
}

%product-badge-description-container {
    align-items: flex-start;
    position: static;
}
