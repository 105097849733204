// -----------------------------------------------------------------------------
// This file contains styles that are specific to the error pages.
// -----------------------------------------------------------------------------

$error-page-code-color: color('base-1') !default;
$error-page-title-color: color('base-0') !default;
$error-page-message-color: color('base-4') !default;

.error-page {
    background: no-repeat center bottom / cover;
    text-align: center;

    &.m-404 {
        background-image: url('../images/bg-404.jpg');

        @include media-breakpoint-up(lg) {
            background-image: url('../images/bg-404-lg.jpg');
        }
    }

    &.m-500 {
        background-image: url('../images/bg-500.jpg');

        @include media-breakpoint-up(lg) {
            background-image: url('../images/bg-500-lg.jpg');
        }
    }

    &.m-maintenance {
        padding-top: 65px;
        padding-bottom: 65px;

        @include media-breakpoint-up(md) {
            padding-top: 200px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 240px;
        }
    }

    &-container {
        padding-top: 60px;
        padding-bottom: 100px;

        @include media-breakpoint-up(lg) {
            padding-top: 170px;
            padding-bottom: 150px;
        }
    }

    &-title {
        color: $error-page-title-color;
        font-size: 44px;
        margin-bottom: 35px;
    }

    &-message {
        color: $error-page-message-color;
        font-size: 16px;
        margin-bottom: 35px;
    }

    &-code {
        color: $error-page-code-color;
        font: $font-weight-light 150px/1 $font-primary;

        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 268px;
        }

        @include media-breakpoint-only(lg) {
            text-align: center;
        }
    }

    &-button {
        display: inline-block;
        width: auto;
    }

    &-brand {
        &-switcher {
            border-top: 1px solid $error-page-code-color;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 35px;
            padding-top: 30px;

            @include when-inside('.m-maintenance') {
                margin-top: 0;
            }
        }

        &-link {
            @include on-event {
                text-decoration: none;
            }

            @include media-breakpoint-up(lg) {
                filter: grayscale(100%) opacity(30%);

                @include hover {
                    filter: grayscale(0%) opacity(100%);
                }
            }
        }

        &-logo {
            max-height: 50px;
            max-width: 100px;
        }
    }
}
