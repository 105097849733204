// -----------------------------------------------------------------------------
// This file contains all styles related to the dropdown component.
// -----------------------------------------------------------------------------

$dropdown-toggle-hover-color: color('base-0') !default;
$dropdown-item-hover-background-color: $white !default;
$dropdown-item-hover-background-color-md: color('base-6') !default;

.dropdown {
    @include media-breakpoint-down(md) {
        position: static;
    }

    &.show {
        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                height: 10px;
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
            }
        }
    }

    &-toggle {
        @include media-breakpoint-up(lg) {
            position: relative;
            cursor: pointer;

            @include when-inside('.show') {
                color: $dropdown-toggle-hover-color;
            }

            &::after {
                margin-left: 5px;
                vertical-align: middle;
                transform: translateY(-25%);
            }
        }
    }

    &-menu {
        @include media-breakpoint-down(md) {
            border-radius: 0;
            border: none;
            box-shadow: none;
            left: 100%;
            margin: 0;
            padding: 14px 0;
            top: 0;
            width: 100%;
            height: auto;
            overflow-y: scroll;
        }

        @include media-breakpoint-up(lg) {
            margin-right: -20px;

            &::after,
            &::before {
                bottom: 100%;
                right: 24px;
                border: solid transparent;
                content: '';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &::after {
                border-color: rgba($dropdown-bg, 0);
                border-bottom-color: $dropdown-bg;
                border-width: 7px;
                margin-right: -7px;
            }

            &::before {
                border-color: rgba($dropdown-border-color, 0);
                border-bottom-color: $dropdown-border-color;
                border-width: 8px;
                margin-right: -8px;
            }
        }
    }

    &-header {
        font-size: 12px;
    }

    &-item {
        font-size: $body-font-size;

        @include on-event {
            background-color: $dropdown-item-hover-background-color;

            @include media-breakpoint-up(md) {
                background-color: $dropdown-item-hover-background-color-md;
            }
        }

        .m-highlighted {
            font-weight: $font-weight-medium;

            @include media-breakpoint-down(md) {
                color: $body-color;
                margin-right: 10px;
            }
        }

        .m-muted {
            color: $dropdown-link-disabled-color;
        }
    }

    &-divider {
        @include media-breakpoint-up(lg) {
            margin: $dropdown-item-padding-y $dropdown-item-padding-x;
        }
    }

    // Dropdown for My Account
    &-account {
        @include media-breakpoint-up(lg) {
            &.show::before {
                height: 15px;
                left: auto;
                right: -20px;
                width: 190px;
            }
        }

        &-toggle {
            @include media-breakpoint-up(lg) {
                &::before {
                    position: static;
                }

                &::after {
                    display: none;
                }
            }
        }

        &-menu {
            @include media-breakpoint-up(lg) {
                min-width: 190px;
                margin-top: 14px;

                &::before,
                &::after {
                    right: 32px;
                }
            }
        }
    }
}
