$hint-disabled-color: #d4d2d0 !default; // exception, for all brands
$hint-icon: $icon-info !default;
$hint-icon-size: 16px !default;
$hint-icon-empty-margin:  0 3px !default;
$hint-icon-event: $icon-info-primary !default;
$hint-icon-disabled: $icon-info-light !default;
$hint-icon-attention: $icon-attention-primary !default;

.hint {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;

    &::after {
        @include inline-svg-bg($hint-icon);

        content: '';
        position: absolute;
        top: -12px;
        right: -20px;
        width: $hint-icon-size;
        height: $hint-icon-size;
    }

    @include on-event {
        &::after {
            background-image: url("#{svg($hint-icon-event)}");
        }
    }

    @include when-inside('.search-widget-quantity') {
        @include on-event(true) {
            background-image: url("#{svg($hint-icon-attention)}");
            position: absolute;
            top: -10px;
            right: -10px;
            width: $hint-icon-size;
            height: $hint-icon-size;
            background-size: contain;

            &::after {
                content: none;
            }
        }
    }

    &.m-empty {
        margin: $hint-icon-empty-margin;
        vertical-align: top;

        &::after {
            position: static;
            display: block;
        }
    }

    &.m-empty-top {
        top: -6px;
        margin: $hint-icon-empty-margin;

        &::after {
            position: static;
            display: block;
        }
    }

    &-group {
        display: flex;
        align-items: flex-start;
    }

    &.m-disabled {
        color: $hint-disabled-color;
        pointer-events: none;

        &::after {
            background-image: url("#{svg($hint-icon-disabled)}");
        }
    }

    @include when-inside('.m-disabled') {
        &::after {
            background-image: url("#{svg($hint-icon-disabled)}");
        }
    }
}
