.b-category_tile {
    @include when-inside('.m-page_designer') {
        &-content {
            color: $blue;
            font-family: $font-emirates;
            font-weight: normal;
            padding: 24px 0;
        }

        img {
            transition: ease 0.3s all;
            z-index: 1;
        }

        &-picture {
            border-radius: var(--aspect-radio-border, $border-radius);
            overflow: hidden;

            &::after {
                content: "";
                width: 100%;
                display: block;
                height: 100%;
                background: $red;
                position: absolute;
                z-index: 0;
                bottom: 0;
            }

            &:hover {
                img {
                    top: -5px;
                }
            }
        }

        &-link {
            overflow: visible;
            text-align: center;
            width: 100%;

            &:hover {
                .b-category_tile {
                    &-picture {
                        transform: none;
                    }
                }
            }
        }
    }
}
