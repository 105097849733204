$main-image-carousel-controls-size: 46px !default;

.main-image-carousel {
    position: relative;

    @include media-breakpoint-up(md) {
        margin-bottom: 16px;
    }

    &::before,
    &::after {
        background: $white;
        bottom: 0;
        content: '';
        position: absolute;
        top: 0;
        width: 1px;
        z-index: z('default');
    }

    &::before {
        right: 0;
    }

    &::after {
        left: 0;
    }

    &-item-wrap {
        display: none;

        &:first-child {
            display: block;
        }

        figure {
            height: 100%;
        }
    }

    &-link {
        display: none;
    }

    &-element-wrapper {
        display: none;
        line-height: 0;

        &:first-child {
            display: inline-block;
        }
    }

    &-element {
        height: 378px;
        object-fit: cover;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 616px;
        }

        @include media-breakpoint-up(lg) {
            height: 476px;
        }

        @include media-breakpoint-up(xl) {
            height: 576px;
        }

        iframe {
            height: 100%;
        }
    }

    .tns-outer {
        @include media-breakpoint-up(md) {
            margin-bottom: 16px;
        }
    }

    .tns-controls {
        @include tiny-carousel-controls($arrow-icon-size: $main-image-carousel-controls-size, $arrow-icon-gutters: 5px, $arrow-icon-left: $icon-arrow-white-left, $arrow-icon-right: $icon-arrow-white-right) {
            background: rgba($black, 0.4) no-repeat center / 20px;
            border-radius: 50%;

            &[data-controls="prev"] {
                background-image: url("#{svg($icon-arrow-white-left)}") rtl(url("#{svg($icon-arrow-white-right)}"));

                @include media-breakpoint-only(sm) {
                    left: auto;
                    right: calc(100% + 25px);
                }

                @include on-disabled {
                    background-image: url("#{svg($icon-arrow-left)}") rtl(url("#{svg($icon-arrow-right)}"));
                }
            }

            &[data-controls="next"] {
                background-image: url("#{svg($icon-arrow-white-right)}") rtl(url("#{svg($icon-arrow-white-left)}"));

                @include media-breakpoint-only(sm) {
                    left: calc(100% + 25px);
                    right: auto;
                }

                @include on-disabled {
                    background-image: url("#{svg($icon-arrow-right)}") rtl(url("#{svg($icon-arrow-left)}"));
                }
            }
        }
    }

    .product-badge {
        @extend %product-main-image-badge;

        &-container {
            @extend %product-main-image-badge-container;
        }
    }
}

.main-image-carousel.m-modal {
    @include transition($transition-base);

    opacity: 0;
    text-align: center;

    &.modalmainimagecarousel-init {
        margin-bottom: 0;
        opacity: 1;
    }

    figure {
        line-height: 0;
    }

    .b-zoom_link {
        display: block;
        pointer-events: none;
    }

    img {
        max-height: 96vh;
        object-fit: cover;
        pointer-events: none;
        width: 100%;
    }

    iframe {
        height: 490px;

        @include media-breakpoint-up(xl) {
            height: 690px;
        }
    }

    .tns-inner {
        overflow: hidden;
    }

    .tns-outer {
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}
