@mixin aspect_ratio($percent) {
    &::before {
        content: "";
        display: block;
        padding-bottom: $percent * 1%;
        width: 100%;
    }

    img,
    video,
    picture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}