.b-tab_list {
    @include flex($horizontal: center);

    margin-bottom: 60px;

    &-tab {
        border-radius: 0;
        color: $grey;
        font-family: $font-emirates;
        flex: 0 1 207px;
        padding: 16px;
        position: relative;
        overflow: visible;
        text-decoration: none;

        &::after {
            border: 1px solid $border-color;
            bottom: 0;
            content: "";
            left: 50%;
            translate: -50%;
            position: absolute;
            height: 1px;
            width: calc(100% + 4px);
        }

        &:first-child {
            &::after {
                border-top-left-radius: $border-radius-small;
                border-bottom-left-radius: $border-radius-small;
            }
        }

        &:last-child {
            &::after {
                border-top-right-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
            }
        }

        &.m-active {
            color: $blue;

            &::after {
                border-color: $blue;
            }
        }

        &:hover {
            color: $blue;
        }
    }
}
