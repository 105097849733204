.b-pdp_aside-date {
    .bootstrap-datetimepicker-widget {
        border: 1px solid $dark;
        margin: 0 !important;
        width: 100%;
        z-index: z('above');

        &.bottom {
            border-radius: 0 0 $border-radius $border-radius;
            margin-top: -3px !important;
        }

        &.top {
            border-radius: $border-radius $border-radius 0 0;
            margin-bottom: -3px !important;
        }

        &::before,
        &::after {
            display: none;
        }

        .glyphicon {
            height: 12px;
            position: absolute;
            top: 26px;
            width: 12px;

            &-chevron-left {
                background-image: url("#{svg($icon-arrow-left-black)}");
                right: 50px;
            }

            &-chevron-right {
                background-image: url("#{svg($icon-arrow-right-black)}");
                right: 22px;
            }
        }

        .picker-switch {
            font-size: 16px;
            font-weight: 700;
            left: 20px;
            position: absolute;
            top: 10px;
        }

        .table-condensed th,
        .table-condensed td {
            padding: 16px 0;
            vertical-align: top;
        }

        .table-condensed td:not(.disabled):hover {
            background: $blue;
            border-radius: $border-radius-small;
            color: $white;
        }
    }

    .datepicker {
        padding-top: 25px;

        &-description {
            line-height: 20px;
            padding: 5px 20px 20px;

            &-limited,
            &-soldout {
                display: inline-block;
                margin-right: 20px;

                &::before {
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: 12px;
                    margin-right: 6px;
                    width: 12px;
                }
            }

            &-limited {
                color: $orange;

                &::before {
                    background: $orange;
                }
            }

            &-soldout {
                color: $red;

                &::before {
                    background: $red;
                }
            }

            &-prices {
                display: inline-block;
            }
        }

        &-days {
            .date-price {
                font-size: 10px;
                line-height: 12px;
            }

            .today {
                .wrapped-day {
                    color: $blue;
                    font-weight: 700;
                }

                &:hover {
                    .wrapped-day {
                        color: $white;
                    }
                }
            }

            .custom-disabled {
                text-decoration: line-through;
                pointer-events: none;
                color:$red;

                &.m-active {
                    color: $red;

                    &:hover {
                        color: $red;
                    }
                }
            }

            .low-availability {
                color: $orange;
            }
        }
    }

    .countblock-init {
        .quantity {
            @include flex($horizontal: space-between, $vertical: flex-start);

            &:nth-child(n + 2) {
                margin-top: 25px;
            }

            &-label {
                font-size: 16px;
                font-weight: 700;

                &-price {
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 2px;
                }

                &-sub {
                    color: color('base-8');
                    font-weight: 400;
                }
            }

            &-button {
                &-subtract,
                &-add {
                    background-size: 16px;
                    border-radius: 50%;
                    border: 1px solid $dark;
                    height: 32px;
                    width: 32px;

                    &:hover,
                    &.m-disabled {
                        background-size: 16px;
                        border-color: color('base-9');
                        height: 32px;
                        width: 32px;
                    }
                }

                &-subtract { // stylelint-disable-line no-descending-specificity
                    background-image: url('#{svg($minus-faq)}');

                    &:hover,
                    &.m-disabled {
                        background-image: url('#{svg($minus-faq)}');
                    }
                }

                &-add { // stylelint-disable-line no-descending-specificity
                    background-image: url('#{svg($plus-faq)}');

                    &:hover,
                    &.m-disabled {
                        background-image: url('#{svg($plus-faq)}');
                    }
                }
            }

            &.m-disabled {
                .quantity-button {
                    border-color: color('base-9');
                }
            }
        }

        .alert-information {
            margin-top: 25px;
        }
    }

    .phonecode-init {
        @include flex;

        gap: 12px;

        /* stylelint-disable selector-max-compound-selectors */
        @include when-inside('.tingle-modal') {
            .customSelect {
                .custom-select {
                    display: none;
                }

                .customSelect .custom-select {
                    display: block;
                }

                .custom-select-panel {
                    min-width: 320px;
                }
            }
        }
        /* stylelint-enable selector-max-compound-selectors */

        .form-group {
            width: 100%;

            &:first-child {
                max-width: 92px;
            }
        }
    }
}

.b-pdp_aside-section {
    .form-input-wrapper.m-datepicker {
        &:before {
            z-index: 1;
        }
    
        input.form-control {
            position: relative;
        }
    
        .dropdown-calendar {
            z-index: 1;
    
            .date-list-item {
                cursor: pointer;
                transition: opacity 0.3s;
    
                &:hover {
                    opacity: 0.7;
                }
    
                &.limited {
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-color: $yellow;
                        margin-left: 10px;
                        border-radius: 50%;
                    }
                }
            }
    
            .date-list_text-warning {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: $yellow;
                    margin-right: 10px;
                    border-radius: 50%;
                }
            }
        }
    }
}