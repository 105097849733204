.autocomplete {
    &-suggestions {
        text-align: left;
        cursor: default;
        border-top: 0;
        position: absolute;
        display: none;
        max-height: 254px;
        overflow: hidden;
        overflow-y: auto;
    }
}
