.btn {
    padding: 10px 32px;
    font-family: $font-emirates;
    font-size: 16px;
    border-radius: $border-radius-large;
    font-weight: bold;
    line-height: 24px;

    &-primary {
        background: $yellow;
        border: 1px solid $yellow;
        color: $blue;

        &:focus {
            outline: none;
        }

        @include hover {
            background: $white;
            border: 1px solid $yellow;
            color: $blue;
        }

        &.m-tile {
            font-size: 14px;
            padding: 7px 24px;
        }

        &:disabled,
        &.disabled,
        &.m-disabled {
            background: $disabled-background;
            border-color: $disabled-background;
            color: $disabled-text;
            cursor: not-allowed;
        }
    }

    &-secondary {
        background: transparent;
        color: $black;
        border: 1px solid $black;

        &:focus {
            outline: none;
        }

        @include hover {
            background: $yellow;
            border-color: $yellow;
            color: $white;
        }

        &:disabled,
        &.disabled,
        &.m-disabled {
            background: transparent;
            border-color: $disabled-text;
            color: $disabled-text;
        }
    }

    &-secondary_inverted,
    &-ghost,
    &-transparent {
        background: transparent;
        border: 1px solid $white;
        color: $white;

        &:focus {
            outline: none;
        }

        @include hover {
            background: $yellow;
            border-color: $yellow;
            color: $white;
        }

        &:disabled,
        &.disabled,
        &.m-disabled {
            background: transparent;
            border-color: $disabled-text;
            color: $disabled-text;
        }
    }

    &-link,
    &.m-link {
        background: none;
        border: none;
        padding: 0;
        color: inherit;
        font-size: inherit;
        font-family: inherit;

        @include hover-focus-active {
            background: none;
            color: inherit;
        }

        .m-page_designer & {
            color: inherit;
        }
    }

    @include when-inside('.m-page_designer') {
        &.m-link,
        &.m-link_regular,
        &.m-link_no_underline,
        &.m-link_small,
        &.m-link_small_bold,
        &.m-link_dark {
            &:hover {
                color: $white;
            }
        }
    }
}
