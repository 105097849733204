.b-pdp_aside-location {
    .form-select-list {
        padding: 12px 0;
    }

    .form-control {
        &-input {
            font-size: 14px;
            padding-right: 90px;
        }

        &-list {
            border: 0;
            font-size: 14px;
            pointer-events: none;

            &-icon {
                @include inline-svg-bg($icon-pin-secondary);

                height: 24px;
                width: 24px;
                top: calc(50% - 12px);
                left: -8px;
            }

            &--arrow {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #656565;
                font-size: 0;
                line-height: 0;
            }
        }
    }

    .b-pdp_aside-content {
        .b-pdp_aside-header {
            display: block;
        }
    }
}
