// Webkit styles (Chrome, Safari)
$scrollbar-webkit-width: 5px !default;
$scrollbar-webkit-border-radius: 5px !default;
$scrollbar-webkit-button-display: none !default;

$scrollbar-webkit-track-bg: linear-gradient(90deg, rgba($scrollbar-webkit-track-bg-color, 0) calc(50% - 1px), rgba($scrollbar-webkit-track-bg-color, 1) calc(50%), rgba($scrollbar-webkit-track-bg-color, 0) calc(50% + 1px)) !default;
$scrollbar-webkit-track-bg-horizontal: linear-gradient(180deg, rgba($scrollbar-webkit-track-bg-color, 0) calc(50% - 1px), rgba($scrollbar-webkit-track-bg-color, 1) calc(50%), rgba($scrollbar-webkit-track-bg-color, 0) calc(50% + 1px)) !default;
$scrollbar-webkit-horizontal-height: $scrollbar-webkit-width !default;
// Firefox styles
$scrollbar-width: thin !default;
$scrollbar-color: $scrollbar-webkit-thumb-bg $scrollbar-webkit-track-bg-color !default;

@mixin scrollbar-vertical {
    overflow-y: auto;
    /* stylelint-disable property-no-unknown */

    scrollbar-color: $scrollbar-color;
    scrollbar-width: $scrollbar-width;

    /* stylelint-enable property-no-unknown */

    &::-webkit-scrollbar {
        width: $scrollbar-webkit-width;
    }

    &::-webkit-scrollbar-button {
        display: $scrollbar-webkit-button-display;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
        background: $scrollbar-webkit-track-bg;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-webkit-thumb-bg;
        border-radius: $scrollbar-webkit-border-radius;
    }
}

@mixin scrollbar-horizontal {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: $scrollbar-webkit-horizontal-height;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
        background: $scrollbar-webkit-track-bg-horizontal;
    }
}

.box-scrollbar {
    @include scrollbar-vertical;

    &.m-horizontal {
        @include scrollbar-horizontal;

        overflow-x: scroll;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
    }
}

