.pdp-modal {
    .thumbnail-images-carousel-item {
        @include media(sm) {
            border: 1px solid $dark;
        }
    }

    .thumbnail-images-carousel {
        @include media(sm) {
            left: 0;
            right: 0;
            width: auto;
        }

        .tns-inner {
            @include media(sm) {
                margin: 0 !important;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .l-pdp-images {
        border-radius: $border-radius;
        overflow: hidden;

        .b-product_images-inner {
            position: relative;
        }

        .b-product_images-carousel-inner,
        .b-product_images-thumbnails {
            display: block !important;
        }

        .b-product_images-main_view,
        .b-product_images-thumbnails_controls {
            display: none !important;
        }

        .b-product_images-thumbnails {
            position: absolute;
            bottom: 25px;
            left: 0;
            right: 0;
            text-align: center;
        }

        .b-product_images-thumbnails_list {
            display: flex;
            justify-content: center;
            margin: 0;
            padding-left: 0;

            .b-product_images-thumbnails_item {
                padding: 0;
                border-radius: 50%;
                background: #fff;
                height: 8px!important;
                width: 8px!important;
                margin-right: 10px;

                img {
                    display: none;
                }

                &.m-active {
                    background-color: $green;
                }
            }
        }

        .tns-ovh {
            height: auto !important;
        }
    }
}


