$back-to-top-bg-color-lg: $white !default;
$back-to-top-font-size: 12px !default;

.back-to-top {
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    padding: 0 5px;
    margin: 30px auto 20px;
    color: inherit;
    background-color: $back-to-top-bg-color;
    border: none;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba($black, 0.3);
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        position: absolute;
        width: 70px;
        height: 70px;
        top: -65px;
        right: $grid-gutter-width * 0.5;
        margin: 0;
        border: 5px solid $back-to-top-border-color-lg;
        background-color: $back-to-top-bg-color-lg;
    }

    &::before {
        @include inline-svg-bg($icon-back-top-xs);

        content: '';
        display: block;
        margin: 0 auto;
        width: 15px;
        height: 15px;

        @include media-breakpoint-up(lg) {
            background-image: url("#{svg($icon-back-top-lg)}");
        }
    }

    &-text {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px 0 0;
        font-size: $back-to-top-font-size;
        line-height: 1.15;
        text-shadow: 0 1px 4px rgba($black, 0.6);
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            display: block;
            position: static;
            transform: none;
            padding: 2px 0 5px;
            color: $back-to-top-color-lg;
            font-weight: $back-to-top-font-weight-lg;
            text-shadow: none;
            white-space: normal;
        }
    }
}
