.notification-block {
    b {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
    }

    &-msg {
        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &::before {
            height: 20px;
            width: 20px;
        }
    }
}
