.map-block {
    background-color: $green;
    border-radius: $border-radius $border-radius 0 0;
    color: $white;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 32px 24px;
    position: relative;

    @include media(md-up) {
        @include flex;

        gap: 40px;
        padding: 40px;
    }

    &::after {
        background-image: url("../images/block-bg-aa-sm.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 0 0 $border-radius $border-radius;
        content: "";
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;
        top: calc(100% + 5px);

        @include media(md-up) {
            background-image: url("../images/block-bg-aa-lg.jpg");
        }
    }

    &-info {
        @include media(sm) {
            margin-bottom: 32px;
        }
    }

    &-title {
        color: $white;
        font-size: 36px;
        margin-bottom: 18px;

        @include media(md-up) {
            font-size: 42px;
        }
    }

    &-subtitle {
        margin-bottom: 8px;
    }

    &-button {
        border-color: $white;
        color: $white;
        margin-top: 24px;
        max-width: 210px;
    }

    &-map {
        border-radius: $border-radius;

        @include media(md-up) {
            height: 440px;
        }
    }
}
