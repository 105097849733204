.b-refinements_accordion {
    &-item {
        border-top: 1px solid $border-color;
        padding-top: 24px;
        margin-bottom: 24px;

        @include media(md-up) {
            &:first-child {
                border-top: none;
            }
        }
    }

    &-button {
        @include heading($heading-font-size: $h6-font-size, $heading-color: $h2-color);

        margin-bottom: 20px;
        text-transform: capitalize;
    }

    &-content {
        ul {
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;

            li {
                padding-left: 0;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &::before {
                    display: none;
                }
            }

            a {
                color: $dark;
            }
        }
    }

    &-show_more_link {
        color: $dark;
        text-decoration: underline;

        &:hover {
            color: $dark;
            text-decoration: none;
        }
    }
}
