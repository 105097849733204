.back-top-button {
    background-image: url("#{svg($icon-back-top-secondary)}");
    border-radius: 50%;
    border: 0;
    bottom: 198px;
    cursor: pointer;
    display: none;
    height: 48px;
    position: fixed;
    right: 40px;
    width: 48px;

    &:focus {
        outline: none;
    }

    &.m-visible {
        display: block;
    }
}

