// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

$header-bg-color: $white !default;
$header-top-border-color: $black !default;
$header-bottom-border-color: color('base-6') !default;
$header-height: 54px !default;

.header {
    background-color: $header-bg-color;
    border-top: 5px solid $header-top-border-color;
    border-bottom: 1px solid $header-bottom-border-color;
    position: relative;
    z-index: z('above');

    &.m-secondary {
        padding: 35px 0;
        border-top: none;
        border-bottom: none;
        background: #fafafa;
        margin-bottom: 5px;

        @include media(sm) {
            text-align: center;

            .header-logo {
                margin: 0 auto 25px;
            }
        }

        @include media(md-up) {
            .header-logo {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .header-nomenu-support-title {
            color: color('base-4');
            margin-right: 15px;

            @include media(md-up) {
                display: block;
            }
        }

        .header-nomenu-support-text {
            color: color('base-0');

            @include media(md-up) {
                font-size: 18px;
                font-weight: $font-weight-medium;
            }
        }
    }

    &.m-sticky {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        box-shadow: 0 6px 12px 0 rgba($black, 0.15);
        z-index: 5;

        @if ($theme-brand == 'marhaba') {
            border-top: 0;
        }

        .hide-when-sticky {
            @include media(lg-up) {
                display: none;
            }
        }
    }

    @include when-inside('.menu-opened') {
        border-bottom-color: $header-bg-color;
        position: static;
    }

    &-in {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: $header-height;

        @if ($theme-brand == 'css') {
            @include when-inside('.m-sticky') {
                @include media-breakpoint-down(sm) {
                    min-height: 54px;
                }
            }
        }

        @include media(md-up) {
            min-height: 94px;
        }

        @include media(lg-up) {
            min-height: 125px;
            align-items: flex-end;
            padding-bottom: 35px;

            @include when-inside('.m-sticky') {
                min-height: 80px;
                padding-bottom: 0;
                align-items: center;
            }
        }
    }

    &-icon {
        &-menu {
            @include header-icon($icon-menu, 30px);

            padding-right: 5px;

            @include hover-focus {
                outline: none;
            }

            @include when-inside('.menu-opened') {
                @include header-icon($icon-close, 22px);

                padding-right: 13px;
            }
        }

        &-user {
            @include header-icon($icon-user);
        }

        &-wishlist {
            @include header-icon($icon-wishlist);

            display: none;

            @include media(lg-up) {
                display: block;
            }
        }

        &-minicart {
            @include header-icon($icon-cart);

            display: block;

            &.m-disabled {
                pointer-events: none;
            }
        }
    }

    &-action-icons {
        padding-right: 10px;

        @include media(lg-up) {
            @include flex($horizontal: flex-end);

            min-width: 130px;

            @include when-inside('.m-sticky') {
                min-width: auto;

                .header-icon-wishlist {
                    display: none;
                }
            }
        }

        @include media(xl) {
            min-width: 170px;
        }

        .dropdown-account {
            margin-left: 20px;

            @include media(xl) {
                margin-left: 40px;
            }

            @include when-inside('.m-sticky') {
                margin-left: 0;
            }
        }

        .header-icon {
            @include media(lg-up) {
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }

            @include media(xl) {
                margin-left: 40px;
            }
        }
    }

    &-wishlist {
        &-quantity {
            @include header-items-qty-label($header-qty-label-bg-color);
        }
    }

    &-bottom-content {
        @include limit-text-lines($line-height: $line-height-base, $font-size: $body-font-size, $max-lines: 1);

        display: none;

        @include media(lg-up) {
            display: block;
        }
    }
}
