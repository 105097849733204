$form-control-label-sub-color: $black !default;
$form-control-border-radius: 0 !default;
$checkbox-primary-color: color('primary-1') !default;
$checkbox-border-color: color('base-3') !default;
$checkbox-disabled-border: color('base-1') !default;
$checkbox-disabled-bg: rgba(color('base-1'), 0.3) !default;
$checkbox-disabled-checked-bg: color('base-1') !default;
$checkbox-form-control-caption-font-size: 12px !default;
$checkbox-form-control-caption-color: $black !default;
$form-group-wrap-small-form-control-font-size: 13px !default;
$form-group-wrap-small-form-control-label-font-size: 13px !default;
$form-group-disabled-form-group-color: #d4d2d0 !default; // exception, for all brands

.form-group {
    .form-control-label {
        display: inline-block;
        margin: 0 0 8px;

        &-sub {
            color: $form-control-label-sub-color;
        }
    }

    &.required {
        .form-control-label {
            position: relative;
            padding-right: 6px;

            &::before {
                content: '\002A';
                color: $red;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .form-control {
        border-radius: $form-control-border-radius;

        &.is-invalid {
            background: url("#{svg($icon-exclamation)}") no-repeat $input-bg;
            background-position: calc(100% - 10px) 50% rtl(10px 50%);
            background-size: 20px;
            padding-right: 34px;

            @include on-event {
                box-shadow: none;
            }

            ~ .invalid-feedback {
                font-size: 13px;
                margin-top: 10px;
                color: $invalid-feedback-color;
            }
        }
    }

    /* stylelint-disable */
    &.custom-radio,
    &.custom-checkbox {
        padding-left: 22px;
        transition: $transition-base;
        user-select: none;

        .custom-control-label {
            cursor: pointer;
            padding: 3px 0 0 10px;

            &::after,
            &::before {
                border-radius: $form-control-border-radius;
                width: 22px;
                height: 22px;
                background-color: transparent;
                box-shadow: none;
                top: 0;
                left: -22px;
            }

            &::before {
                border: 2px solid $checkbox-border-color;
            }

            @include on-event {
                &::before {
                    border-color: $checkbox-primary-color;
                }
            }

            > span {
                display: block;
                font-size: 13px;
                color: color('base-4');

                @include media-breakpoint-up(lg) {
                    display: inline;
                }
            }
        }

        .form-control-caption {
            padding: 0 0 0 10px;
            font-size: $checkbox-form-control-caption-font-size;
            color: $checkbox-form-control-caption-color;
        }

        .custom-control-input {
            @include on-disabled {
                ~ .custom-control-label {
                    &::after {
                        border: 2px solid $checkbox-disabled-border;
                        background-color: $checkbox-disabled-bg;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .custom-control-input:checked {
            @include on-disabled {
                ~ .custom-control-label {
                    &::after {
                        background-color: $checkbox-disabled-checked-bg;
                        border: 2px solid $checkbox-disabled-border;
                    }
                }
            }

            ~ .custom-control-label {
                &::after {
                    @include inline-svg-bg($icon-checkmark);

                    background-size: 14px;
                    background-color: $checkbox-primary-color;
                }
            }
        }
    }

    &.custom-radio {
        .custom-control-label {
            &::after,
            &::before {
                border-radius: 50%;
            }
        }

        .custom-control-input:checked {
            @include on-disabled {
                ~ .custom-control-label {
                    &::after {
                        border-color: $checkbox-disabled-border;
                        background-color: $white;
                    }
                }
            }

            ~ .custom-control-label {
                &::after {
                    background: $white;
                    border: 6px solid $checkbox-primary-color;
                    border-radius: 50%;
                }
            }
        }
    }
    /* stylelint-enable */

    select,
    textarea {
        &.form-control {
            &.is-invalid {
                background-image: none;
            }
        }
    }

    textarea {
        &.form-control {
            min-height: 175px;
        }
    }

    &.m-disabled-form-group {
        pointer-events: none;
        color: $form-group-disabled-form-group-color;

        .form-control-label {
            color: inherit;

            &::before {
                color: inherit;
            }
        }

        .form-select-wrapper::before {
            border-top-color: inherit;
        }

        .form-control {
            @include on-disabled(true) {
                border-color: inherit;
                color: inherit;
                background-color: $white;
            }

            &::placeholder {
                color: inherit;
            }
        }
    }

    .form-select-list {
        min-height: 35px;
        padding: 8px 0 7px;
        font-size: $form-group-wrap-small-form-control-font-size;
        line-height: 1.4;
        display: flex;
        justify-content: flex-end;
        position: relative;
        border: 1px solid $form-border-grey;
        border-radius: $border-radius;
        background-color: $white;

        @include transition($input-transition);

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $form-border-focus;
            color: $form-font-focus;
            box-shadow: none;
        }
    }
}

.form-select-list-active {
    font-weight: $font-weight-bold;
}

.form-group-wrap-small {
    /* stylelint-disable */
    .form-control {
        min-height: 35px;
        padding: 8px 10px 7px 10px;
        font-size: $form-group-wrap-small-form-control-font-size;
        line-height: 1.4;

        &-label {
            margin-bottom: 5px;
            font-size: $form-group-wrap-small-form-control-label-font-size;
        }

        &.is-invalid {
            background-size: 16px;

            ~ .invalid-feedback {
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    select,
    textarea {
        &.form-control.is-invalid {
            ~ .invalid-feedback {
                padding: 2px 0 0 20px;
                background-size: 15px;
            }
        }
    }

    .is-invalid-container {
        .invalid-feedback {
            background: url("#{svg($icon-exclamation)}") no-repeat;
            background-position: 0 50%;
            padding: 2px 0 0 20px;
            background-size: 15px;
            font-size: 12px;
            margin-top: 5px;
            display: block;
        }
    }

    .form-input-wrapper::before {
        top: 6px;
    }

    .custom-select,
    .form-select-wrapper {
        &::before {
            top: 15px;
            right: 10px;
        }
    }

    .form-control:not([size]):not([multiple]) {
        height: 35px;
    }

    &.row,
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }

    [class*="col-"],
    .col {
        padding-right: 5px;
        padding-left: 5px;
    }
    /* stylelint-enable */
}
