$autocomplete-suggestions-border-color: color('base-0') !default;
$autocomplete-suggestions-bg-color: $white !default;
$autocomplete-suggestion-selected-bg-color: color('base-1') !default;
$autocomplete-suggestion-color: color('base-0') !default;

.autocomplete {
    &-suggestions {
        background: $autocomplete-suggestions-bg-color;
        border: 1px solid $autocomplete-suggestions-border-color;
        z-index: 1;
    }

    &-suggestion {
        padding: 5px 15px;
        color: $autocomplete-suggestion-color;

        &.selected {
            background-color: $autocomplete-suggestion-selected-bg-color;
        }

        b {
            font-weight: bold;
        }
    }
}
