.b-content_item {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;

    &-image {
        height: 255px;
        width: 100%;
        object-fit: cover;

        @include media(md-up) {
            height: 305px;
        }
    }

    &-content {
        padding: 20px 16px;
    }

    &-title {
        a {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.24px;
            line-height: 1.1;
            color: $blue;
        }
    }

    &-description {
        line-height: 20px;
        max-height: 60px;
        overflow: hidden;
    }

    &-link {
        color: $dark;
        text-decoration: underline;
        position: absolute;
        bottom: 20px;
        left: 16px;

        &:hover {
            text-decoration: none;
            color: $dark;
        }
    }

    .b-actions-container {
        display: inline-block;
        margin-bottom: 15px;
    }
}
