.b-pdp {
    color: $aa-black;
}

@include media-breakpoint-down(sm) {
    .tingle-modal {
        background-color: #fff;
        overflow-y: auto;
    }

    .tingle-modal-box__content {
        overflow-y: initial;

        &:has(.flyoutwidget-init.m-active) {
            padding-bottom: 150px;
        }

        &:has(.bootstrap-datetimepicker-widget.bottom) {
            padding-bottom: 300px;
        }
    }
}