// TODO make it more flexible
.background-svg {
    position: relative;
    z-index: z('default');

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: z('below');

        @include media-breakpoint-up(md) {
            background-position: -448px 130px;
        }

        @include media-breakpoint-up(lg) {
            background-size: 100%;
            background-position: 50% 50px;
        }

        @if ($theme-brand == 'marhaba') {
            @include background-svg($icon-curl, -645px 40px, 4283px);

            opacity: 0.2;
        } @else if ($theme-brand == 'aa') {
            @include background-svg($icon-aa-grey-logo, -645px 40px, 4283px);
        }
    }
}
