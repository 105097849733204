$social-sharing-text-color: inherit !default;

.social-sharing {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-text {
        margin-right: 10px;
        color: $social-sharing-text-color;
    }

    &-icon {
        @include transition($transition-base);

        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;

        &-facebook {
            @include inline-svg-bg($icon-social-facebook);

            @include on-event {
                @include inline-svg-bg($icon-social-facebook-active);
            }
        }

        &-twitter {
            @include inline-svg-bg($icon-social-twitter);

            @include on-event {
                @include inline-svg-bg($icon-social-twitter-active);
            }
        }

        &-pinterest {
            @include inline-svg-bg($icon-social-pinterest);

            @include on-event {
                @include inline-svg-bg($icon-social-pinterest-active);
            }
        }

        &-email {
            @include inline-svg-bg($icon-social-email);

            @include on-event {
                @include inline-svg-bg($icon-social-email-active);
            }
        }

        &-whatsapp {
            @include inline-svg-bg($icon-social-whatsapp);

            @include on-event {
                @include inline-svg-bg($icon-social-whatsapp-active);
            }
        }
    }
}
