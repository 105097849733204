.b-back-button {
    @include flex($vertical: center);

    gap: 8px;
    cursor: pointer;

    &::before {
        @include inline-svg($icon-chevron-left);

        content: '';
        border-radius: 50%;
        border: 1px solid $dark;
        width: 20px;
        height: 20px;
    }
}
