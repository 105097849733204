.request-password {
    &-header {
        border-bottom: 0;
        font-size: 24px;
        margin-bottom: 18px;
        padding-bottom: 0;
    }

    &-body {
        &-wrap {
            padding-top: 0;
        }

        > p {
            color: color('base-4');
            font-size: 14px;
            margin-bottom: 24px;
        }
    }
}
