.tabs {
    &-tertiary-nav,
    &-secondary-nav,
    &-nav {
        position: relative;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        line-height: 1;

        &-list {
            display: flex;
            align-items: flex-start;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }

        &-item {
            @include text-overflow;

            flex: 1 1 0;
            margin: 0;
            cursor: pointer;
        }
    }

    &-marker {
        position: absolute;
        bottom: 0;
        transition: transform 0.5s, width 0.5s, background-color 0.5s;
    }

    &-content {
        display: none;

        &.active {
            display: block;
        }
    }
}
