.b-refinements_panel {
    @include media(lg-up) {
        flex-shrink: 0;
        width: 305px;
    }

    input {
        @include visually-hidden;

        + span {
            &::before {
                @include inline-svg($icon-checkbox-inverted);

                display: inline-block;
                content: "";
                margin-right: 12px;
                border: 2px solid $black;
                border-radius: $border-radius-sm;
                position: relative;
                top: 6px;
            }
        }

        &:checked + span {
            &::before {
                background-color: $blue;
                border-color: $blue;
            }
        }
    }

    .b-refinement_checkbox-hit_count {
        color: $grey;
    }

    &-inner {
        @include media(md-down) {
            background: $white;
            border-top: 4px solid $blue;
            position: fixed;
            max-height: 100vh;
            overflow-y: auto;
            inset: 0;
            z-index: z('overlay');
            right: 100%;
            transition: 0.5s;

            .m-open > & {
                right: 0;
            }
        }
    }

    &-title {
        @include media(lg-up) {
            margin-bottom: 28px;
        }

        @include media(md-down) {
            display: none;
        }
    }

    &-header {
        display: none;

        @include media(md-down) {
            @include flex($horizontal: space-between);

            padding: 16px;
        }

        &-title {
            margin-bottom: 0;
        }
    }

    &-clear {
        padding: 24px;
        border-top: 1px solid $border-color;

        @include media(lg-up) {
            display: none;
        }
    }

    &-content {
        padding: 0 24px;
        border-radius: $border-radius;

        @include media(lg-up) {
            border: 1px solid $border-color;
        }
    }

    &-item {
        margin-bottom: 24px;
    }

    &-close {
        @include inline-svg($icon-close-small);
    }
}
