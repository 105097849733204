@mixin divider-full-width( $display: true, $color: $global-border-color, $size: 1px, $placing: top, $position: relative) {
    @if ($display) {
        position: $position;

        &::before {
            content: '';
            position: absolute;
            left: calc(50% - (50vw - 20px));
            width: calc(100vw - 40px);
            height: $size;
            color: $color;
            background-color: currentColor;
            box-shadow: 50vw 0 0 currentColor, -50vw 0 0 currentColor;
            @if ($placing == top) {
                top: 0;
            } @else {
                bottom: 0;
            }
        }
    } @else {
        &::before {
            display: none;
        }
    }
}
