$card-box-shadow-color: rgba(45, 36, 35, 0.2) !default;
$card-border-radius: 5px !default;
$card-border-color: #e1e1e1 !default;
$card-line-item-name-color: color('base-2') !default;
$card-line-item-font-size: 16px !default;
$card-line-item-font-size-md: 14px !default;
$card-inner-background: $white !default;
$card-inner-background-lg: #fafafa !default;
$card-inner-header-font-size: 18px !default;

.card {
    border: none;
    box-shadow: 0 1px 5px 0 $card-box-shadow-color;
    padding: 12px 12px 10px;
    margin-bottom: 20px;
    background-color: $white;
    border-radius: $card-border-radius;

    &.step-block {
        .col-xl-7 {
            padding: 0;
        }

        .step-block__form {
            display: flex;
            justify-content: space-between;  
            align-items: flex-start;
            margin-bottom: 15px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }  

            .step-block__content {
                width: calc(75% - 60px);

                @include media-breakpoint-down(sm) {
                    width: calc(75% - 20px);
                }
            }
    
            .step-block__img {
                width: 25%;
            }
        } 
        
        .step-block__heading {
            font-weight: 600;
        }

        .step-block__text {    
            font-size: 13px;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }    
    }

    &.m-secondary {
        @include media-breakpoint-down(md) {
            background: none;
            box-shadow: none;
            padding: 0;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
        padding: 25px 25px 20px;
    }

    &-header {
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $card-border-color;
        padding: 0 0 16px;

        h4 {
            @include rtl-english-fix;

            margin-bottom: 0;
        }

        &.m-secondary {
            border-bottom: none;
            color: color('base-0');
        }

        &-description {
            font-size: 16px;
            color: color('base-4');

            &__link {
                color: $link-color;
                text-decoration: underline;
                font-weight: 700;
                cursor: pointer;
                transition: color 0.2s;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    &-body {
        padding: 16px 0;
    }

    &-footer {
        padding: 20px 0 0;
        background-color: transparent;
        border-top: 1px solid $card-border-color;
    }

    &-line-item {
        font-size: $card-line-item-font-size;

        @include media-breakpoint-up(md) {
            font-size: $card-line-item-font-size-md;
        }
    }

    &-line-item-name {
        color: $card-line-item-name-color;
    }

    &-image {
        max-width: 58px;

        @include media-breakpoint-up(lg) {
            max-width: 100px;
        }
    }

    &-inner {
        @include border-radius($card-border-radius);

        margin: 15px 0;
        background: $card-inner-background;
        padding: 12px 12px 5px;

        @include media-breakpoint-up(md) {
            padding: 25px 25px 10px;
        }

        @include media-breakpoint-up(lg) {
            background: $card-inner-background-lg;
        }

        .card-inner-header {
            font-family: $font-primary;
            font-size: $card-inner-header-font-size;
            font-weight: $font-weight-medium;
        }
    }

    &-dashboard-title {
        font-weight: $font-weight-bold;
    }


    .payment-option {
        .card-method,
        .apple-pay-method {            
            display: none;       
            
            &:checked {
                ~ .payment-method-label {
                    &::after {
                        content:"";
                        position: absolute;
                        top: 21px;
                        right: 31px;
                        display: block;
                        width: 11px;
                        height: 11px;
                        background-color: $pink;
                        border-radius: 50%;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .tooltip {
                max-width: 250px;
            }
        }

        .payment-method-label {
            cursor: pointer;

            &__apple-pay {
                min-height: 53px;
                background-image: url("../images/apple_pay_icon.svg");
                background-position: 1.3rem -10px;
                background-repeat: no-repeat;
                background-size: 70px;
            }

            &__card {                
                background-image: url("../images/bank-account.png");
                background-position: 0 15px;
                background-repeat: no-repeat;
                background-size: 25px;
                padding-left: 33px;
            }

            &::before {
                content:"";
                position: absolute;
                top: 15px;
                right: 25px;
                display: block;
                width: 23px;
                height: 23px;
                border: 1px solid $form-border-grey;
                border-radius: 50%;
            }
        }

        input.card-method {
            ~ .b-tabs_content {
                overflow: hidden;                
                max-height: 0;
            }
        }

        input.card-method:checked {
            ~ .b-tabs_content {
                overflow-y: auto;
                max-height: 560px;
            }           
        }
    }
}  

.saved_passengers-select {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    > div.form-group {
        flex-grow: 1;
        margin-bottom: 0;
    }

    span.hint {
        margin: 10px;
    }

    .tooltip {
        width: 270px;

        @include media-breakpoint-down(sm) {
            left: -113px !important;

            .tooltip-arrow {
                left: 234px !important;
            }
        }       

        a {
            text-decoration: underline;
            color: #fff;
        }
    }
}
