$pdp-description-font-size: 16px !default;
$pdp-description-color: $black !default;
$pdp-description-title-font-size-lg: 34px !default;
$pdp-description-caption-margin-md: 0 0 15px !default;
$pdp-description-caption-font-size-md: 16px !default;
$pdp-description-caption-line-height-md: 1.5 !default;
$pdp-description-caption-font-md: $font-weight-normal #{$pdp-description-caption-font-size-md} / #{$pdp-description-caption-line-height-md} $font-primary !default;
$pdp-description-caption-color-md: color('base-3') !default;
$pdp-description-list-font-size-md: 14px !default;
// pdp description side
$pdp-description-side-font-size: 14px !default;
$pdp-description-side-color: $black !default;
$pdp-description-side-bg-color: $grey-1 !default;
$pdp-description-side-figure-margin: 0 (-$grid-gutter-width * 0.5) 30px !default;

.pdp-description {
    font-size: $pdp-description-font-size;
    color: $pdp-description-color;
    text-align: justify;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    &-title {
        @include media-breakpoint-up(lg) {
            font-size: $pdp-description-title-font-size-lg;
        }
    }

    &-caption {
        text-align: center;

        @include media-breakpoint-up(md) {
            margin: $pdp-description-caption-margin-md;
            font: $pdp-description-caption-font-md;
            color: $pdp-description-caption-color-md;
            text-align: left;
        }
    }

    &-list {
        @include media-breakpoint-up(md) {
            font-size: $pdp-description-list-font-size-md;
        }
    }

    &-side {
        font-size: $pdp-description-side-font-size;
        color: $pdp-description-side-color;
        background-color: $pdp-description-side-bg-color;
        text-align: justify;

        &-figure {
            display: block;
            margin: $pdp-description-side-figure-margin;

            picture {
                @include aspect-ratio(65.6);

                @include media-breakpoint-only(md) {
                    @include aspect-ratio(41.6);
                }

                @include media-breakpoint-down(sm) {
                    @include aspect-ratio(39.6);
                }

                img {
                    height: auto !important;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        &-img {
            width: 100%;
        }
    }
}
