.tingle-modal {
    .main-image-carousel.m-modal {
        img {
            max-height: 100vh;
            object-fit: contain;
        }

        .thumbnail-images-carousel {
            img {
                max-height: 96vh;
                object-fit: cover;
            }

            &-control {
                border-radius: 50%;

                @include media(md-up) {
                    top: calc(50% - 20px);
                    border: 1px solid $blue;
                }

                &[data-controls="prev"],
                &[data-controls="prev"]:disabled {
                    left: 24px;

                    @include plain-hover-focus {
                        @include inline-svg($icon-chevron-left);

                        @include media(md-up) {
                            background-size: 50%;
                            height: 40px;
                            width: 40px;
                        }
                    }
                }

                &[data-controls="next"],
                &[data-controls="next"]:disabled {
                    right: 24px;

                    @include plain-hover-focus {
                        @include inline-svg($icon-chevron-right);

                        @include media(md-up) {
                            background-size: 50%;
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }
        }
    }

    .form-group {
        .form-control {
            border-radius: $border-radius-small;
        }
    }
}
