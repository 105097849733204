// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs secondary component.
// -----------------------------------------------------------------------------
$tabs-secondary-nav-margin: 0 0 20px !default;
$tabs-secondary-nav-font-size: 14px !default;
$tabs-secondary-nav-border-radius: 0 !default;
$tabs-secondary-nav-color: $white !default;
$tabs-secondary-nav-color-active: $white !default;
$tabs-secondary-nav-bg-color: rgba($black, 0.3) !default;
$tabs-secondary-nav-bg-color-active: $black !default;
$tabs-secondary-item-padding: 8px 5px !default;


.tabs-secondary-nav {
    margin: $tabs-secondary-nav-margin;
    font-size: $tabs-secondary-nav-font-size;

    &-item {
        @include tabs-border-radius($tabs-secondary-nav-border-radius);

        padding: $tabs-secondary-item-padding;
        color: $tabs-secondary-nav-color;
        background-color: $tabs-secondary-nav-bg-color;

        &.active {
            color: $tabs-secondary-nav-color-active;
            background-color: $tabs-secondary-nav-bg-color-active;
        }
    }
}
