.b-pdp_skywards {
    @include flex($vertical: center);

    column-gap: 10px;
    color: $grey;
    margin-bottom: 20px;
    padding-top: 20px;

    &-icon {
        @include inline-svg($icon-skywards);

        border-radius: $border-radius-small;
    }

    &-message {
        margin: 0;
    }

    &-trigger {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    &-modal {
        @include media(md-down) {
            background: $fader;
        }
    }
}
