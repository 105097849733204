
// -----------------------------------------------------------------------------
// This file contains styles that are specific to the section layout.
// -----------------------------------------------------------------------------
$section-padding: 30px 0 0 !default;
$section-padding-lg: 40px !default;
$section-padding-md: 40px !default;

.section {
    padding: $section-padding;

    @include media-breakpoint-up(lg) {
        background: $white;
        padding: $section-padding-lg;
    }

    @if ($theme-brand == 'marhaba') {
        @include media-breakpoint-up(md) {
            background: $white;
            padding: $section-padding-md;
        }
    }
}
