.social-sharing {
    cursor: pointer;
    position: relative;

    &-text {
        @include flex($vertical: center);

        font-size: 12px;
        gap: 8px;
        margin: 0;

        &::after {
            @include inline-svg($icon-social-links);

            content: '';
            display: inline-block;
        }

        span {
            @include media (sm) {
                display: none;
            }
        }
    }

    &-inner {
        background-color: $blue;
        border-radius: $border-radius-small;
        display: none;
        padding: 16px 12px;
        position: absolute;
        right: 0;
        top: calc(100% + 8px);
        width: 196px;
        z-index: z('default');

        &::before {
            border: 8px solid transparent;
            border-top-width: 0;
            border-bottom-color: $blue;
            bottom: 100%;
            content: '';
            height: 0;
            position: absolute;
            right: 8px;
            width: 0;
        }
    }

    &-icon {
        @include flex($vertical: flex-end);

        background: none;
        color: $white;
        cursor: pointer;
        gap: 8px;
        height: auto;
        line-height: 1;
        margin-right: 0;
        width: auto;

        & + & {
            margin-top: 16px;
        }

        &::before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
        }

        @include on-event {
            background: none;
            color: $white;
            text-decoration: underline;
        }

        &-instagram {
            &::before {
                @include inline-svg($icon-social-instagram-secondary);
            }
        }

        &-facebook {
            &::before {
                @include inline-svg($icon-social-facebook-secondary);
            }
        }

        &-whatsapp {
            &::before {
                @include inline-svg-bg($icon-social-whatsapp-secondary);
            }
        }

        &-email {
            &::before {
                @include inline-svg-bg($icon-social-email-secondary);
            }
        }

        &-copy {
            &::before {
                @include inline-svg-bg($icon-social-copy-secondary);
            }
        }
    }

    &.m-active {
        .social-sharing-inner {
            display: block;
        }
    }
}
