@mixin button-default {
    appearance: none;
    cursor: pointer;
    line-height: 1;
    text-align: center;
    user-select: none;
}

@mixin button() {
    @include text-overflow;

    appearance: none;
    cursor: pointer;
    display: block;
    line-height: 1;
    padding: 10px 20px;
    text-align: center;
    user-select: none;

    @include media-breakpoint-up(lg) {
        display: inline-block;
        vertical-align: middle;
        width: auto;
    }
}

@mixin button-style($color: $white, $bg: $black, $border-radius: false, $disable-hover: false) {
    background: $bg;
    border: 2px solid $bg;
    color: $color;
    max-width: 100%;
    font-size: $button-font-size;

    @if ($border-radius) {
        border-radius: $border-radius;
    }

    @if not $disable-hover {
        transition: $transition-base;

        @media (pointer: fine) {
            @include on-event {
                background: transparent;
                color: $bg;
                outline: none;
                text-decoration: none;
            }
        }

        @media (pointer: coarse) {
            @include on-event {
                background: $bg;
                color: $color;
                outline: none;
                text-decoration: none;
            }
        }
    }
}

@mixin button-icon-name($icon-name, $icon-name-event: false) {
    &::before {
        background-image: url("#{svg($icon-name)}");
        transition: background-image $transition-base-duration;
    }

    @if ($icon-name-event) {
        @media (pointer: fine) {
            @include on-event {
                &::before {
                    background-image: url("#{svg($icon-name-event)}");
                }
            }
        }
    }
}
