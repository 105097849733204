// For each breakpoint, define the width of the logo in a media query
@mixin header-logo-widths($logo-widths: $header-logo-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $logo-width in $logo-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            width: $logo-width;
        }
    }
}

@mixin header-logo-heights($logo-heights: $header-logo-heights, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $logo-height in $logo-heights {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            height: $logo-height;
        }
    }
}

@mixin header-items-qty-label($bg-color: $black, $text-color: $white) {
    color: $text-color;
    background: $bg-color;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 19px;
    width: 19px;
    text-align: center;
    transform: translate(50%, -50%);
}

@mixin header-icon($icon-name, $size: 26px) {
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;

    &::before {
        @include inline-svg-bg($icon-name);

        content: '';
        display: block;
        height: $size;
        pointer-events: none;
        width: $size;
    }
}

@mixin header-navigation-bus-route-text-color($color) {
    color: $color;

    @include hover {
        color: $color;
    }
}

@mixin navigation-overlay($z-index: 'default') {
    &::before {
        content: '';
        background: rgba($black, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: z($z-index);
    }
}

@mixin navigation-link($link-color) {
    color: $link-color;
    display: block;
    line-height: 1;
    padding: 14px 35px 14px 22px;
    position: relative;

    @include media-breakpoint-only(md) {
        padding: 14px 45px 14px 33px;
    }

    @include media-breakpoint-down(md) {
        @include on-event {
            color: $link-color;
            text-decoration: none;
        }

        &.dropdown-toggle {
            &::after {
                @include inline-svg-bg($icon-menu-arrow-right, $icon-menu-arrow-left);

                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                border: none;
                height: 16px;
                width: 16px;

                @include media-breakpoint-only(md) {
                    right: 30px;
                }
            }
        }

        &.dropdown-item {
            padding-left: 45px;

            @include media-breakpoint-only(md) {
                padding-left: 55px;
            }
        }
    }
}

@mixin navigation-submenu-link($link-color) {
    @include media-breakpoint-down(md) {
        color: $link-color;
        display: block;
        line-height: 1;
        padding: 14px 33px;

        @include on-event {
            color: $link-color;
            text-decoration: none;
        }
    }

    @include media-breakpoint-only(md) {
        padding: 14px 43px;
    }
}

@mixin navigation-backlink() {
    cursor: pointer;
    display: block;
    font-family: $font-secondary;
    font-size: $navigation-menu-link-font-size;
    margin-bottom: 5px;
    padding: 14px 35px 14px 45px;
    position: relative;

    @include media-breakpoint-up(md) {
        padding-left: 55px;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &::before {
        @include inline-svg-bg($icon-menu-arrow-left, $icon-menu-arrow-right);

        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        border: none;
        height: 16px;
        width: 16px;

        @include media-breakpoint-only(md) {
            left: 30px;
        }
    }
}
