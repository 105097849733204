summary {
    display: block;
    padding: 0 40px 0 0;
    position: relative;
    list-style: none;

    &::marker,
    &::-webkit-details-marker {
        display: none;
    }

    &::after {
        @include inline-svg($icon-chevron-down);

        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        translate: 0 -50%;

        [open] & {
            @include inline-svg($icon-chevron-up);
        }
    }
}
