$theme-brand: 'aa';

// Global
$global-border-color: $border-color;

// Body
$body-color: $black;

// Links
$link-color:       color('primary-3');
$link-hover-color: color('primary-3');

// Headings
$h1-color: $blue;
$h2-color: $blue;
$h3-color: $blue;
$h4-color: color('base-0');
$h5-color: color('base-0');
$h6-color: color('base-0');

$headings-font-family: $font-emirates !important;
