.b-product_tile {
    @include when-inside('.m-page_designer') {
        background: $white;
        border: 1px solid $border-color;

        &-inner {
            width: 100%;
        }

        .product-link-button,
        .product-link-button.m-einstein {
            display: inline-block;
        }

        .b-pdp-images-original-sticker {
            grid-column: grid-start/grid-end;
            grid-row: 1/2;
            z-index: z('above');
            margin-top: auto;
            padding-bottom: 12px;
            padding-left: 12px;
        }
    }
}
