.tooltip {
    position: absolute;
    padding: 5px 10px;

    &-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 7px;
    }

    // TODO create mixin

    &[x-placement^="top"] {
        margin-bottom: 7px;

        .tooltip-arrow {
            border-width: 7px 7px 0 7px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            bottom: -5px;
            left: calc(50% - 7px) rtl-ignore();
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 7px;

        .tooltip-arrow {
            border-width: 0 7px 7px 7px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            top: -5px;
            left: calc(50% - 7px) rtl-ignore();
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 7px;

        .tooltip-arrow {
            border-width: 7px 7px 7px 0;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
            left: -5px;
            top: calc(50% - 7px) rtl-ignore();
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 7px;

        .tooltip-arrow {
            border-width: 7px 0 7px 7px;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            right: -5px;
            top: calc(50% - 7px) rtl-ignore();
            margin-left: 0;
            margin-right: 0;
        }
    }
}
