.wheelchair-modal {
    background: rgba(0, 0, 0, 0.6);
    display: flex;

    .tingle-modal-box__content {
        padding: 35px;
    }

    .tingle-modal__close {
        top: 25px;
        right: 25px;
    }

    &.m-small {
        .tingle-modal-box {
            @media (max-width: 540px) {
                max-width: 90%;
            }
        }
    }
}
