$quantity-disabled-color: #d4d2d0 !default; // exception, for all brands
$quantity-input-color: color('base-0') !default;
$quantity-input-font-size: 14px !default;
$quantity-label-font-size: 14px !default;
$quantity-label-sub-font-size: 12px !default;
$quantity-label-sub-color: $dark !default;

.quantity {
    &-label {
        display: inline-block;
        font-size: $quantity-label-font-size;

        &.m-height {
            min-height: 50px;
            margin: 0;
            position: relative;
        }

        &-sub {
            color: $quantity-label-sub-color;
            display: block;
            font-size: $quantity-label-sub-font-size;
            line-height: 1.2;
            padding-right: 10px;
        }
    }

    &-group {
        display: flex;
        align-items: center;
        user-select: none;
    }

    &-button {
        padding: 0;
        margin: 0;
        width: 14px;
        height: 14px;
        background: center / contain no-repeat;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &-subtract {
            background-image: url("#{svg($icon-arrow-left-primary)}") rtl(url("#{svg($icon-arrow-right-primary)}"));

            &.m-disabled {
                background-image: url("#{svg($icon-arrow-left)}") rtl(url("#{svg($icon-arrow-right)}"));
            }
        }

        &-add {
            background-image: url("#{svg($icon-arrow-right-primary)}") rtl(url("#{svg($icon-arrow-left-primary)}"));

            &.m-disabled {
                background-image: url("#{svg($icon-arrow-right)}") rtl(url("#{svg($icon-arrow-left)}"));
            }
        }
    }

    &-input {
        width: 36px;
        height: 22px;
        padding: 2px 0 0;
        margin: 0 5px;
        border-radius: 3px;
        font-size: $quantity-input-font-size;
        line-height: 1;
        border: 1px solid transparent;
        background-color: transparent;
        text-align: center;
        color: $quantity-input-color;
        pointer-events: none;
        appearance: none;
        -moz-appearance: textfield;

        &.is-invalid {
            border-color: $form-control-error-border-color;
            box-shadow: 0 0 0 2px $form-error-shadow-color-secondary;
        }
    }

    &.m-center {
        text-align: center;

        .quantity-group {
            justify-content: center;
        }
    }

    &.m-disabled {
        color: $quantity-disabled-color;
        pointer-events: none;

        .quantity {
            &-label-sub {
                color: inherit;
            }

            &-input {
                color: inherit;
            }

            &-button {
                &-subtract {
                    background-image: url("#{svg($icon-arrow-left-light)}") rtl(url("#{svg($icon-arrow-right-light)}"));
                }

                &-add {
                    background-image: url("#{svg($icon-arrow-right-light)}") rtl(url("#{svg($icon-arrow-left-light)}"));
                }
            }
        }
    }
}
