$this: ".b-pdp_aside";

.b-pdp_aside {
    border-radius: $border-radius;
    border: 1px solid $border-color;

    &-modal {
        .tns-inner {
            margin: 0 !important;
        }

        .tingle-modal-box__content {
            padding: 48px 0 0;
        }

        .tingle-modal__close {
            top: 18px;
        }

        .pdp-product-addtocart {
            width: 100%;
        }

        #{$this} {
            &-deal {
                border-radius: 0;
            }

            &-section {
                padding: 32px 16px;
            }
        }

        ~ .modal-backdrop {
            @include media (md-down) {
                display: none;
            }
        }
    }

    &-deal {
        background: rgba($red, 10%);
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border: 1px solid $red;
        font-size: 16px;
        padding: 16px 24px;
        text-align: center;
    }

    &-section {
        border-top: 1px solid $border-color;
        padding: 32px 24px;

        &:first-child {
            border: none;
        }

        &__inner {
            border-top: 1px solid $border-color;
            padding: 32px 0 16px;

            .pdp-total-block_aa {
                padding: 0 20px;
            }
        }
    }

    &-header {
        @include flex($horizontal: space-between, $vertical: center);

        margin-bottom: 20px;
    }

    &-title {
        font-size: 20px;
        margin: 0;
    }

    &-hint {
        color: color('base-8');
    }

    &-call_back {
        .form-group-wrap-small {
            &:last-child {
                border-top: 1px solid color('base-7');
                margin: 14px -24px 0;
                padding: 24px 24px 0;
            }
        }
    }

    &-availability {
        .pdp-product-addtocart-wrap {
            margin: 0;
        }

        .pdp-product-addtocart {
            padding: 0;
        }

        &.m-desert-safari {
            border-top: 1px solid $border-color;
        }
    }

    &-location,
    &-total {
        .pdp-product-pickup & {
            background: #fafafa;
            border-top: none;
            padding: 0;

            .b-pdp_aside-modal & {
                padding: 0 16px;
            }
        }
    }

    &-location {
        &__content {
            display: none;
            margin-top: 16px;

            .form-group {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                .l-pdp & {
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .form-group-wrap-small > .form-group {
            padding: 16px 12px;
            background-color: #fff;
            border: 1px solid $border-color;
            border-radius: 4px;
            position: relative;
            margin-bottom: 16px;

            .autocomplete-suggestions {
                @include media (md-down) {
                    top: 119px !important;
                    left: 13px !important;
                }
            }

            label {
                span {
                    font-size: 10px;
                    color: #7c7d84;
                }
            }

            label[for='hotel_pick-up'],
            label[for='resident_pick-up'] {
                position: relative;
                cursor: pointer;
                line-height: 21px;
                margin-bottom: 0;
                padding-left: 28px;

                input[type='radio'] {
                    display: none;

                    &:checked + .radio-fake {
                        border-color: #0d803f;
                    }
                }

                .radio-fake {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 18px;
                    height: 18px;
                    border-radius: 100%;
                    border: 3px solid #efefef;
                }
            }

            &:has(input[type='radio']:checked) {
                .b-pdp_aside-location__content {
                    display: block;
                }
            }
        }
    }

    &-total {
        border-radius: 0 0 16px 16px;
    }
}
