$contact-us-accordion-control-background-color: color('base-1') !default;
$contact-us-accordion-control-color: color('base-4') !default;
$contact-us-accordion-control-expanded-color: color('primary-3') !default;
$contact-us-description-color: color('base-4') !default;
$contact-us-info-wrap-background-color: color('base-1') !default;
$contact-us-info-link-color: color('base-4') !default;

.contact-us {
    padding-top: 22px;

    @include media-breakpoint-up(md) {
        padding-top: 56px;
    }

    &-title {
        color: color('base-0');
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &-description {
        color: $contact-us-description-color;
        font-weight: $font-weight-normal;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    &-form {
        padding-bottom: 50px;

        @include media-breakpoint-up(md) {
            padding-bottom: 84px;
        }
    }

    & &-form-message {
        &.m-small {
            min-height: 100px;
        }
    }

    &-input-wrap {
        &.m-hidden-label {
            .form-control-label {
                visibility: hidden;
            }
        }

        &.m-hidden-asterisk {
            .form-control-label::before {
                visibility: hidden;
            }
        }
    }

    &-info {
        &-wrap {
            background-color: $contact-us-info-wrap-background-color;
            padding: 28px 20px;

            @include media-breakpoint-up(md) {
                padding: 24px 30px 50px;
            }
        }

        &-title {
            font-size: 20px;
            font-weight: $font-weight-bold;
            margin-bottom: 18px;

            @include media-breakpoint-up(md) {
                font-size: 24px;
                margin-bottom: 28px;
            }
        }

        &-sections {
            @include media-breakpoint-up(md) {
                display: flex;
            }
        }

        &-section {
            &:first-child {
                margin-bottom: 20px;

                @include media-breakpoint-up(md) {
                    margin: 0 20px 0 0;
                }

                @include media-breakpoint-up(lg) {
                    margin: 0 38px 0 0;
                }
            }

            &.m-max-width-fixed {
                max-width: 360px;

                @include media-breakpoint-up(lg) {
                    max-width: 524px;
                }
            }
        }

        &-item {
            line-height: 1.7;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }

            b {
                color: color('base-0');
                text-decoration: none;
            }
        }

        &-description {
            color: $contact-us-info-link-color;
        }

        &-link {
            color: $contact-us-info-link-color;

            @include on-event {
                text-decoration: none;
            }

            &.m-underlined {
                text-decoration: underline;
            }
        }
    }

    &-question {
        color: color('base-0');

        &-description {
            color: $contact-us-description-color;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }
    }

    &-delimiter {
        margin-bottom: 30px;
        margin-top: 34px;

        @include media-breakpoint-up(md) {
            margin-bottom: 52px;
            margin-top: 60px;
        }
    }

    &-enquiry {
        margin-bottom: 16px;
    }

    &-about-you {
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            margin-bottom: 42px;
        }
    }

    .tabs-nav {
        margin-bottom: 62px;
    }

    .tabs-content-container {
        margin-bottom: 50px;
        padding-left: 0;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .tabs-content {
        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    .tabs-nav-item {
        font-size: 20px;
        font-weight: $font-weight-medium;
        padding-bottom: 26px;

        &::before {
            height: 2px;
        }

        &.active::before {
            height: 6px;
        }
    }

    .accordion-container {
        padding: 28px 0 0;

        @include media-breakpoint-up(md) {
            @include make-row;

            padding: 0;
        }
    }

    .accordion-icon {
        @include inline-svg-bg($icon-plus);

        height: 12px;
        flex: 0 0 12px;
        position: absolute;
        right: 30px;
        top: calc(50% - 6px);
        width: 12px;
    }

    .accordion-control {
        background-color: $contact-us-accordion-control-background-color;
        color: $contact-us-accordion-control-color;
        font-size: 18px;
        font-weight: $font-weight-medium;
        justify-content: center;
        padding: 12px 50px;

        &.m-accordion-expanded {
            background-color: $white;
            border-bottom: 1px solid $global-border-color;
            color: $contact-us-accordion-control-expanded-color;

            .accordion-icon {
                @include inline-svg-bg($icon-minus);
            }
        }
    }

    textarea.form-control {
        display: block;
        min-height: 200px;

        @include media-breakpoint-up(md) {
            min-height: 180px;
        }
    }
}

#snapins_invite {
    margin-top: -170px;
}
