$pdp-modal-close-button-size: 54px !default;

.pdp {
    &-modal {
        padding-top: 0;

        .tingle-modal {
            &-box {
                background-color: transparent;
                max-width: 100%;
                width: auto;
            }

            &-box__content {
                padding: 0;
            }

            &__close {
                @include transition($transition-base);

                background: url("#{svg($icon-close-modal)}") rgba($black, 0.2) no-repeat center / 20px;
                border-radius: 50%;
                height: $pdp-modal-close-button-size;
                width: $pdp-modal-close-button-size;

                @include on-event {
                    background-color: rgba($black, 0.4);
                }
            }

            &__closeIcon {
                display: none;
            }
        }

        &.m-active-video {
            .tingle-modal__close {
                bottom: calc(100% + 10px);
                top: auto;
            }
        }
    }
}
