$custom-select-panel-background-color: $white;
$custom-select-panel-background-color-md: $white;
$custom-select-panel-outline-color-md: #7c9dd2;
$custom-select-panel-outline-color: $form-border-focus;
$custom-select-panel-outline-width: 1px;
$custom-select-option-hover-color-md: #2792fc;
$custom-select-option-hover-color: color('base-1');
$custom-select-wrapper-bg-color: $white;
$custom-select-error-border-color: $form-control-error-border-color;

.custom-select {
    background: none;
    box-shadow: none;
    color: $form-font-grey;
    cursor: default;
    font-size: $body-font-size;
    padding: 9px 15px;

    @include on-event {
        border: 1px solid $form-border-focus;
        box-shadow: none;
        color: $form-font-focus;
    }

    @include when-inside('.is-disabled') {
        background-color: $form-bg-disabled;
        color: $form-font-disabled;
        pointer-events: none;
    }

    @include when-inside('.is-invalid-container') {
        border: 1px solid $custom-select-error-border-color;
    }

    &:focus {
        border: 1px solid $form-border-focus;
        box-shadow: none;
        color: $form-font-focus;
    }

    &::before {
        @include css-triangle($form-font-grey, $triangle-size: 6px, $triangle-direction: down);

        border-width: 6px 4px 0;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 17px;

        @include when-inside('.is-open') {
            @include css-triangle($form-border-focus, $triangle-size: 6px, $triangle-direction: up);

            border-width: 0 4px 6px;
        }
    }

    > span {
        display: inline-block;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        width: 100%;
    }

    &-wrapper {
        position: relative;
        background-color: $custom-select-wrapper-bg-color;
        box-sizing: border-box;

        @include when-inside('.is-invalid-container') {
            ~ .invalid-feedback {
                @include inline-svg-bg($icon-exclamation);

                display: block;
                padding: 1px 0 1px 25px;
                background-size: 20px;
                background-position: 0 50%;
            }
        }

        @include when-inside('.form-group-wrap-small') {
            ~ .invalid-feedback {
                padding: 2px 0 0 20px;
                font-size: 12px;
                margin-top: 5px;
                background-size: 15px;
            }
        }

        select {
            left: 0;
            position: absolute;
            top: 0;
            visibility: hidden;
        }
    }

    &-panel {
        background-color: $custom-select-panel-background-color;
        left: $custom-select-panel-outline-width;
        max-height: 364px;
        outline: 1px solid $custom-select-panel-outline-color;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        visibility: hidden;
        width: calc(100% - #{2 * $custom-select-panel-outline-width});
        z-index: z('above');

        @include media-breakpoint-up(md) {
            outline-color: $custom-select-panel-outline-color-md;
        }

        @include media-breakpoint-up(lg) {
            width: 300px;
        }

        @include when-inside('.is-open') {
            visibility: visible;
        }

        @include when-inside('.pdp-service-custom-select') {
            top: 100%;
            width: auto;
            height: 300px;
        }
    }

    &-option {
        cursor: default;
        padding: 6px 6px 5px;
        vertical-align: middle;

        &.has-focus {
            background-color: $custom-select-option-hover-color;

            @include media-breakpoint-up(lg) {
                background-color: $custom-select-option-hover-color-md;
                color: $white;
            }
        }
    }
}
