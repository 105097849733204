.b-pdp_main {
    border-bottom: 1px solid $border-color;
    font-size: 16px;

    &-section {
        border-top: 1px solid $border-color;
        padding-top: 23px;
        margin-bottom: 23px;

        @include media(md-up) {
            margin-bottom: 30px;
            padding-top: 30px;
        }

        h2 {
            @include heading($heading-font-size: $h2-font-size, $heading-color: $h2-color);

            margin-bottom: 0;

            @include media(md-up) {
                font-size: 32px;
            }
        }

        h3 {
            font-size: 20px;
        }

        .b-duration {
            &-icon {
                @include inline-svg($icon-duration);
            }
        }

        .b-language {
            &-icon {
                @include inline-svg($icon-lang);
            }
        }

        .b-duration,
        .b-language {
            @include flex($vertical: center);

            column-gap: 8px;
            margin-bottom: 12px;

            &-icon {
                display: inline-block;
            }

            &-caption {
                color: $grey;
            }
        }

        &-content {
            padding-top: 16px;
            margin-bottom: 24px;

            .pdp-description {
                padding: 0;
            }
        }
    }

    &-facts {
        .list-option {
            padding: 0;
            gap: 20px;
            justify-content: space-between !important;
            margin: 0;

            > li {
                margin: 0 !important;
                flex: unset;
                max-width: calc(50% - 15px);
                padding: 0;

                @include media(md-up) {
                    max-width: calc(25% - 15px);
                }
            }

            &-item {
                line-height: 20px;
                margin: 0;
            }

            &-caption {
                color: color('base-8');
                font-size: 12px;
            }
        }
    }

    &-accordions {
        /* stylelint-disable no-descending-specificity */
        ul {
            list-style: none;
            margin: 0;
            padding: 0 0 10px;

            li {
                margin: 0 0 10px;
                padding: 0 0 0 26px;
                position: relative;

                &::before {
                    @include inline-svg($icon-bullet);

                    color: $dark;
                    content: '';
                    left: 0;
                    position: absolute;
                    top: 50%;
                    translate: 0 -65%;
                }
            }
        }
        /* stylelint-enable no-descending-specificity */

        .tracking-consent-inner {
            display: block;
        }
    }

    .b-list {
        li {
            padding-left: 26px;

            &::before {
                content: "";
                top: 50%;
                translate: 0 -65%;
            }
        }

        &.m-included {
            li::before {
                @include inline-svg($icon-bullet);
            }
        }

        &.m-excluded {
            li::before {
                @include inline-svg($icon-cross);
            }
        }
    }
}
