.widget {
    position: relative;

    &-select {
        border-radius: $border-radius-small;
        border: 1px solid color('base-7');
        cursor: pointer;
        height: 44px;
        padding: 14px 12px 10px 50px;

        &::before {
            @include inline-svg($icon-users);

            content: '';
            left: 10px;
            position: absolute;
            top: 10px;
        }

        @include when-inside('.m-active') {
            border-color: $dark;
        }
    }

    &-flyout {
        background: $white;
        border-radius: 0 0 $border-radius $border-radius;
        border: 1px solid $dark;
        display: none;
        left: 0;
        margin-top: -3px !important;
        padding: 20px;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: z('above');

        @include when-inside('.m-active') {
            display: block;
        }
    }

    &-controls {
        @include flex($horizontal: flex-end);

        margin-top: 22px;

        &-item {
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
