$navigation-bar-title-font-size: 24px !default;
$navigation-bar-title-color: color('base-0') !default;
$navigation-bar-box-shadow: 0 1px 4px 0 rgba(color('base-0'), 0.1) !default;
$navigation-bar-description-color: color('base-4') !default;
$navigation-bar-description-font-size: $body-font-size !default;

.navigation-bar {
    border-radius: $border-radius;
    box-shadow: $navigation-bar-box-shadow;
    background-color: $white;
    padding: 34px;
    margin: 30px 0 0;

    @include media-breakpoint-up(md) {
        max-width: 342px;
    }

    @include media-breakpoint-up(lg) {
        margin: 10px auto 0;
    }

    &-title {
        font-size: $navigation-bar-title-font-size;
        color: $navigation-bar-title-color;
        margin-bottom: 10px;
        font-family: $font-secondary;
    }

    &-link {
        margin-top: 8px;
        display: inline-block;
    }

    &-arrow {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: center/contain no-repeat;
        background-image: url("#{svg($icon-arrow-right-primary)}") rtl(url("#{svg($icon-arrow-left-primary)}"));
    }

    &-description {
        color: $navigation-bar-description-color;
        font-size: $navigation-bar-description-font-size;
        margin: 6px 0;
    }
}
