$product-badge-default-background-color: color('primary-1');

$product-badge-main-image-background-color: color('primary-5');

$product-badge-description-background-color: color('primary-5');

$service-badge-background-color: color('primary-4');
$service-badge-highlighted-background-color: color('primary-5');
$service-badge-border-radius: 0;

.product-badge {
    .l-pdp-content-aside & {
        background-color: color('primary-3');
        border-radius: 6px 6px 0 0;
        color: color('white');
        font-family: $font-emirates;
        width: 100%;
        margin-bottom: 0;
    }

    .b-pdp_aside-content & {
        border-radius: 6px;
        font-size: 12px;
        padding: 4px 10px;
        margin-bottom: 10px;
        width: auto;
    }

    .b-carousel-item &,
    .product-carousel-item & {
        background: color('primary-5');
        border-radius: 6px 6px 0 0;
        color: color('white');
        font-family: $font-emirates;
        padding-bottom: 21px;
    }

    &-container {
        .l-pdp-content-aside & {
            top: 0;
            position: relative;
            text-align: center;
        }

        .b-pdp_aside-content & {
            top: 8px;
            right: 10px;
            align-items: start;
            text-align: left;
        }

        .b-carousel-item &,
        .product-carousel-item & {
            left: 10px;
            right: auto;
            top: 3px;
            z-index: -1;
        }
    }
}

.tns-carousel:has(.m-promo) {
    .product-carousel-item {
        padding-top: 34px !important;

        .product-badge-container {
            left: 5px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .l-pdp-content-aside {
        > .product-badge-container--top {
            margin-bottom: -12px;
            overflow: hidden;
    
            ~ div[data-component^="product"] {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                position: relative;
                z-index: 1;
                background-color: $white;
            }

            ~ .b-pdp_aside-deal {
                position: relative;
                z-index: 1;
                background-color: #FEEBEB;
            }
    
             .product-badge {
                padding: 8px 10px 16px 12px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .b-pdp_aside-modal {
        .product-badge-container {
            position: relative;
            
            .product-badge {
                background-color: color('primary-3');               
                color: color('white');
                font-family: $font-emirates;
            }

            &--top {
                .product-badge-container {
                    position: static;
                }

                .product-badge {
                    margin-bottom: 0;
                    width: 100%;
                    border-radius: initial;
                    text-align: center;
                }
            }
        }
    }
}

