.b-refinement_radio {
    @include flex($vertical: center);

    gap: 12px;
    position: relative;

    &-icon {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        border: 2px solid $black;
    }

    &[data-checked="true"] &-icon {
        &::after {
            position: absolute;
            content: "";
            top: 4px;
            left: 4px;
            background: $blue;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
    }
}
