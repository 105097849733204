// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// Typography
//
// Styleguide 3

// Fonts
//
// Markup:
// <code>DINNextLTPro 300</code>
// <div style="margin: 0 0 20px; font-size: 40px; font-weight: 300; font-family: 'DINNextLTPro', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;">The quick brown fox jumps over the lazy dog</div>
// <code>DINNextLTPro 400</code>
// <div style="margin: 0 0 20px; font-size: 40px; font-weight: 400; font-family: 'DINNextLTPro', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;">The quick brown fox jumps over the lazy dog</div>
// <code>DINNextLTPro 500</code>
// <div style="margin: 0 0 20px; font-size: 40px; font-weight: 500; font-family: 'DINNextLTPro', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;">The quick brown fox jumps over the lazy dog</div>
// <code>DINNextLTPro 700</code>
// <div style="margin: 0 0 20px; font-size: 40px; font-weight: 700; font-family: 'DINNextLTPro', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;">The quick brown fox jumps over the lazy dog</div>
// <code>Mallanna</code>
// <div style="margin: 0 0 20px; font-size: 40px; font-family: 'Mallanna', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;">The quick brown fox jumps over the lazy dog</div>
//
// Styleguide 3.1

@font-face {
    font-family: 'Mallanna';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Mallanna-Regular.woff2') format('woff2'),
         url('../fonts/Mallanna-Regular.woff') format('woff');
}

@font-face {
    font-family: 'DINNextLTPro';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/DINNextLTPro-Light.woff2') format('woff2'),
         url('../fonts/DINNextLTPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'DINNextLTPro';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/DINNextLTPro-Regular.woff2') format('woff2'),
         url('../fonts/DINNextLTPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'DINNextLTPro';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/DINNextLTPro-Medium.woff2') format('woff2'),
         url('../fonts/DINNextLTPro-Medium.woff') format('woff');
}

@font-face {
    font-family: 'DINNextLTPro';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/DINNextLTPro-Bold.woff2') format('woff2'),
         url('../fonts/DINNextLTPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Emirates';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Emirates-Bold-font.woff2') format('woff2'),
         url('../fonts/Emirates-Bold-font.woff') format('woff');
}
