.b-floating_logo {
    .m-page_designer & {
        pointer-events: none;
        grid-column: grid-start/grid-end;
        grid-row: 1/2;
        position: relative;

        &-container {
            position: absolute;

            img {
                width: auto;
            }

            &.m-vertical {
                &_top {
                    top: 24px;
                }

                &_middle {
                    top: 50%;
                    translate: 0 -50%;
                }

                &_bottom {
                    bottom: 24px;
                }
            }

            &.m-horizontal {
                &_left {
                    left: 24px;
                }

                &_center {
                    left: 50%;
                    transform: translateX(-50%);
                }

                &_right {
                    right: 24px;
                }
            }
        }
    }
}
