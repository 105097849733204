@mixin tiny-carousel-controls(
    $arrow-icon-size: 24px,
    $arrow-icon-gutters: 0,
    $arrow-icon-left-disabled: $icon-arrow-left,
    $arrow-icon-left-hover: $icon-arrow-left-black,
    $arrow-icon-left: $icon-arrow-left-primary,
    $arrow-icon-right-disabled: $icon-arrow-right,
    $arrow-icon-right-hover: $icon-arrow-right-black,
    $arrow-icon-right: $icon-arrow-right-primary) {
    button {
        @include transition;

        background: center / contain no-repeat;
        border: 0;
        cursor: pointer;
        height: $arrow-icon-size;
        padding: 0;
        position: absolute;
        top: calc(50% - #{$arrow-icon-size*0.5});
        width: $arrow-icon-size;
        z-index: z('default');

        &:focus {
            outline: none;
        }

        &[data-controls="prev"] {
            background-image: url("#{svg($arrow-icon-left)}");
            left: $arrow-icon-gutters rtl-ignore();

            @include media-breakpoint-up(lg) {
                @include hover {
                    background-image: url("#{svg($arrow-icon-left-hover)}");
                }
            }

            @include on-disabled {
                background-image: url("#{svg($arrow-icon-left-disabled)}");
                cursor: default;
            }
        }

        &[data-controls="next"] {
            background-image: url("#{svg($arrow-icon-right)}");
            right: $arrow-icon-gutters rtl-ignore();

            @include media-breakpoint-up(lg) {
                @include hover {
                    background-image: url("#{svg($arrow-icon-right-hover)}");
                }
            }

            @include on-disabled {
                background-image: url("#{svg($arrow-icon-right-disabled)}");
                cursor: default;
            }
        }

        @content;
    }
}
