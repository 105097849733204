.l-pdp {
    color: $aa-black;

    &-header {
        margin-bottom: 24px;
    }

    &-images {
        margin-bottom: 24px;

        @include media(md-up) {
            margin-bottom: 32px;
        }
    }

    &-promo-wrap {
        margin-bottom: 44px;

        @include media(sm) {
            margin-bottom: 30px;
        }
    }

    &-content {
        @include flex($vertical: flex-start);

        column-gap: 20px;
        margin-bottom: 80px;

        @include media(md-up) {
            margin-bottom: 140px;
        }

        &-main {
            flex-grow: 1;
        }

        &-aside {
            flex: 0 0 365px;
            max-width: 365px;

            @include media(md-down) {
                display: none;
            }
        }
    }

    &-top,
    &-bottom {
        .l-grid_layout {
            @include make-container-max-widths;

            @include media(lg) {
                max-width: 100%;
            }
        }
    }

    &.product-detail {
        .map-block {
            margin-bottom: 95px;

            @include media(md-up) {
                margin-bottom: 155px;
            }
        }

        .l-pdp-video {
            margin-bottom: 76px;

            @include media(md-up) {
                margin-bottom: 135px;
            }
        }
    }

    .price {
        &-value {
            color: $aa-black;
            font-size: 16px;
            white-space: nowrap;
            display: inline;
        }
    }

    .product-price {
        &-currency {
            color: $aa-black;
        }
    }

    .m-page_designer {
        .l-pdp-content {
            margin-bottom: 0;
        }
    }
}
