$image-container-overlay-background:  rgba(0, 0, 0, 0.25) !default;
$image-container-img-fixed-height: 250px !default;
$image-container-img-fixed-height-xl: 600px !default;
$image-container-img-fixed-height-xl-tablet: 620px !default;
$image-container-ribbon-background-color: $grey-1 !default;

.image-container {
    position: relative;

    &.m-clipped {
        img {
            object-fit: contain;
            max-width: none;
        }
    }

    &.m-fixed-height {
        img {
            height: $image-container-img-fixed-height;

            @include media-breakpoint-only(md) {
                height: $image-container-img-fixed-height-md;
                object-fit: cover;
                max-width: 100%;
            }

            @include media-breakpoint-up(lg) {
                height: $image-container-img-fixed-height-lg;
            }
        }
    }

    &.m-overlay::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $image-container-overlay-background;
        content: "";
    }

    &-ribbon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        background-color: $image-container-ribbon-background-color;
    }

    &.m-alone-image {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            height: $image-container-img-fixed-height-xl-tablet;
        }

        @include media-breakpoint-up(xl) {
            height: $image-container-img-fixed-height-xl;
        }

        @include media-breakpoint-down(sm) {
            height: auto;
        }

        img {
            max-width: none;
            height: 100%;

            @include media-breakpoint-down(sm) {
                object-fit: contain;
                max-width: 100%;
                height: auto;
            }
        }
    }

    .theme-marhaba & {
        @include aspect_ratio(26.1);

        @include media-breakpoint-down(xl) {
            height: 375px;

            .m-cart & {
                @include aspect_ratio(12);

                height: auto;
            }

            &.m-overlay {
                height: 600px;
            }

            &.m-clipped {
                @include aspect_ratio(56);

                height: auto;

                img {
                    height: auto;
                }

                &.m-fixed-height {
                    @include aspect_ratio(25);

                    @include media-breakpoint-down(sm) {
                        @include aspect_ratio(67);

                        height: 250px !important;

                        img {
                            width: auto;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        height: 500px;
                    }
                }
            }

            &.m-alone-image {
                height: 600px;

                @include media-breakpoint-down(sm) {
                    @include aspect_ratio(31.2);

                    height: auto;
                }
            }
        }

        @include media-breakpoint-only(md) {
            img {
                width: auto;
            }

            &.m-alone-image {
                height: 620px;
            }
        }
    }

    .cart-page & {
        .theme-marhaba & {
            @include aspect_ratio(12.6);

            height: 153px;

            @include media-breakpoint-only(md) {
                height: 96px;
            }

            @include media-breakpoint-down(sm) {
                height: 47px;
            }
        }
    }

    .product-carousel & {
        height: auto;
    }

    .theme-aa & {
        @include aspect_ratio(75);

        &.m-fixed-height {
            @include aspect_ratio(20.8);

            @include media-breakpoint-down(lg) {
                height: 400px;
            }

            @include media-breakpoint-down(md) {
                height: 375px;
            }

            &.position-md-absolute {
                @include aspect_ratio(26.1);
            }
        }
    }

    .col-sm-12 & {
        .theme-aa & {
            @include aspect_ratio(100);
        }
    }

    .content-hero & {
        .theme-aa & {
            @include aspect_ratio(20.25);

            @include media-breakpoint-down(md) {
                @include aspect_ratio(20.25);
            }

            @include media-breakpoint-down(sm) {
                @include aspect_ratio(19.5);
            }

            @include media-breakpoint-only(lg) {
                @include aspect_ratio(23);
            }
        }

        .content-page-find-us & {
            .theme-aa & {
                @include aspect_ratio(75.25);
            }
        }
    }

    .aa-hp-blog-mobile.content-hero & {
        @include aspect-ratio(106.6);
    }

    .content-hero.blog-page &,
    .content-hero.hp-bottom-banner & {
        .theme-aa & {
            @include aspect_ratio(31.25);
        }
    }

    .content-hero.col-md-6 & {
        .theme-aa & {
            @include aspect_ratio(20.8);

            &.m-overlay {
                &::before {
                    position: static;
                }
            }

            &.desert_safari {
                @include aspect_ratio(30);
            }

            @include media-breakpoint-down(sm) {
                @include aspect_ratio(106.6);
            }
        }
    }

    .theme-css & {
        @include aspect_ratio(7.55);

        &.m-fixed-height {
            @include aspect_ratio(20.5);

            @include media-breakpoint-down(lg) {
                @include aspect_ratio(33);
            }

            @include media-breakpoint-down(lg) {
                @include aspect_ratio(24);
            }
        }

        &.position-md-absolute {
            @include aspect_ratio(31.5);
        }
    }

    .m-secondary & {
        .theme-css & {
            @include aspect_ratio(26);

            @include media-breakpoint-down(lg) {
                @include aspect_ratio(30);
            }

            @include media-breakpoint-down(md) {
                @include aspect_ratio(26);
            }
        }
    }
}
