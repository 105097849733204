$button-bg-primary: $yellow;
$button-bg-primary-alt: color('primary-4');
$button-bg-secondary: color('primary-5');
$button-bg-tertiary: color('primary-3');
$button-bg-disabled: rgba(color('base-0'), 0.2);
$button-border-disabled: transparent;
$button-bg-transparent: rgba(color('base-0'), 0.2);
$button-link-color: color('base-4');
$button-link-color-event: color('primary-3');
// button-quaternary
$button-quaternary-color: color('base-0');
$button-quaternary-border-color: color('base-2');
$button-quaternary-border-color-event: color('primary-3');
$button-quaternary-border-radius: 0;
// button large
$button-large-border-radius: false;
$button-icon-secondary-color: color('base-4');
$button-icon-secondary-color-event: color('primary-3');
