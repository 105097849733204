.accordion-icon {
    display: none;
}

.accordion-init {
    .accordion {
        &-item {
            padding: 0;
            border-bottom: 1px solid $global-border-color;

            &:first-child {
                border-top: 1px solid $global-border-color;
            }
        }

        &-control {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            cursor: pointer;
        }

        &-text {
            pointer-events: none;
        }

        &-icon {
            display: inline-block;
            pointer-events: none;
        }

        &-container {
            position: absolute;
            visibility: hidden;
            padding: 15px;
        }
    }


    .m-accordion-expanded {
        .accordion {
            &-container {
                position: static;
                visibility: visible;
            }

            &-icon {
                transform: rotate(180deg);
            }
        }
    }
}
