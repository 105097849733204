// -----------------------------------------------------------------------------
// This file contains all styles related to the breadcrumbs component.
// -----------------------------------------------------------------------------

$breadcrumbs-margin: 0 0 35px !default;
$breadcrumbs-margin-lg: 0 0 20px !default;
$breadcrumbs-color: color('base-0') !default;
$breadcrumbs-font-size: 12px !default;
$breadcrumbs-font-size-lg: 13px !default;
$breadcrumbs-item-padding: 0 0 0 8px !default;
$breadcrumbs-item-color-active: color('base-0') !default;
$breadcrumbs-divider-padding: 0 4px 0 0 !default;
$breadcrumbs-divider: quote("/") !default;

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: $breadcrumbs-margin;
    color: $breadcrumbs-color;
    font-size: $breadcrumbs-font-size;
    list-style: none;

    @include media-breakpoint-up(lg) {
        margin: $breadcrumbs-margin-lg;
        font-size: $breadcrumbs-font-size-lg;
    }

    &-item {
        a {
            color: inherit;
            text-decoration: underline;
        }

        &.active {
            a {
                color: $breadcrumbs-item-color-active;
                font-weight: $font-weight-medium;
                pointer-events: none;
                text-decoration: none;
            }
        }
    }

    &-item + &-item {
        padding: $breadcrumbs-item-padding;

        &::before {
            display: inline-block;
            padding: $breadcrumbs-divider-padding;
            content: $breadcrumbs-divider;
        }
    }
}
