// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.category-slot-wrapper {
    position: relative;

    &.m-secondary {
        z-index: z('default');

        .content-banner {
            padding: 0 8px;
        }
    }

    &.m-services {
        z-index: 0;
    }

    > div {
        &:empty {
            height: 502px;
        }
    }

    .d-none.d-md-block {
        &:empty {
            height: 425px;
        }
    }
}
